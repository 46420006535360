import { createGlobalStyle } from 'styled-components';
import {
  fontFaces,
  cssReset,
  brazeReset,
  reCaptchaReset,
  nProgress,
} from './css';

const GlobalStyle = createGlobalStyle`
  ${fontFaces}
  ${cssReset}
  ${brazeReset}
  ${nProgress}
  ${reCaptchaReset}
`;

export default GlobalStyle;
