import { createGlobalStyle } from 'styled-components';

const adyen = createGlobalStyle`
  .adyen-checkout__payment-method {
    border-radius: ${({ theme }) => theme.spacing.customSpacing('8px')};
    margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
    :first-child {
      border-top-left-radius: ${({ theme }) =>
        theme.spacing.customSpacing('8px')};
      border-top-right-radius: ${({ theme }) =>
        theme.spacing.customSpacing('8px')};
    }
    :last-child {
      border-bottom-left-radius: ${({ theme }) =>
        theme.spacing.customSpacing('8px')};
      border-bottom-right-radius: ${({ theme }) =>
        theme.spacing.customSpacing('8px')};
      margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
    }
  }
  .adyen-checkout__payment-method--selected {
    box-shadow: 0px 0px 0px 2px #2f9bd8 inset;
    background-color: #ffffff;
  }
  .adyen-checkout__payment-method__header {
    padding: ${({ theme }) => theme.spacing.customSpacing('16px')} !important;
  }
  .adyen-checkout__payment-method__radio {
    display: none;
  }
  .adyen-checkout__payment-method__radio--selected {
    display: none;
  }
  .adyen-checkout__payment-method__name {
    /* Payment method name in the payment method header */
  }
  .adyen-checkout__spinner__wrapper {
    /* Spinning icon */
  }
  .adyen-checkout__button {
    /* Buttons */
  }
  .adyen-checkout__button--pay {
    /* Pay button */
  }
  .adyen-checkout__field {
    /* Form field container */
  }
  .adyen-checkout__label {
    /* Form label container */
  }
  .adyen-checkout__label__text {
    /* Text element inside the form label container */
  }
  .adyen-checkout__input {
    /* Input fields */
  }
  .adyen-checkout__input--error {
    /* Error state for the input fields */
  }
  .adyen-checkout__error-text {
    /* Error message text */
  }
  .adyen-checkout__card__cardNumber__input {
    /* Input field for the card number */
  }
  .adyen-checkout__field--expiryDate {
    /* Input field for the expiry date */
  }
  .adyen-checkout__field__cvc {
    /* Input field for the CVC security code */
  }
  .adyen-checkout__card__holderName {
    /* Input field for card holder name */
  }
  .adyen-checkout__checkbox__input {
    /* Checkboxes */
  }
  .adyen-checkout__checkbox__label {
    /* Checkbox labels */
  }
  .adyen-checkout__radio_group__input {
    /* Radio buttons */
  }
  .adyen-checkout__dropdown__button {
    /* Dropdown button showing list of options */
  }
  .adyen-checkout__dropdown__list {
    /* Dropdown list */
  }
  .adyen-checkout__dropdown__element {
    /* Elements in the dropdown list */
  }
  .adyen-checkout__link {
    /* Links */
  }
`;

export default adyen;
