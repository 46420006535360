import React, { useEffect, useRef, useState } from 'react';
import {
  MatchContestFragment,
  QueueStatus,
  useCancelChallengeMutation,
  useLeaveChallengeMutation,
} from 'graphpl/core';
import { ResponsivePill } from 'components/atoms/button/button';
import { useUser } from 'components/util/user-context';
import { addCommasToNumber } from 'components/util/add-commas-to-number';
import { useRouter } from 'next/router';
import { AvailableIcons, Iconography } from 'components/styles';
import { useToast } from 'components/util/toast-context';
import { Avatar } from 'components/atoms/new-avatar';
import { useModal } from 'components/util/modal-context';
import { NewRelativeSkill } from 'components/atoms/new-relative-skill';
import { closeOnClickOutside } from 'helpers';
import { DropdownItem } from 'components/atoms';
import LoadingSpinner, {
  CenterSpinner,
} from 'components/atoms/loading-spinner';
import { useTheme } from 'styled-components';
import SSLSecured from 'components/organisms/match-screen/assets/ssl-secured.svg';
import CashApp from 'components/organisms/match-screen/assets/cash-app.svg';
import PayPal from 'components/organisms/match-screen/assets/paypal.svg';
import {
  AvatarHeader,
  ButtonWrapper,
  MatchName,
  MatchContextText,
  ExpandMenu,
  ThirdPartyLogos,
  PrimaryMarketingText,
  SecondaryMarketingText,
  LoggedOutButtonPrimaryText,
  LoggedOutButtonSecondaryText,
  LoggedOutButtonInternals,
  RatingWrapper,
  StyledSurface,
  MenuItemInternals,
  StyledDropdownList,
  UsernameText,
} from './match-header-section.styles';
import { ConfirmEntry } from '../confirm-entry';
import { SendChallenge } from '../send-challenge';

type MatchHeaderSectionProps = {
  matchData: MatchContestFragment;
  authenticated: boolean;
};

export const MatchHeaderSection = ({
  matchData,
  authenticated,
}: MatchHeaderSectionProps) => {
  const theme = useTheme();
  const router = useRouter();
  const { user } = useUser();
  const { displayModal } = useModal();
  const { displayToast } = useToast();
  const [menuOpen, setMenuOpen] = useState(false);

  const menuContentRef = useRef(null);
  const menuButtonRef = useRef(null);

  useEffect(
    () =>
      closeOnClickOutside({
        setState: setMenuOpen,
        buttonRef: menuButtonRef,
        contentRef: menuContentRef,
      }),
    [menuContentRef, menuButtonRef, menuOpen],
  );

  const [
    deleteMatch,
    { loading: deleteLoading, called: deleteCalled, error: deleteError },
  ] = useCancelChallengeMutation({
    variables: {
      challengeId: matchData.id || '',
    },
    onCompleted: () => {
      router.push(`/lounge`);

      displayToast({
        type: 'success',
        subtext: 'Successfully deleted match',
        toastDisplayed: true,
      });
    },
    onError: (error) => {
      displayToast({
        type: 'error',
        heading: 'Error deleting match',
        subtext: error.message,
        toastDisplayed: true,
      });
    },
  });

  const [
    leaveQueue,
    { loading: leaveLoading, called: leaveCalled, error: leaveError },
  ] = useLeaveChallengeMutation({
    variables: {
      challengeId: matchData.id || '',
    },
    onCompleted: () => {
      router.push(`/lounge`);

      displayToast({
        type: 'success',
        subtext: 'Successfully left match',
        toastDisplayed: true,
      });
    },
    onError: (error) => {
      displayToast({
        type: 'error',
        heading: 'Error leaving match',
        subtext: error.message,
        toastDisplayed: true,
      });
    },
  });

  const ownerId = matchData.creator?.id;

  const isOwner = user?.id === ownerId;
  const playerInQueue = matchData.playerQueue?.find(
    (player) => player?.user?.id === user?.id,
  );
  const isJoined =
    Boolean(playerInQueue) && playerInQueue?.status === QueueStatus.JOINED;
  const isRejected = playerInQueue?.status === QueueStatus.REJECTED;

  const matchValue =
    matchData.amountMin &&
    matchData.amountMin?.value !== matchData?.amount?.value
      ? `${addCommasToNumber(
          matchData.amountMin?.value || 0,
        )}-${addCommasToNumber(matchData.amount?.value || 0)}`
      : addCommasToNumber(matchData.amount?.value || 0);

  const matchName = `${matchData?.game?.name} for $${matchValue}`;
  const matchInviteText = 'wants to play';

  if (!authenticated || !user?.id) {
    const onClick = () => {
      router.push(`/login?redirect=/new-match/${matchData.id}`);
    };
    return (
      <>
        <StyledSurface size="large">
          {matchData.creator && (
            <AvatarHeader>
              <Avatar size="extraExtraLarge" user={matchData.creator} />
            </AvatarHeader>
          )}

          <MatchContextText>
            {matchData.creator?.username && (
              <UsernameText
                hasPlPlus={Boolean(
                  matchData.creator?.usernameFlairs?.hasPlPlus,
                )}
              >
                {matchData.creator?.username}
              </UsernameText>
            )}
            &nbsp;
            {matchInviteText}
          </MatchContextText>
          <MatchName>{matchName}</MatchName>
          <ButtonWrapper>
            <ResponsivePill size="large" purpose="info" onClick={onClick}>
              <LoggedOutButtonInternals>
                <LoggedOutButtonPrimaryText>
                  Play Now
                </LoggedOutButtonPrimaryText>
                <LoggedOutButtonSecondaryText>
                  100% first deposit match
                </LoggedOutButtonSecondaryText>
              </LoggedOutButtonInternals>
            </ResponsivePill>
          </ButtonWrapper>
          {process.env.NODE_ENV !== 'test' && (
            <ThirdPartyLogos>
              <SSLSecured />
              <PayPal />
              <CashApp />
            </ThirdPartyLogos>
          )}
        </StyledSurface>
        <PrimaryMarketingText>
          🏆 #1 video game wagering platform
        </PrimaryMarketingText>
        <SecondaryMarketingText>
          $200M+ paid out since 2018
        </SecondaryMarketingText>
      </>
    );
  }

  if (!isOwner) {
    return (
      <StyledSurface size="large">
        {matchData.creator && (
          <AvatarHeader>
            <Avatar size="extraExtraLarge" user={matchData.creator} />
          </AvatarHeader>
        )}
        <RatingWrapper>
          <NewRelativeSkill
            type="badge"
            gameSeriesId={matchData?.game?.gameSeriesId}
            opponentUserId={matchData?.creator?.id}
            opponentRatings={matchData?.creator?.plrRatings}
            context="challenge"
          />
        </RatingWrapper>

        <MatchContextText additionalPadding>
          {isJoined && !isRejected && 'Awaiting'}
          &nbsp;
          {matchData.creator?.username && (
            <UsernameText
              hasPlPlus={Boolean(matchData.creator?.usernameFlairs?.hasPlPlus)}
            >
              {matchData.creator?.username}
            </UsernameText>
          )}
          &nbsp;
          {!isJoined && matchInviteText}
          {isRejected && "'s match"}
        </MatchContextText>
        <MatchName>{matchName}</MatchName>
        <ButtonWrapper>
          {isJoined && !isRejected ? (
            <ResponsivePill
              size="large"
              purpose="error"
              onClick={() => leaveQueue()}
            >
              {leaveLoading || (leaveCalled && !leaveError) ? (
                <LoadingSpinner
                  size={24}
                  color={theme.new.theme === 'light' ? 'blue' : 'white'}
                />
              ) : (
                'Leave queue'
              )}
            </ResponsivePill>
          ) : (
            <ResponsivePill
              size="large"
              purpose={isRejected ? 'warning' : 'success'}
              onClick={() => {
                displayModal({
                  type: 'confirmTransparent',
                  body: <ConfirmEntry matchData={matchData} />,
                  modalDisplayed: true,
                  displayBackButton: false,
                });
              }}
              disabled={isRejected}
            >
              {isRejected ? 'Declined' : 'Play'}
            </ResponsivePill>
          )}
        </ButtonWrapper>
      </StyledSurface>
    );
  }

  const copyLink = async () => {
    try {
      await navigator?.share({
        url: window.location.href,
        title: 'Join me in this match!',
        text: `${matchData.creator?.username || ''} ${matchInviteText}`,
      });
      return;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error sharing', error);
      try {
        await navigator?.clipboard?.writeText(window.location.href);
        displayToast({
          type: 'success',
          subtext: 'Link copied to clipboard',
          toastDisplayed: true,
        });
      } catch (err) {
        displayToast({
          type: 'error',
          subtext: 'Unable to copy link to clipboard',
          toastDisplayed: true,
        });
      }
    }
  };

  const loading = deleteLoading;

  return (
    <StyledSurface size="large">
      {matchData.creator && (
        <AvatarHeader>
          <Avatar size="extraExtraLarge" user={matchData.creator} />
        </AvatarHeader>
      )}
      <ExpandMenu
        role="button"
        ref={menuButtonRef}
        onClick={() => {
          setMenuOpen((prev) => !prev);
        }}
        disabled={loading || (deleteCalled && !deleteError)}
      >
        {loading || (deleteCalled && !deleteError) ? (
          <LoadingSpinner
            size={24}
            color={theme.new.theme === 'light' ? 'blue' : 'white'}
          />
        ) : (
          <Iconography name={AvailableIcons.ELLIPSIS} />
        )}
      </ExpandMenu>
      {menuOpen && (
        <StyledDropdownList isOpen={menuOpen} forwardedRef={menuContentRef}>
          <DropdownItem
            onClick={() => {
              deleteMatch();
              setMenuOpen(false);
            }}
          >
            {loading ? (
              <CenterSpinner fullWidth />
            ) : (
              <MenuItemInternals>Delete match</MenuItemInternals>
            )}
          </DropdownItem>
        </StyledDropdownList>
      )}
      <MatchContextText>Your match - invite below</MatchContextText>
      <MatchName>{matchName}</MatchName>
      <ButtonWrapper>
        <ResponsivePill size="large" purpose="info" onClick={copyLink}>
          Copy Link
        </ResponsivePill>
        <ResponsivePill
          size="medium"
          purpose="openOutlined"
          onClick={() => {
            displayModal({
              type: 'confirmTransparent',
              body: <SendChallenge matchData={matchData} />,
              modalDisplayed: true,
              displayBackButton: false,
            });
          }}
        >
          Send as a challenge on Players&apos; Lounge
        </ResponsivePill>
      </ButtonWrapper>
    </StyledSurface>
  );
};
