import React from 'react';
import { InstantMatchFragment } from 'graphpl/core';
import { T } from 'react-polyglot-hooks';
import MatchDetailsSectionRule from 'components/atoms/match-details-section-rule';
import {
  SectionHeader,
  SectionLink,
  HeaderWrapper,
  RulesWrapper,
} from './match-details-setion.styles';

type MatchDetailsSectionProps = {
  rules: InstantMatchFragment['rules'];
  formatTitle: string;
  openFullRules?: () => void;
  disabledFullRules?: boolean;
};

const MatchDetailsSection = ({
  rules,
  openFullRules,
  formatTitle,
  disabledFullRules = false,
}: MatchDetailsSectionProps) => {
  return (
    <>
      <HeaderWrapper>
        <SectionHeader>{formatTitle}</SectionHeader>
      </HeaderWrapper>
      <RulesWrapper>
        {rules?.map((rule) => {
          if (!rule) return null;
          return <MatchDetailsSectionRule key={rule.optionName} rule={rule} />;
        })}
      </RulesWrapper>
      {!disabledFullRules && (
        <SectionLink
          id="full-rules-open-button"
          onClick={() => openFullRules?.()}
        >
          <T phrase="viewFullRules" />
        </SectionLink>
      )}
    </>
  );
};

export default MatchDetailsSection;
