import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { AvailableIcons, Iconography, lightTheme } from 'components/styles';
import { useGlobal } from 'components/util/global-context';
import { useModal } from 'components/util/modal-context';
import SelectInput from 'components/atoms/select-input';
import { ResponsivePill } from 'components/atoms/button/button';
import { NewCheckbox } from 'components/atoms/new-checkbox';
import ServiceFeeComponent from 'components/molecules/service-fee/service-fee';
import { useUser } from 'components/util/user-context';
import { EntryFeeInput } from 'components/atoms/entry-fee-input';
import {
  ConsolesFragment,
  GamesFragment,
  MatchCompetitorFragment,
  SendDirectChallengeMutationVariables,
  useSendDirectChallengeMutation,
} from 'graphpl/core';
import { useToast } from 'components/util/toast-context';
import { useRouter } from 'next/router';
import MlbPlatformModal, {
  isMLBPlatformError,
} from 'components/organisms/mlb-platform-modal';
import depositNowToast, {
  isLackOfFundsError,
} from 'components/molecules/deposit-now-toast';
import AddGamertag, {
  AddGamertagHeader,
  gamertagIsMissing,
  messageMap,
} from 'components/organisms/add-gamertag-modal';
import { CenterSpinner } from 'components/atoms/loading-spinner';
import { messageParent } from 'helpers/web-view';
import { Divider } from 'components/atoms';
import {
  getDCEnabledGames,
  getDefaultGame,
  getGame,
} from '../utilities/create-match-game-helpers';
import {
  BottomPadding,
  BottomWrapper,
  CheckboxDescription,
  CheckboxName,
  CheckboxRow,
  CheckboxTextWrapper,
  CheckboxToggleWrapper,
  CheckboxWrapper,
  EmptyServiceFeeText,
  EntryFeeErrorText,
  EntryFeeWrapper,
  HeaderIconWrapper,
  HeaderText,
  HeaderWrapper,
  InputWrapper,
  ModalWrapper,
  ServiceFeeText,
  SubHeader,
  SubHeaderText,
  TitleWrapper,
  TopContents,
} from './direct-challenge-modal.styles';
import {
  getConsole,
  getConsoles,
  getConsolesForSelectInput,
} from '../utilities/create-match-console-helpers';

type DirectChallengeAmounts = { minAmount: number; maxAmount: number };
const defaultAmounts: DirectChallengeAmounts = {
  minAmount: 5,
  maxAmount: 50,
};

const DEFAULT_MIN_MINIMUM_AMOUNT = 5;
const DEFAULT_MIN_MAXIMUM_AMOUNT = 5000;

const DEFAULT_MAX_MINIMUM_AMOUNT = 5;
const DEFAULT_MAX_MAXIMUM_AMOUNT = 5000;

const getMinError = (
  selectedAmounts: DirectChallengeAmounts,
): string | undefined => {
  if (!selectedAmounts?.minAmount) {
    return 'Minimum amount is required';
  }
  if (selectedAmounts.minAmount > selectedAmounts.maxAmount) {
    return 'Minimum amount must be less than maximum amount';
  }
  if (selectedAmounts.minAmount < DEFAULT_MIN_MINIMUM_AMOUNT) {
    return `Minimum amount must be greater than $${DEFAULT_MIN_MINIMUM_AMOUNT}`;
  }
  if (selectedAmounts.minAmount > DEFAULT_MIN_MAXIMUM_AMOUNT) {
    return `Minimum amount must be less than $${DEFAULT_MIN_MAXIMUM_AMOUNT}`;
  }
};

const getMaxError = (
  selectedAmounts: DirectChallengeAmounts,
): string | undefined => {
  if (!selectedAmounts?.maxAmount) {
    return 'Maximum amount is required';
  }
  if (selectedAmounts.maxAmount < selectedAmounts.minAmount) {
    return 'Maximum amount must be greater than minimum amount';
  }
  if (selectedAmounts.maxAmount < DEFAULT_MAX_MINIMUM_AMOUNT) {
    return `Maximum amount must be greater than $${DEFAULT_MAX_MINIMUM_AMOUNT}`;
  }
  if (selectedAmounts.maxAmount > DEFAULT_MAX_MAXIMUM_AMOUNT) {
    return `Maximum amount must be less than $${DEFAULT_MAX_MAXIMUM_AMOUNT}`;
  }
};

type InputState = {
  game: GamesFragment;
  console: ConsolesFragment;
  gameFormats: string[];
  rules: string[];
  amounts: DirectChallengeAmounts;
};

export const DirectChallengeModal = ({
  storybookDisabled = false,
  opponent,
  topPadding = 0,
  bottomPadding = 0,
}: {
  storybookDisabled?: boolean;
  opponent?: MatchCompetitorFragment | null;
  topPadding?: number;
  bottomPadding?: number;
}) => {
  const router = useRouter();
  const { games, consoles } = useGlobal();
  const { displayModal, dismissModal } = useModal();
  const { displayToast, dismissToast } = useToast();
  const { user } = useUser();

  const defaultGame = getDefaultGame({
    games,
    user,
  });
  const allGamesEnabled = games.filter(
    ({ enabled, directChallengeEnabled }) => directChallengeEnabled && enabled,
  );
  const gameOptions = getDCEnabledGames({ games });

  const [state, setState] = useState<InputState>({
    game: defaultGame,
    console: getConsoles({ selectedGame: defaultGame, consoles, user })[0],
    gameFormats: [],
    rules: [],
    amounts: defaultAmounts,
  });

  const availableConsoles = getConsoles({
    selectedGame: state.game,
    consoles,
    user,
  });

  const consoleOptions = getConsolesForSelectInput({
    consoles: availableConsoles,
  });

  const selectedGame = state.game;
  const selectedConsole = state.console;
  const gameFormats = (selectedGame?.gameFormats || []).filter(
    (gameFormat) =>
      Boolean(gameFormat) &&
      gameFormat?.enabled &&
      !gameFormat?.key?.includes('plc'),
  );
  const selectedGameFormats = state.gameFormats;
  const selectedRules = state.rules;
  const selectedAmounts = state.amounts;

  const changeSelectedGameFormat = (gameFormatKey: string) => {
    if (selectedGameFormats.includes(gameFormatKey)) {
      setState((prev) => ({
        ...prev,
        gameFormats: prev.gameFormats.filter((key) => key !== gameFormatKey),
      }));
      return;
    }
    setState((prev) => ({
      ...prev,
      gameFormats: [...prev.gameFormats, gameFormatKey],
    }));
  };

  const changeSelectedRules = (ruleKey: string) => {
    if (selectedRules.includes(ruleKey)) {
      setState((prev) => ({
        ...prev,
        rules: prev.rules.filter((key) => key !== ruleKey),
      }));
      return;
    }
    setState((prev) => ({ ...prev, rules: [...prev.rules, ruleKey] }));
  };

  const changeGame = (newGameId: string) => {
    const newGame = getGame(newGameId, allGamesEnabled);
    setState((prev) => ({
      game: newGame,
      console: getConsoles({ selectedGame: newGame, consoles, user })[0],
      gameFormats: [],
      rules: [],
      amounts: prev.amounts,
    }));
  };

  const [
    sendDirectChallenge,
    {
      error: directChallengeMutationError,
      loading,
      called: directChallengeMutationCalled,
    },
  ] = useSendDirectChallengeMutation({
    errorPolicy: 'none',
    onCompleted: (data) => {
      if (!data.sendDirectChallenge?.id) return;

      displayToast({
        toastDisplayed: true,
        type: 'success',
        subtext: 'Direct challenge created',
      });

      messageParent({
        action: 'NAVIGATE',
        source: 'create-direct-challenge-modal',
        data: {
          url: `/new-match/${data.sendDirectChallenge.id}`,
          targetId: data.sendDirectChallenge.id,
        },
      });

      dismissModal();
      router.push(`/new-match/${data.sendDirectChallenge.id}`);
    },

    onError: (error) => {
      if (isMLBPlatformError(error.message)) {
        displayToast({
          toastDisplayed: true,
          type: 'error',
          disableTimeout: true,
          subtext: <MlbPlatformModal onClick={dismissToast} />,
        });
        return;
      }

      if (isLackOfFundsError(error?.message)) {
        depositNowToast(displayToast, dismissToast, dismissModal);
        return;
      }

      if (gamertagIsMissing(error?.message)) {
        messageParent({
          action: 'NAVIGATE',
          source: 'match-creation-modal',
          data: {
            url: '/user/gamertags',
            targetId: messageMap[error.message],
          },
        });
        displayModal({
          type: 'default',
          header: <AddGamertagHeader message={error?.message} />,
          body: (
            <AddGamertag callback={dismissModal} message={error?.message} />
          ),
        });
        return;
      }

      displayToast({
        toastDisplayed: true,
        type: 'warning',
        subtext: error.message,
      });
    },
  });

  const handleSubmit = () => {
    if (!selectedGame || !selectedGame.id || !selectedGame.gameSeriesId) return;
    if (!selectedConsole) return;
    if (!selectedAmounts.minAmount) return;
    if (selectedGameFormats.length === 0) return;

    const variables: SendDirectChallengeMutationVariables = {
      settings: {
        amountMin: Math.floor(selectedAmounts.minAmount),
        amountMax:
          Math.floor(selectedAmounts.maxAmount) ||
          Math.floor(selectedAmounts.minAmount),
        gameId: selectedGame.id,
        consoleSettingId: selectedConsole?.id || 'ps5',
        teamSize: 1,
        gameSeriesId: selectedGame.gameSeriesId,
        gameFormatIds: selectedGameFormats,
        rules: selectedRules,
      },
      opponentId: opponent?.id || undefined,
    };

    sendDirectChallenge({
      variables,
    });
  };

  const onChangeMinimum: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setState((prev) => {
      return {
        ...prev,
        amounts: {
          ...prev.amounts,
          minAmount: parseFloat(event.target.value),
        },
      };
    });
  };

  const onChangeMaximum: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setState((prev) => {
      return {
        ...prev,
        amounts: {
          ...prev.amounts,
          maxAmount: parseFloat(event.target.value),
        },
      };
    });
  };

  const isMinimumError = getMinError(selectedAmounts);

  const isMaximumError = getMaxError(selectedAmounts);

  const disabledInputs =
    storybookDisabled ||
    loading ||
    (directChallengeMutationCalled && !directChallengeMutationError);

  const disabledButton =
    !selectedGame ||
    !selectedConsole?.id ||
    !selectedGame.name ||
    selectedGameFormats.length === 0 ||
    !selectedAmounts.minAmount ||
    Boolean(isMinimumError) ||
    Boolean(isMaximumError) ||
    disabledInputs;

  return (
    <ThemeProvider theme={lightTheme}>
      <ModalWrapper topPadding={topPadding} bottomPadding={bottomPadding}>
        <TopContents>
          <HeaderWrapper>
            <TitleWrapper>
              <HeaderText>Direct Challenge</HeaderText>
              <SubHeaderText>
                Create match and send challenge to anyone
              </SubHeaderText>
            </TitleWrapper>
            <HeaderIconWrapper
              onClick={() => {
                messageParent({
                  action: 'DISMISS_MODAL',
                  source: 'create-direct-challenge-modal',
                });
                dismissModal();
              }}
            >
              <Iconography
                name={AvailableIcons.CROSS}
                color={lightTheme.new.content}
              />
            </HeaderIconWrapper>
          </HeaderWrapper>
          <SubHeader>Game</SubHeader>
          <InputWrapper>
            <SelectInput
              id="direct-challenges-game-selector"
              name="direct-challenges-game-selector"
              defaultValue="chooseGame"
              label="Game"
              isTransparent
              options={gameOptions}
              onChange={(event) => changeGame(event?.target?.value)}
              value={selectedGame.name as string}
              disabled={disabledInputs}
            />
          </InputWrapper>
          {consoleOptions.length > 1 && (
            <>
              <SubHeader>Platform</SubHeader>
              <InputWrapper>
                <SelectInput
                  id="direct-challenges-console-selector"
                  name="direct-challenges-console-selector"
                  defaultValue="choosePlatform"
                  label="Your platform"
                  isTransparent
                  options={consoleOptions}
                  onChange={(event) => {
                    setState((prev) => ({
                      ...prev,
                      console: getConsole(event?.target?.value, consoles),
                    }));
                  }}
                  value={selectedConsole?.displayName || ''}
                  disabled={disabledInputs}
                />
              </InputWrapper>
            </>
          )}
          <SubHeader>Game mode</SubHeader>
          <CheckboxWrapper>
            {gameFormats.map((gameFormat) => {
              if (!gameFormat || !gameFormat.name || !gameFormat.key)
                return null;

              return (
                <CheckboxRow
                  key={gameFormat.name}
                  onClick={() => {
                    if (disabledInputs) return;
                    changeSelectedGameFormat(gameFormat.key as string);
                  }}
                  disabled={disabledInputs}
                >
                  <CheckboxToggleWrapper>
                    <NewCheckbox
                      checked={selectedGameFormats.includes(gameFormat.key)}
                    />
                  </CheckboxToggleWrapper>
                  <CheckboxTextWrapper>
                    <CheckboxName>{gameFormat.name}</CheckboxName>
                    <CheckboxDescription>
                      {gameFormat.description}
                    </CheckboxDescription>
                  </CheckboxTextWrapper>
                </CheckboxRow>
              );
            })}
          </CheckboxWrapper>
          {selectedGame.rules && selectedGame.rules.length >= 1 && (
            <>
              <SubHeader>Additional rules</SubHeader>
              <CheckboxWrapper>
                {selectedGame.rules.map((rule) => {
                  if (!rule || !rule.name || !rule.key) return null;

                  return (
                    <CheckboxRow
                      key={rule.name}
                      onClick={() => {
                        if (disabledInputs) return;
                        changeSelectedRules(rule.key as string);
                      }}
                      disabled={disabledInputs}
                    >
                      <CheckboxToggleWrapper>
                        <NewCheckbox
                          checked={selectedRules.includes(rule.key)}
                        />
                      </CheckboxToggleWrapper>
                      <CheckboxTextWrapper>
                        <CheckboxName>{rule.name}</CheckboxName>
                        <CheckboxDescription>
                          {rule.description}
                        </CheckboxDescription>
                      </CheckboxTextWrapper>
                    </CheckboxRow>
                  );
                })}
              </CheckboxWrapper>
            </>
          )}
          <SubHeader>Entry amount</SubHeader>
          <EntryFeeWrapper>
            <EntryFeeInput
              id="minimum_entry_fee"
              label="Min"
              initialValue={selectedAmounts.minAmount}
              error={Boolean(isMinimumError)}
              onChange={onChangeMinimum}
              disabled={disabledInputs}
            />
            <EntryFeeInput
              id="maximum_entry_fee"
              label="Max"
              initialValue={selectedAmounts.maxAmount}
              error={Boolean(isMaximumError)}
              onChange={onChangeMaximum}
              disabled={disabledInputs}
            />
          </EntryFeeWrapper>
          <EntryFeeErrorText>
            {isMinimumError || isMaximumError}
          </EntryFeeErrorText>
        </TopContents>
        <BottomWrapper>
          <Divider />
          <BottomPadding>
            <ServiceFeeText>
              {selectedAmounts && selectedAmounts.minAmount !== 0 ? (
                <ServiceFeeComponent
                  selectedAmounts={
                    selectedAmounts
                      ? [
                          { value: selectedAmounts.minAmount },
                          { value: selectedAmounts.maxAmount },
                        ]
                      : []
                  }
                  userHasPlPlus={user?.hasPlPlus || false}
                  highContrast
                />
              ) : (
                <EmptyServiceFeeText />
              )}
            </ServiceFeeText>
            {opponent?.username ? (
              <ResponsivePill
                size="medium"
                purpose="info"
                disabled={disabledButton}
                onClick={handleSubmit}
              >
                {loading ? (
                  <CenterSpinner size={24} />
                ) : (
                  `Send Challenge to ${opponent?.username}`
                )}
              </ResponsivePill>
            ) : (
              <ResponsivePill
                size="large"
                purpose="info"
                disabled={disabledButton}
                onClick={handleSubmit}
              >
                {loading ? (
                  <CenterSpinner size={24} />
                ) : (
                  'Proceed to invite user(s)'
                )}
              </ResponsivePill>
            )}
          </BottomPadding>
        </BottomWrapper>
      </ModalWrapper>
    </ThemeProvider>
  );
};
