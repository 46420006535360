import React, { PropsWithChildren } from 'react';
import { PLPlusPill } from './pl-plus-pill.styles';
import { ButtonProps } from '../button';

export const PlPlusButton = ({
  purpose,
  size,
  children,
}: PropsWithChildren<ButtonProps>) => (
  <PLPlusPill purpose={purpose} size={size}>
    {children}
  </PLPlusPill>
);

export default PlPlusButton;
