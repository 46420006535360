import { css } from 'styled-components';

const plPlusAnimation = css`
  background: linear-gradient(
    270deg,
    ${({ theme }) => theme.plPlus.gradientFull.join(',')},
    ${({ theme }) => theme.plPlus.gradientFull.reverse().slice(1).join(',')}
  );
  background-repeat: repeat-x;
  background-size: 200%;
  -webkit-animation: PlusGradient 7s linear infinite;
  -moz-animation: PlusGradient 7s linear infinite;
  animation: PlusGradient 7s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @keyframes PlusGradient {
    0% {
      background-position: 200% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;

export { plPlusAnimation };
