import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  PropsWithChildren,
} from 'react';
import initStream from 'components/organisms/chat/helpers/stream-init';
import { StreamChat } from 'stream-chat';
import { UserFragment } from 'graphpl/core';
import { useUser } from '../user-context';

const setupStreamClient = async ({ user }: { user: UserFragment }) => {
  const newClient = await initStream({
    userToken: user?.streamToken || '',
    id: user?.id || '',
    username: user?.username || '',
    picture: user?.avatar,
  });
  return newClient;
};

type ChatContextType = {
  setUnreadMessages: React.Dispatch<React.SetStateAction<number>>;
  unreadMessages: number;
  client: StreamChat | null;
};

export const ChatContext = createContext<ChatContextType>({
  setUnreadMessages: () => {},
  unreadMessages: 0,
  client: null,
});

export const ChatProvider = ({ children }: PropsWithChildren) => {
  const { user } = useUser();

  const [client, setClient] = useState<StreamChat | null>(null);
  const [unreadMessages, setUnreadMessages] = useState(0);

  client?.on((event) => {
    if (event.total_unread_count !== undefined) {
      setUnreadMessages(event.total_unread_count);
    }
  });

  useEffect(() => {
    const fetchClient = async () => {
      if (!user) return;
      const localClient = await setupStreamClient({
        user,
      });

      setClient(localClient);
      setUnreadMessages((localClient?.user?.total_unread_count as number) || 0);
    };

    if (!client && user?.streamToken) {
      fetchClient();
    }
  }, [client, user?.id, user?.streamToken]);

  return (
    <ChatContext.Provider
      value={{
        setUnreadMessages,
        unreadMessages,
        client,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
