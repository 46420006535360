import { AvailableIcons, Iconography } from 'components/styles';
import React from 'react';
import styled, { useTheme } from 'styled-components';

type NewCheckboxProps = {
  checked?: boolean;
  isTransparent?: boolean;
  highContrast?: boolean;
  inverse?: boolean;
};

const CheckboxWrapper = styled.div<{
  checked: boolean;
  isTransparent: boolean;
  highContrast: boolean;
  inverse: boolean;
}>`
  box-sizing: border-box;
  width: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  height: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  border-radius: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
  border: 1px solid
    ${({ theme, highContrast }) =>
      highContrast ? theme.new.content : theme.new.divisions};
  background-color: ${({ theme, checked, isTransparent, inverse }) => {
    if (checked) {
      return inverse
        ? theme.new.information.text
        : theme.new.information.background;
    }
    if (isTransparent) {
      return 'transparent';
    }
    return theme.new.surface;
  }};
  transition: background-color 100ms ease-out;
`;

const AnimatedIcon = styled(Iconography)`
  transition: fill 100ms ease-out;
`;

export const NewCheckbox = ({
  checked = false,
  isTransparent = false,
  highContrast = false,
  inverse = false,
}: NewCheckboxProps) => {
  const theme = useTheme();

  const iconColor = () => {
    if (checked) {
      return inverse
        ? theme.new.information.background
        : theme.new.information.text;
    }
    return 'transparent';
  };

  return (
    <CheckboxWrapper
      checked={checked}
      isTransparent={isTransparent}
      highContrast={highContrast}
      inverse={inverse}
    >
      <AnimatedIcon name={AvailableIcons.TICK} color={iconColor()} size={22} />
    </CheckboxWrapper>
  );
};
