import { InstantMatchType } from 'components/organisms/match/instant-matches/instant-matches.styles';
import { InstantMatchFragment, MatchmakingAmountType } from 'graphpl/core';
import formatCurrency from 'helpers/lobby/format-currency';

type AmountTextProps = {
  amounts: InstantMatchFragment['amounts'];
  amountType?: MatchmakingAmountType | null;
  type?: InstantMatchType | null;
};

const amountText = ({ amounts, amountType, type = null }: AmountTextProps) => {
  if (type === 'MATCHMAKING' && amounts && !amounts.length && !amounts[0])
    return 'Free';

  if (amounts && !amounts.length && !amounts[0]) return `1\u2013100`;

  if (
    amounts &&
    amounts.length === 1 &&
    typeof amounts[0]?.value === 'string' &&
    `${(amounts[0] || {})?.value}`?.includes('K')
  )
    return amounts[0]?.value;

  // @ts-ignore
  if (amountType !== 'RANGE') return formatCurrency(amounts[0], true);

  // @ts-ignore
  const first = formatCurrency(amounts[0], true);
  // @ts-ignore
  const last = formatCurrency(amounts[amounts.length - 1], true);

  return `${first}\u2013${last}`;
};

export default amountText;
