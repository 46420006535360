import React from 'react';

import { useModal } from 'components/util/modal-context';
import { AvailableIcons, Iconography } from 'components/styles';
import { useTheme } from 'styled-components';
import { useRouter } from 'next/router';
import { messageParent } from 'helpers/web-view';
import {
  ModalHeading,
  ServiceFee,
  StrikethroughServiceFeeText,
  ServiceFeeInfoWrapper,
  PlPlusText,
  IconWrapper,
  StyledLink,
  HeaderWrap,
  CloseButton,
  PlPlusTextLittle,
} from './service-fee.styles';

type Amount = {
  value: number;
};

type ServiceFeeComponentProps = {
  selectedAmounts: Amount[] | number;
  userHasPlPlus?: boolean;
  highContrast?: boolean;
};

const defaultBandings = [
  { min: 5, max: 9.99, fee: 0.74 },
  { min: 10, max: 24.99, fee: 1.49 },
  { min: 25, max: 49.99, fee: 2.24 },
  { min: 50, max: Number.POSITIVE_INFINITY, fee: 2.77 },
];

type Banding = {
  min: number;
  max: number;
  fee: number;
};

const calculateServiceFee = (
  amount: number,
  bandings: Banding[] = defaultBandings,
): number => {
  const matchingBanding = bandings.find(
    (band) => amount >= band.min && amount <= band.max,
  );
  const fee = matchingBanding ? matchingBanding.fee : 0;
  return fee;
};

const getLowestAndHighestAmount = (
  selectedAmounts: Amount[] | number,
): [number, number] => {
  let highestAmount = 0;
  let lowestAmount = 0;

  if (Array.isArray(selectedAmounts) && selectedAmounts.length) {
    lowestAmount = selectedAmounts.reduce((min, amount) => {
      let amountValue = 0;
      if (typeof amount === 'number') {
        amountValue = amount;
      } else if (
        typeof amount === 'object' &&
        amount !== null &&
        'value' in amount
      ) {
        amountValue = amount.value;
      }
      return amountValue < min ? amountValue : min;
    }, 10000000);
    // Handle case where selectedAmounts is an array
    highestAmount = selectedAmounts.reduce((max, amount) => {
      let amountValue = 0;
      if (typeof amount === 'number') {
        amountValue = amount;
      } else if (
        typeof amount === 'object' &&
        amount !== null &&
        'value' in amount
      ) {
        amountValue = amount.value;
      }
      return amountValue > max ? amountValue : max;
    }, 0);
  } else if (
    typeof selectedAmounts === 'number' ||
    typeof selectedAmounts === 'string'
  ) {
    // Handle case where selectedAmounts is a single number or a string
    highestAmount = Number(selectedAmounts);
    lowestAmount = Number(selectedAmounts);
  }
  return [lowestAmount, highestAmount];
};

const serviceFeeText = (selectedAmounts: Amount[] | number) => {
  const [lowestAmount, highestAmount] = getLowestAndHighestAmount(
    selectedAmounts,
  );

  if (highestAmount > 0) {
    const serviceHighestFee = calculateServiceFee(highestAmount);
    const serviceLowestFee = calculateServiceFee(lowestAmount);
    const feeText =
      Array.isArray(selectedAmounts) &&
      selectedAmounts.length > 1 &&
      serviceHighestFee !== serviceLowestFee
        ? `up to $${serviceHighestFee.toFixed(2)}`
        : `$${serviceHighestFee.toFixed(2)}`;

    return feeText;
  }
  return '0.00';
};

const rakeFeeText = (selectedAmounts: Amount[] | number) => {
  const [, highestAmount] = getLowestAndHighestAmount(selectedAmounts);

  if (highestAmount > 0) {
    const rakeHighestFee = highestAmount * 0.1;
    const feeText = `$${rakeHighestFee.toFixed(2)}`;

    return feeText;
  }
  return '0.00';
};

const rakeAndServiceFeeText = (selectedAmounts: Amount[] | number) => {
  const [lowestAmount, highestAmount] = getLowestAndHighestAmount(
    selectedAmounts,
  );

  if (highestAmount > 0) {
    const serviceHighestFee = calculateServiceFee(highestAmount);
    const rakeHighestFee = highestAmount * 0.1;
    const rakeLowestFee = lowestAmount * 0.1;
    const feeText =
      Array.isArray(selectedAmounts) &&
      selectedAmounts.length > 1 &&
      rakeHighestFee !== rakeLowestFee
        ? `up to $${(rakeHighestFee + serviceHighestFee).toFixed(2)}`
        : `$${(rakeHighestFee + serviceHighestFee).toFixed(2)}`;

    return feeText;
  }
  return '0.00';
};

const ServiceFeeComponent = ({
  selectedAmounts,
  userHasPlPlus = false,
  highContrast = false,
}: ServiceFeeComponentProps) => {
  const { displayModal, dismissModal } = useModal();
  const router = useRouter();
  const theme = useTheme();

  const serviceFee = serviceFeeText(selectedAmounts);
  const rakeFee = rakeFeeText(selectedAmounts);
  const rakeAndServiceFee = rakeAndServiceFeeText(selectedAmounts);

  const openInfoModal = (): void => {
    displayModal({
      modalDisplayed: true,
      body: (
        <>
          <HeaderWrap>
            <ModalHeading>
              {!userHasPlPlus ? (
                <>Service Fees {rakeAndServiceFee}</>
              ) : (
                <>
                  Service Fees {rakeAndServiceFee.includes('up to') && 'up to'}
                  <StrikethroughServiceFeeText>
                    {rakeAndServiceFee.replace('up to', '')}
                  </StrikethroughServiceFeeText>{' '}
                  <PlPlusText>{rakeFee}</PlPlusText>
                </>
              )}
            </ModalHeading>
            <CloseButton onClick={() => dismissModal()}>
              <Iconography name={AvailableIcons.CROSS} size={24} />
            </CloseButton>
          </HeaderWrap>
          <ServiceFeeInfoWrapper>
            Service fees help cover the costs of running PL. It also fuels the
            enhancement of product features, increases tournament prize pools,
            and bolster our 24/7 customer service. PL+ members pay reduced
            service fees.
          </ServiceFeeInfoWrapper>
        </>
      ),
      type: 'newDialog',
      displayBackButton: false,
      closeWebViewInReactNativeOnClickOutside: true,
      closeWebViewInReactNativeOnClose: true,
    });
  };

  return (
    <ServiceFee highContrast={highContrast}>
      {!userHasPlPlus ? (
        'Service fees applied'
      ) : (
        <>
          Service fees applied. Your PL+ Saves{' '}
          {serviceFee.includes('up to') && 'up to'}
          <PlPlusTextLittle>{serviceFee.replace('up to', '')}</PlPlusTextLittle>
        </>
      )}
      <IconWrapper onClick={openInfoModal}>
        <Iconography
          name={AvailableIcons.INFORMATION}
          size={16}
          color={highContrast ? theme.new.content : theme.new.subContent}
        />
      </IconWrapper>
      {!userHasPlPlus && (
        <StyledLink
          onClick={() => {
            messageParent({
              action: 'NAVIGATE',
              source: 'ServiceFeeComponent',
              data: {
                url: `/subscribe`,
              },
            });
            router.push('/subscribe');
          }}
          highContrast={highContrast}
        >
          Save {serviceFee} with PL+
        </StyledLink>
      )}
    </ServiceFee>
  );
};

export default ServiceFeeComponent;
