import React from 'react';
import { T } from 'react-polyglot-hooks';
import {
  format,
  isDate,
  isPast,
  isToday,
  isTomorrow,
  isThisWeek,
  isYesterday,
  differenceInMilliseconds,
  isFuture,
} from 'date-fns';

const MILLISECONDS_IN_SECOND = 1000;
const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * 60;
const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * 60;

export const withinHour = (timestamp: number | Date): boolean => {
  const dateTime = Date.now();
  if (
    !isPast(timestamp) &&
    differenceInMilliseconds(timestamp, dateTime) < MILLISECONDS_IN_HOUR
  ) {
    return true;
  }

  return false;
};

/*
 * FormatDateTimeString
 * Takes a epoch timestamp and returns the following formats
 * Time formatting should be based upon a 12 hour clock (meridiem)
 * if timestamp > 1 hour from now but same day: "Today at ${time}${meridiem}"
 * if timestamp > 1 day < 2 days: "Tomorrow at ${time}${meridiem}"
 * if timestamp > 2 days < 1 week: "${day}" at ${time}${meridiem}"
 * if timestamp > 1 week: "${date}" at ${time}${meridiem}"
 */

export const formatDateTimeFuture = (timestamp: number | Date): JSX.Element => {
  const dateTime = Date.now();
  if (withinHour(timestamp)) {
    const minutes =
      differenceInMilliseconds(timestamp, dateTime) / MILLISECONDS_IN_MINUTE;
    return (
      <T
        phrase="timeMins"
        interpolations={{
          mins: minutes,
        }}
      />
    );
  }
  if (isToday(timestamp)) {
    return (
      <T
        phrase="todayAt"
        interpolations={{
          time: `${format(timestamp, 'h:mmaaa').toLowerCase()}`,
        }}
      />
    );
  }
  if (isTomorrow(timestamp)) {
    return (
      <T
        phrase="tomorrowAt"
        interpolations={{
          time: `${format(timestamp, 'h:mmaaa').toLowerCase()}`,
        }}
      />
    );
  }

  if (isThisWeek(timestamp, { weekStartsOn: 1 })) {
    const startDate = format(timestamp, 'ccc');
    const startTime = format(timestamp, 'h:mmaaa').toLowerCase();

    return (
      <T
        phrase="dateTimeAt"
        interpolations={{
          date: startDate,
          time: startTime,
        }}
      />
    );
  }

  const startDate = format(timestamp, 'ccc MM/dd');
  const startTime = format(timestamp, 'h:mmaaa').toLowerCase();

  return (
    <T
      phrase="dateTimeAt"
      interpolations={{
        date: startDate,
        time: startTime,
      }}
    />
  );
};

/*
 * Format a number of milliseconds
 * Returns number of hours in seconds provided
 * With half hour intervals.
 */
const HourAndHalfTimeFormatting = (time: number): string => {
  const countHalfHours = time / (MILLISECONDS_IN_HOUR / 2);
  const hours = Math.floor(countHalfHours / 2);
  const halfPast = countHalfHours % 2;
  return `${hours}h${halfPast && '30m'}`;
};

/*
 *
 * If notification was within the last hour: X minutes ago
 * If notification was within the last 3 hours: XhYm ago;
 * with half hour resolution, i.e. "About 1h ago", "About 1h30m ago",
 * "About 2h ago", "About 2h30m ago"
 * 3+ hours ago, but today: "Today at hh:mm am/pm"
 * Yesterday: "Yesterday at hh:mm am/pm"
 * Anything else: absolute timestamp; e.g. "Dec 7 at hh:mm am/pm"
 */
export const formatDateTimePast = (timestamp: number | Date): JSX.Element => {
  const dateTime = Date.now();
  const secondsSinceTime = differenceInMilliseconds(dateTime, timestamp);
  if (secondsSinceTime < MILLISECONDS_IN_HOUR) {
    const time = Math.floor(secondsSinceTime / MILLISECONDS_IN_MINUTE);
    return <T phrase="timeAgo" interpolations={{ time }} />;
  }
  if (secondsSinceTime < MILLISECONDS_IN_HOUR * 3) {
    const time = HourAndHalfTimeFormatting(secondsSinceTime);
    return <T phrase="aboutTimeAgo" interpolations={{ time }} />;
  }
  if (isYesterday(timestamp)) {
    const time = format(timestamp, 'h:mmaaa').toLowerCase();
    return (
      <T
        phrase="yesterdayAt"
        interpolations={{
          time,
        }}
      />
    );
  }
  if (isToday(timestamp)) {
    const time = format(timestamp, 'h:mmaaa').toLowerCase();
    return (
      <T
        phrase="todayAt"
        interpolations={{
          time,
        }}
      />
    );
  }

  const startDate = format(timestamp, 'LLL d');
  const startTime = format(timestamp, 'h:mmaaa').toLowerCase();
  return (
    <T
      phrase="dateTimeAt"
      interpolations={{
        date: startDate,
        time: startTime,
      }}
    />
  );
};

const formatDateTimeStringRelativeToNow = (
  timestamp: number | Date,
): JSX.Element => {
  if (!timestamp) throw Error('Please supply a timestamp');
  if (!isDate(timestamp)) throw Error('Please supply valid timestamp');

  if (isFuture(timestamp)) {
    return formatDateTimeFuture(timestamp);
  }
  if (isPast(timestamp)) {
    return formatDateTimePast(timestamp);
  }

  return <T phrase="now" />;
};

export default formatDateTimeStringRelativeToNow;
