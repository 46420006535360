import { ON_SERVER } from 'helpers';
import { CLICK_BUTTON } from './events';
import * as sources from './sources';

const { PL_VERSION } = process.env;

type Analytics = {
  event: string;
  source: string;
  [index: string]: unknown;
};

const fireButtonAnalytics = ({ event, source, ...rest }: Analytics): void => {
  if (ON_SERVER)
    throw new Error(
      'You have attempted to fire a button click analytics event from the server, check your calls to "fireButtonAnalytics" are wrapped inside of an onClick event.',
    );

  if (event !== CLICK_BUTTON)
    throw new Error(
      `Analytics event "${event}" is being used inside of "fireButtonAnalytics"`,
    );

  if (!Object.values(sources).find((val) => val === source))
    throw new Error(
      `Analytics source "${source}" is not a defined analytics source.`,
    );

  const analyticsEvent = `web-${event}-${source}`;
  const analyticsProperties = {
    source: `web-${source}`,
    origin: window.location.href,
    ...rest,
    'pl-version': PL_VERSION,
    platform: 'web',
  };

  // @ts-ignore
  global.analytics.track(analyticsEvent, analyticsProperties);
};

export default fireButtonAnalytics;
