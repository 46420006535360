import { fontM, fontS, fontXS, Theme } from 'components/styles';

import { css, FlattenInterpolation, ThemeProps } from 'styled-components';

export type TagSize = 'small' | 'medium' | 'large';

const tagSizes = {
  small: css`
    padding-left: ${({ theme }) => theme.spacing.customSpacing(4)};
    padding-right: ${({ theme }) => theme.spacing.customSpacing(4)};
    ${fontXS}
  `,
  medium: css`
    padding-left: ${({ theme }) => theme.spacing.customSpacing(8)};
    padding-right: ${({ theme }) => theme.spacing.customSpacing(8)};
    padding-top: ${({ theme }) => theme.spacing.customSpacing(4)};
    padding-bottom: ${({ theme }) => theme.spacing.customSpacing(4)};
    ${fontS}
  `,
  large: css`
    padding-left: ${({ theme }) => theme.spacing.customSpacing(8)};
    padding-right: ${({ theme }) => theme.spacing.customSpacing(8)};
    padding-top: ${({ theme }) => theme.spacing.customSpacing(4)};
    padding-bottom: ${({ theme }) => theme.spacing.customSpacing(4)};
    ${fontM}
  `,
};

export const getTagSize = (
  size: TagSize,
): FlattenInterpolation<ThemeProps<Theme>> => tagSizes[size];
