import { css } from 'styled-components';
import { visuallyHidden } from 'components/styles';

const reCaptchaReset = css`
  .grecaptcha-badge {
    ${visuallyHidden};
  }
`;

export default reCaptchaReset;
