/**
 * This function takes in a string as well as a parameter of what the maximum number
 * of characters for the string should be. If the string longer than the maximum, a
 * truncated version of the string with an ellipsis in the middle is returned.
 * Otherwise, the string itself is returned. The function does not allow for a
 * character maximum of less than 7. To handle errors, an empty string is
 * returned if a non-string is passed.
 * @param {string} string - The string value that may need to be truncated
 * @param {number} maxChars - The maximum number of characters allowed in the length of the string before it must be truncated
 */

const truncateStringInMiddle = (string: string, maxChars = 12): string => {
  if (typeof string !== 'string') return '';
  const maxLength = maxChars >= 7 ? maxChars : 7;
  if (string.length <= maxLength) return string;

  const floorHalf = Math.floor(maxLength / 2);

  const startString = string.slice(0, floorHalf);
  const endString = string.slice(string.length - floorHalf, string.length + 1);

  return `${startString}...${endString}`;
};

export default truncateStringInMiddle;
