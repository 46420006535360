type CloseOnClickOutsideProps = {
  setState: (state: boolean) => void;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  contentRef?: React.RefObject<HTMLDivElement>;
  enabled?: boolean;
};

export const closeOnClickOutside = ({
  setState,
  buttonRef,
  contentRef,
  enabled = true,
}: CloseOnClickOutsideProps) => {
  const handleClickOutside = (event: MouseEvent) => {
    if (!enabled) return;
    if (!event.target) return;

    const eventPath = event.composedPath();
    const buttonWasClicked = buttonRef?.current
      ? eventPath.includes(buttonRef.current)
      : false;
    const contentWasClicked = contentRef?.current
      ? eventPath.includes(contentRef.current)
      : false;

    if (!buttonWasClicked && !contentWasClicked) {
      setState(false);
    }
  };

  const handleEscKey = (event: KeyboardEvent) => {
    if (event.key !== 'Escape') return;

    setState(false);
  };

  document.addEventListener('click', handleClickOutside);
  document.addEventListener('keydown', handleEscKey);

  return () => {
    document.removeEventListener('click', handleClickOutside);
    document.removeEventListener('keydown', handleEscKey);
  };
};
