import { useState } from 'react';
import { useGetWalletSubscriptionOrdersQuery } from 'graphpl/core';

const useUserHasPlPlusSubscription = (): [boolean, Record<string, never>] => {
  const [userHasPlPlus, setUserHasPlPlus] = useState(false);

  useGetWalletSubscriptionOrdersQuery({
    variables: {
      planName: 'PLPLUS',
      isActive: true,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if ((data?.walletSubscriptionOrders || [])?.length >= 1) {
        setUserHasPlPlus(true);
      } else {
        setUserHasPlPlus(false);
      }
    },
  });

  return [userHasPlPlus, {}];
};

export default useUserHasPlPlusSubscription;
