import React from 'react';
import Palette from 'components/styles/colors';
import SVG, { SVGImages } from 'svgs';
import {
  OuterAvatarWrapper,
  InnerAvatarWrapper,
  AvatarImage,
  CheckMarkStyled,
  Overlay,
  GoldWinnerBackground,
  PlayerIconContainer,
} from './avatar.styles';
import type { AvatarType, AvatarSize, AvatarStatus } from './avatar.styles';

type AvatarContents = {
  className: string;
  background: string;
  fill: Palette;
  svg: SVGImages;
};

const avatarIcons: Record<AvatarType, AvatarContents> = {
  groupChat: {
    className: 'user-avatar-image-group-chat',
    background: 'groupChat',
    fill: Palette.white,
    svg: 'group',
  },
  default: {
    className: 'user-avatar-image-no-avatar',
    background: 'default',
    fill: Palette.white,
    svg: 'player',
  },
};

type IconProps = {
  size: AvatarSize;
  type?: AvatarType;
};

const Icon = ({ size, type }: IconProps) => {
  const icon = !type ? avatarIcons.default : avatarIcons[type];
  return (
    <PlayerIconContainer className={icon.className} size={size}>
      <SVG icon={icon.svg} fill={icon.fill} />
    </PlayerIconContainer>
  );
};

type AvatarProps = {
  size: AvatarSize;
  type?: AvatarType;
  avatar?: string;
  showOverlay?: boolean;
  status?: AvatarStatus;
  winner?: boolean;
  className?: string;
};

const Avatar = ({
  size,
  type,
  avatar = '',
  showOverlay = false,
  status = 'ready',
  winner = false,
  className,
}: AvatarProps) => (
  <OuterAvatarWrapper className={className}>
    <InnerAvatarWrapper>
      {winner && (
        <GoldWinnerBackground
          className="user-avatar-winner-border"
          size={size}
        />
      )}
      {status && showOverlay && (
        <Overlay status={status} size={size}>
          {status === 'ready' && (
            <CheckMarkStyled src="https://images.playerslounge.co/img/icons/tickCircleFilled.svg" />
          )}
        </Overlay>
      )}
      {avatar ? (
        <AvatarImage
          data-testid="user-avatar-image"
          className="user-avatar-image"
          size={size}
          src={avatar}
          winner={winner}
        />
      ) : (
        <Icon size={size} type={type} />
      )}
    </InnerAvatarWrapper>
  </OuterAvatarWrapper>
);

export default Avatar;
