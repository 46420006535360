import { DropdownList } from 'components/atoms';
import Surface from 'components/atoms/surface';
import {
  DISABLED_STATE,
  MIN_TABLET,
  TOUCHABLE_STYLE,
  fontL,
  fontMContrast,
  fontS,
  fontXL,
  fontXXS,
  plPlusAnimation,
} from 'components/styles';
import styled from 'styled-components';

export const StyledSurface = styled(Surface)`
  margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const ButtonWrapper = styled.div`
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
`;

export const ExpandMenu = styled.div`
  margin-top: -42px;
  z-index: 1;
  padding: ${({ theme }) => theme.spacing.BASE_SPACING};
  width: fit-content;
  cursor: pointer;
  ${TOUCHABLE_STYLE}
  ${DISABLED_STATE}
`;

export const MatchContextText = styled.p<{ additionalPadding?: boolean }>`
  ${fontMContrast};
  color: ${({ theme }) => theme.new.content};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding-top: ${({ theme, additionalPadding }) =>
    additionalPadding
      ? theme.spacing.BASE_SPACING
      : theme.spacing.customSpacing('6px')};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const UsernameText = styled.span<{ hasPlPlus: boolean }>`
  ${fontMContrast};
  color: ${({ theme }) => theme.new.content};
  text-align: center;
  ${({ hasPlPlus }) => hasPlPlus && plPlusAnimation}
`;

export const MatchName = styled.h1`
  ${fontXL};
  color: ${({ theme }) => theme.new.content};
  text-align: center;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.DOUBLE_BASE_SPACING};
`;

export const AvatarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  width: 100%;
`;

export const ThirdPartyLogos = styled.div`
  box-sizing: border-box;
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};

  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LoggedOutButtonInternals = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoggedOutButtonPrimaryText = styled.div`
  ${fontL}
  color: ${({ theme }) => theme.new.information.text};
`;

export const LoggedOutButtonSecondaryText = styled.div`
  ${fontXXS}
  color: ${({ theme }) => theme.new.information.text};
`;

export const PrimaryMarketingText = styled.p`
  ${fontMContrast}
  color: ${({ theme }) => theme.new.content};
  text-align: center;
`;

export const SecondaryMarketingText = styled.p`
  ${fontS};
  color: ${({ theme }) => theme.new.subContent};
  text-align: center;
`;

export const RatingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top -8px;
  z-index: 1;
`;

export const StyledDropdownList = styled(DropdownList)`
  @media (min-width: ${MIN_TABLET}) {
    margin-top: ${({ theme }) => theme.spacing.DOUBLE_BASE_SPACING};
    margin-left: ${({ theme }) => theme.spacing.BASE_SPACING};
  }
`;

export const MenuItemInternals = styled.p`
  ${fontMContrast}
  color: ${({ theme }) => theme.new.error.background};
`;
