import { InstantMatchType } from 'components/organisms/match/instant-matches/instant-matches.styles';

/**
 * Used to return the proper variant for the Tag
 * component (namely "light" or "dark"),
 * based on whether the rule `isDefault` or not
 *
 * @param {boolean} isDefault - boolean to determine whether the rule in the
 * Tag compnoent is a default rule or a non-default (custom) rule, which changes the styling
 */
const determineRuleVariant = (
  isDefault?: boolean,
): Record<InstantMatchType, 'light' | 'dark'> => ({
  BIG_MONEY: isDefault ? 'light' : 'dark',
  MATCHMAKING: isDefault ? 'light' : 'dark',
  FIND: isDefault ? 'dark' : 'light',
  ACTIVE: isDefault ? 'light' : 'dark',
  PLC: isDefault ? 'light' : 'dark',
});

export default determineRuleVariant;
