import { EffectCallback, useEffect } from 'react';

/* This exists purely so we can mock it out in order to test
 * the user ping in _app. This was because mocking apollo there
 * is rather difficult and as such we just opted for this aproach
 * * in order to speed us up, sorry to whoever thinks WTF have
 * hey done this :)
 * */

const useMountEffect = (func: EffectCallback) => useEffect(func, []);

export default useMountEffect;
