import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  PropsWithChildren,
} from 'react';
import { Toast, BaseToastData } from 'components/atoms/banner';
import { useRouter } from 'next/router';
import {
  // eslint-disable-next-line import/named
  getCookie,
  // eslint-disable-next-line import/named
  removeCookie,
  // eslint-disable-next-line import/named
  setCookie,
  // eslint-disable-next-line import/named
  secondsFromCharacters,
} from 'helpers';

export type ToastData = BaseToastData & {
  toastDisplayed: boolean;
  disableTimeout?: boolean;
};

const defaultToast: ToastData = {
  toastDisplayed: false,
  clickToDismiss: false,
  type: 'info',
  heading: undefined,
  subtext: undefined,
  displayOptions: false,
  firstBtnOnClick: () => {},
  firstBtnHref: undefined,
  firstBtnText: undefined,
  secondBtnOnClick: () => {},
  secondBtnText: undefined,
  secondBtnHref: undefined,
  children: undefined,
  closeButton: false,
  position: 'bottom',
};

type ToastContextProps = {
  toast: ToastData;
  setToast: (toast: ToastData) => void;
  displayToast: (toast: ToastData) => void;
  dismissToast: () => void;
  setFlashToast: (toast: ToastData) => void;
};

export const ToastContext = createContext<ToastContextProps>({
  toast: defaultToast,
  setToast: () => {},
  displayToast: () => {},
  dismissToast: () => {},
  setFlashToast: () => {},
});

let timeoutId: NodeJS.Timeout | null = null;

export const ToastProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const { pathname } = router || {};

  const [toast, setToast] = useState<ToastData>(defaultToast);

  const dismissToast = () => {
    setToast(defaultToast);
  };

  const displayToast = (toastData: ToastData) => {
    if (toast.toastDisplayed) {
      dismissToast();
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }

    setToast(toastData);

    if (
      !toastData?.clickToDismiss &&
      !toastData?.disableTimeout &&
      !toastData?.closeButton
    ) {
      timeoutId = setTimeout(() => {
        dismissToast();
      }, secondsFromCharacters(toastData?.heading as string, toastData?.subtext as string));
    }
  };

  const setFlashToast = (toastData: ToastData) => {
    const newToastData = { ...toastData, path: pathname };
    setCookie('flashMessage', JSON.stringify(newToastData));
  };

  useEffect(() => {
    const flash = getCookie('flashMessage');
    const flashToast = (flash && JSON.parse(flash)) || null;
    if (flashToast && flashToast.path !== pathname) {
      displayToast(flashToast);
      removeCookie('flashMessage');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <ToastContext.Provider
      value={{ toast, setToast, displayToast, dismissToast, setFlashToast }}
    >
      {toast.toastDisplayed && (
        <Toast dismissToast={dismissToast} toastData={toast} />
      )}
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
