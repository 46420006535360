import { ToastData } from 'components/util/toast-context';
import { navigate } from 'helpers';
import { messageParent } from 'helpers/web-view';

const LACK_OF_FUNDS_ERROR =
  "Looks like you don't have enough money in your account balance! Please deposit and try again.";

const isLackOfFundsError = (message: string): boolean =>
  message === LACK_OF_FUNDS_ERROR;

const depositNowToast = (
  displayToast: (toast: ToastData) => void,
  dismissToast: () => void,
  dismissModal?: () => void,
): void => {
  displayToast({
    toastDisplayed: true,
    type: 'info',
    disableTimeout: true,
    clickToDismiss: true,
    heading: 'Insufficient funds',
    subtext: 'Please add funds to your account',
    displayOptions: true,
    firstBtnText: 'Add funds',
    firstBtnOnClick: () => {
      messageParent({
        action: 'NAVIGATE',
        source: 'DEPOSIT_NOW_TOAST',
        data: {
          url: '/deposit',
        },
      });
      navigate('/deposit?ds=insufficient-funds-toast');
      dismissToast();
      dismissModal?.();
    },
    firstBtnHref: '/deposit?ds=insufficient-funds-toast',
    secondBtnText: 'Dismiss',
    secondBtnOnClick: () => dismissToast(),
    closeButton: false,
  });
};

export default depositNowToast;
export { isLackOfFundsError, LACK_OF_FUNDS_ERROR };
