import isWithinInterval from 'date-fns/isWithinInterval';
import sub from 'date-fns/sub';

const withinLastSevenDays = (timestamp: number): boolean => {
  const createdAtTs = new Date(timestamp * 1000);
  const sevenDaysAgo = sub(Date.now(), { days: 7 });
  const isWithin = isWithinInterval(createdAtTs, {
    start: sevenDaysAgo,
    end: Date.now(),
  });

  return isWithin;
};

export default withinLastSevenDays;
