import styled from 'styled-components';
import { fontXL } from 'components/styles';

export const Heading = styled.h2`
  ${fontXL}
  color: ${({ theme }) => theme.new.content};
`;

export const GamertagWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING};
  background-color: ${({ theme }) => theme.new.surface};
`;
