import { timeAsync } from 'helpers/logger';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GetServerSidePropsContext } from 'next';
import { GraphQLError } from 'graphql';
import { GetCurrentUserDocument, UserFragment } from 'graphpl/core';
import { ApolloPageProps } from 'helpers/apollo-client';
import { Merge } from 'helpers/typescript';
import cookie from 'cookie';
import { Themes } from 'components/styles';

type GetDefaultPropsReturn = Merge<
  ApolloPageProps,
  {
    user: UserFragment;
    authenticated: boolean;
    theme: Themes;
    errors?: readonly GraphQLError[];
    isApp: boolean;
  }
>;

const getDefaultProps = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  context: GetServerSidePropsContext,
): Promise<GetDefaultPropsReturn> => {
  let user: UserFragment = {};
  let authenticated = false;
  let fetchErrors: readonly GraphQLError[] | undefined;

  const cookies = context?.req?.headers?.cookie
    ? cookie.parse(context.req.headers.cookie)
    : {};
  const userToken = context.req?.cookies?.UserToken;
  const plDeviceCookie = cookies.PLDevice;

  const isAppCookie = plDeviceCookie && plDeviceCookie.toLowerCase() === 'app';

  const rawPlDevice = context?.req?.headers?.['x-pl-device'] || 'web';
  const plDevice =
    typeof rawPlDevice === 'string' ? rawPlDevice : rawPlDevice[0];
  const rawUA = context?.req?.headers?.['user-agent'] || 'web';
  const userAgent = typeof rawUA === 'string' ? rawUA : rawUA[0];

  const isApp =
    isAppCookie ||
    plDevice.toLowerCase() === 'app' ||
    userAgent.toLowerCase() === 'app';

  if (!userToken) {
    return {
      user,
      theme: 'light',
      authenticated: false,
      isApp: !!isApp,
      errors: [],
    };
  }
  try {
    const { data, errors } = await apolloClient.query({
      query: GetCurrentUserDocument,
      fetchPolicy: 'network-only',
      errorPolicy: 'all', // This prevents the error being thrown and instead returns the errors array.
    });

    fetchErrors = errors;

    const authenticationError = errors?.find(
      (error) =>
        error?.extensions?.code === 'AUTHENTICATION_REQUIRED' ||
        error?.extensions?.code === 'UNAUTH_INVALID' ||
        error?.extensions?.code === 'NOT_FOUND',
    );

    if (authenticationError) {
      authenticated = false;
    } else {
      user = data.currentUser;
      authenticated = true;
    }
  } catch (e) {
    authenticated = false;
  }
  const theme = (context?.req?.cookies?.['pl-theme'] as Themes) || 'light';

  return {
    user,
    theme,
    authenticated,
    isApp: !!isApp,
    errors: fetchErrors || [],
  };
};

export default timeAsync<GetDefaultPropsReturn, any>(
  getDefaultProps,
  'getDefaultProps',
);
