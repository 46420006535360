import { FC, SVGProps } from 'react';
import Apex from './assets/games/apex.svg';
import Cfb from './assets/games/cfb.svg';
import Cod from './assets/games/cod.svg';
import Destiny from './assets/games/destiny.svg';
import Dragonball from './assets/games/dragonball.svg';
import Fifa from './assets/games/fifa.svg';
import Fortnite from './assets/games/fortnite.svg';
import Halo from './assets/games/halo.svg';
import Madden from './assets/games/madden.svg';
import Mlb from './assets/games/mlb.svg';
import Nba from './assets/games/nba.svg';
import RocketLeagueLight from './assets/games/rocket-league-light.svg';
import RocketLeagueDark from './assets/games/rocket-league-dark.svg';

// eslint-disable-next-line no-shadow
export enum GameIcons {
  APEX = 'Apex',
  CFB = 'Cfb',
  COD = 'Cod',
  DESTINY = 'Destiny',
  DRAGONBALL = 'Dragonball',
  FIFA = 'Fifa',
  FORTNITE = 'Fortnite',
  HALO = 'Halo',
  MADDEN = 'Madden',
  MLB = 'Mlb',
  NBA = 'Nba',
  ROCKET_LEAGUE_LIGHT = 'RocketLeagueLight',
  ROCKET_LEAGUE_DARK = 'RocketLeagueDark',
}

export const gameIconsComponentMap: Record<
  GameIcons,
  FC<SVGProps<SVGElement>>
> = {
  Apex,
  Cfb,
  Cod,
  Destiny,
  Dragonball,
  Fifa,
  Fortnite,
  Halo,
  Madden,
  Mlb,
  Nba,
  RocketLeagueLight,
  RocketLeagueDark,
};
