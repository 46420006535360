import React, { PropsWithChildren } from 'react';
import SVG from 'svgs';
import Palette from 'components/styles/colors';
import { LinkAnalyticsObject } from 'analytics/get-analytics-object';
import {
  DropdownItemWrapper,
  DropdownItemBox,
  DropdownItemLink,
  DropdownItemSubMenuSVGContainer,
  DropdownItemBorderWrapper,
} from './dropdown-item.styles';

export type DropdownItemProps = PropsWithChildren<{
  href?: string | null;
  analytics?: LinkAnalyticsObject;
  noBorder?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  prefixId?: string;
  subMenu?: React.ReactNode;
  isNewSection?: boolean;
  className?: string;
  disableHoverState?: boolean;
  removeTopBorder?: boolean;
  containsSubMenu?: boolean;
}>;

const DropdownItem = ({
  children,
  href = null,
  analytics,
  noBorder = false,
  onClick = () => {},
  onMouseEnter = () => {},
  prefixId,
  subMenu = null,
  isNewSection,
  className,
  disableHoverState,
  removeTopBorder,
  ...rest
}: DropdownItemProps) => {
  return (
    // @ts-ignore
    <DropdownItemWrapper
      data-testid={
        prefixId ? `${prefixId}-item-wrapper` : 'dropdown-item-wrapper'
      }
      onClick={() => onClick()}
      onMouseEnter={() => onMouseEnter()}
      isNewSection={isNewSection}
      className={className}
      disableHoverState={disableHoverState}
      removeTopBorder={removeTopBorder}
      {...rest}
    >
      <DropdownItemBorderWrapper
        noBorder={noBorder}
        data-testid={
          prefixId
            ? `${prefixId}-item-borderwrapper`
            : 'dropdown-item-borderwrapper'
        }
        isNewSection={isNewSection}
      >
        {href ? (
          <DropdownItemLink
            data-testid={prefixId ? `${prefixId}-item` : 'dropdown-item'}
            href={href}
            analytics={analytics}
          >
            {children}
          </DropdownItemLink>
        ) : (
          <DropdownItemBox
            data-testid={prefixId ? `${prefixId}-item` : 'dropdown-item'}
          >
            {children}
          </DropdownItemBox>
        )}
        {subMenu && (
          <>
            <DropdownItemSubMenuSVGContainer>
              <SVG
                icon="arrowRight"
                data-testid="submenu-arrowRight"
                fill={Palette.blueSlate}
              />
            </DropdownItemSubMenuSVGContainer>
            {subMenu}
          </>
        )}
      </DropdownItemBorderWrapper>
    </DropdownItemWrapper>
  );
};

export default DropdownItem;
