import Bugsnag from '@bugsnag/js';
import { NextApiRequest, NextApiResponse } from 'next';

export function startBugsnag() {
  if (process.env.NODE_ENV !== 'production') return;
  if (process.env.NEXT_PHASE === 'phase-production-build') return;
  if (process.env.STORYBOOK === 'true') return;

  // next.js executes top-level code at build time. See https://github.com/vercel/next.js/discussions/16840 for further example
  // So use NEXT_PHASE to avoid Bugsnag.start being executed during the build phase
  // See https://nextjs.org/docs/api-reference/next.config.js/introduction and https://github.com/vercel/next.js/blob/canary/packages/next/shared/lib/constants.ts#L1-L5 for
  // more details on NEXT_PHASE

  if (process.env.NEXT_IS_SERVER === 'true') {
    Bugsnag.start({
      apiKey: process.env.BUGSNAG_API_KEY || '',
      appVersion: process.env.BUGSNAG_APP_VERSION,
      releaseStage: process.env.PL_ENV,
      // @bugsnag/plugin-aws-lambda must only be imported on the server
      // eslint-disable-next-line import/no-extraneous-dependencies, global-require
      plugins: [require('@bugsnag/plugin-aws-lambda')],
    });
  } else {
    // If preferred two separate Bugsnag projects e.g. a javascript and a node project could be used rather than a single one
    Bugsnag.start({
      apiKey: process.env.BUGSNAG_API_KEY || '',
      appVersion: process.env.BUGSNAG_APP_VERSION,
      releaseStage: process.env.PL_ENV,
      plugins: [],
    });
  }
}
const noOpHandler = (
  func: (req: NextApiRequest, res: NextApiResponse) => Promise<void>,
) => (req: NextApiRequest, res: NextApiResponse) => func(req, res);

export const getServerlessHandler = (): ((
  func: (req: NextApiRequest, res: NextApiResponse) => Promise<void>,
) => (req: NextApiRequest, res: NextApiResponse) => Promise<void>) => {
  if (process.env.NODE_ENV !== 'production') return noOpHandler;
  if (process.env.NEXT_PHASE === 'phase-production-build') return noOpHandler;
  if (process.env.STORYBOOK === 'true') return noOpHandler;

  return Bugsnag.getPlugin('awsLambda').createHandler();
};
