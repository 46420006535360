export const visuallyHidden = `
  position: absolute;
  overflow: hidden;
  margin: 0;
  width: 1px;
  height: 1px;
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
`;
