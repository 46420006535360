import React from 'react';
import {
  DirectChallengeInviteStatus,
  MatchCompetitorFragment,
  MatchContestFragment,
  QueueStatus,
} from 'graphpl/core';
import { GroupAvatar } from 'components/atoms/new-avatar';
import { Pill } from 'components/atoms';
import { useModal } from 'components/util/modal-context';
import {
  StyledSurface,
  SectionHeading,
  SectionWrapper,
  PreviewContentWrapper,
  ContentWrapper,
} from './players-joined-preivew.styles';
import { JoinedUserRow, PendingUserRow } from '../joined-user-row';
import { PlayersJoined } from '../players-joined/players-joined';

type PlayersJoinedProps = {
  matchData: MatchContestFragment;
};

const getUsers = ({
  invites,
  playerQueue,
}: MatchContestFragment): MatchCompetitorFragment[] => {
  const users: MatchCompetitorFragment[] = [];

  playerQueue?.forEach((player) => {
    if (player?.user) {
      users.push(player.user);
    }
  });

  invites?.forEach((invite) => {
    if (invite?.user) {
      users.push(invite.user);
    }
  });

  const dedupedUsers: MatchCompetitorFragment[] = [];

  users.forEach((item) => {
    if (dedupedUsers.findIndex((element) => element?.id === item?.id) === -1) {
      dedupedUsers.push(item);
    }
  });
  return dedupedUsers;
};

export const PlayersJoinedPreview = ({ matchData }: PlayersJoinedProps) => {
  const { displayModal } = useModal();
  const activeQueue = matchData?.playerQueue?.filter(
    (player) =>
      player &&
      player.status !== QueueStatus.CANCELLED &&
      player.status !== QueueStatus.REJECTED,
  );
  const numberOfJoinedPlayers = activeQueue?.length || 0;
  const activeInvites = matchData?.invites?.filter(
    (player) =>
      player && player.status !== DirectChallengeInviteStatus.DECLINED,
  );
  const numberOfInvitedPlaters = activeInvites?.length || 0;

  const users = getUsers({ invites: activeInvites, playerQueue: activeQueue });
  const totalNumberOfPlayers = users.length;
  const isOpen = totalNumberOfPlayers >= 1;

  const showReview = users.length >= 2;

  const showAccept =
    numberOfJoinedPlayers === 1 && numberOfInvitedPlaters === 0;
  const showInvite =
    numberOfJoinedPlayers === 0 && numberOfInvitedPlaters === 1;

  return (
    <SectionWrapper isOpen={isOpen}>
      <StyledSurface size="large">
        <SectionHeading>
          Potential Players ({totalNumberOfPlayers})
        </SectionHeading>
        {showReview && (
          <ContentWrapper>
            <GroupAvatar users={users} size="large" maxUsers={6} />
            <Pill
              size="medium"
              purpose="info"
              onClick={() => {
                displayModal({
                  type: 'confirmTransparent',
                  body: <PlayersJoined matchData={matchData} />,
                  modalDisplayed: true,
                  displayBackButton: false,
                });
              }}
            >
              Review
            </Pill>
          </ContentWrapper>
        )}
        {showAccept && (
          <PreviewContentWrapper>
            <JoinedUserRow
              matchData={matchData}
              opponent={matchData.playerQueue[0]}
            />
          </PreviewContentWrapper>
        )}
        {showInvite && matchData?.invites?.[0]?.user && (
          <PreviewContentWrapper>
            <PendingUserRow
              matchData={matchData}
              opponent={matchData.invites[0].user}
            />
          </PreviewContentWrapper>
        )}
      </StyledSurface>
    </SectionWrapper>
  );
};
