export type EntryFeeAmount = {
  label: string;
  value: number;
  disabled?: boolean;
};

type SelectionFuncProps = {
  amount: EntryFeeAmount;
  minAmount?: EntryFeeAmount;
  maxAmount?: EntryFeeAmount;
};

export const isAmountSelected = ({
  amount,
  minAmount,
  maxAmount,
}: SelectionFuncProps): boolean => {
  if (!minAmount) {
    return false;
  }

  if (maxAmount) {
    return amount.value >= minAmount.value && amount.value <= maxAmount.value;
  }

  return amount.value === minAmount.value;
};

export const isAmountRangeSelected = ({
  amount,
  minAmount,
  maxAmount,
}: SelectionFuncProps): boolean => {
  if (!minAmount) {
    return false;
  }
  if (!maxAmount) {
    return false;
  }
  if (amount.value < minAmount.value) {
    return false;
  }
  if (amount.value >= maxAmount.value) {
    return false;
  }
  return true;
};

export const isAmountInsideRangeSelected = ({
  amount,
  minAmount,
  maxAmount,
}: SelectionFuncProps): boolean => {
  if (!minAmount) {
    return false;
  }
  if (!maxAmount) {
    return false;
  }
  if (minAmount.value === amount.value) {
    return false;
  }
  if (maxAmount.value === amount.value) {
    return false;
  }
  if (amount.value < minAmount.value) {
    return false;
  }
  if (amount.value >= maxAmount.value) {
    return false;
  }
  return true;
};
