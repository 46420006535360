import React from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { amountText } from 'helpers';
import { InstantMatchType } from 'components/organisms/match/instant-matches/instant-matches.styles';
import { InstantMatchFragment, MatchmakingAmountType } from 'graphpl/core';
import { font3XL, fontMContrast } from 'components/styles';

const CurrencyWrapper = styled.div`
  margin-top: 4px;
  ${fontMContrast}
  color: ${({ theme }) => theme.new.content};
`;

const currencyColor = (type: InstantMatchType, theme: DefaultTheme) => {
  if (type === 'FIND') return theme.new.subContent;

  return theme.new.content;
};

const AmountWrapper = styled.div<{ type: InstantMatchType }>`
  ${font3XL}
  font-size: 39.06px;
  line-height: 39.06px;
  color: ${({ theme }) => theme.new.content};
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;

  ${({ type, theme }) =>
    type === 'MATCHMAKING' &&
    `margin-left: ${theme.spacing.HALF_BASE_SPACING};`}
`;

const StrikeLine = styled.div<{ type: InstantMatchType }>`
  height: 0;
  margin-top: -4px;
  border-top: ${({ type, theme }) =>
    type === 'FIND' ? `1px solid ${theme.new.divisions}` : null};
`;

const InstantMatchAmountBox = styled.div`
  display: flex;
  margin-left: -9px;
`;

export type InstantMatchAmountProps = {
  amountType?: MatchmakingAmountType | null;
  amounts?: InstantMatchFragment['amounts'];
  type?: InstantMatchType;
  className?: string;
};

const InstantMatchAmount = ({
  amountType,
  amounts = [],
  type = 'FIND',
  className,
}: InstantMatchAmountProps) => {
  const theme = useTheme();

  return (
    <>
      <InstantMatchAmountBox
        data-testid="instant-match-amount"
        className={className}
      >
        <CurrencyWrapper color={currencyColor(type, theme)}>
          {type === 'FIND' ? '$' : amounts?.[0]?.currency}
        </CurrencyWrapper>
        <AmountWrapper type={type}>
          {amountText({ amounts, amountType, type })}
        </AmountWrapper>
      </InstantMatchAmountBox>
      {type === 'FIND' && <StrikeLine type={type}>&nbsp;</StrikeLine>}
    </>
  );
};

export default InstantMatchAmount;
