const Brands = {
  twitchPurple: 'rgb(145,70,255)',
  plPlus: {
    blackRose: '#72042C',
    burningOrange: '#FF6F42',
    brightSun: '#FFD541',
    sunlitAmber: '#E8AD34',
    crimsonBlossom: '#E54A64',
    midnightIndigo: '#451DAB',
  },
};

export default Brands;
