import React, { PropsWithChildren } from 'react';
import SVG from 'svgs';
import { useDisableScrollbar } from 'helpers';
import { MAX_PHONE_PX } from 'components/styles';
import {
  SidebarCloseButton,
  SidebarInner,
  SidebarOuter,
  SidebarWrapper,
  HeaderWrapper,
  FooterWrapper,
  OverflowContainer,
} from './sidebar.styles';

const CloseButton = ({
  onClose,
}: {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
}) => (
  <SidebarCloseButton
    data-testid="sidebar-close"
    title="close"
    onClick={onClose}
  >
    <SVG icon="close" fill="currentcolor" tab-index="-1" role="img" />
  </SidebarCloseButton>
);

export type SidebarProps = PropsWithChildren<{
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  isOpen: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  mobile?: boolean;
}>;

const Sidebar = React.forwardRef<HTMLDivElement, SidebarProps>(
  ({ children, isOpen, onClose, header, footer, mobile = false }, ref) => {
    const noScrollbarOnMobile = (windowWidth: number): boolean =>
      windowWidth <= MAX_PHONE_PX && mobile && isOpen;
    useDisableScrollbar(noScrollbarOnMobile, [isOpen]);

    return (
      <SidebarWrapper isOpen={isOpen} ref={ref} data-testid={'sidebar-wrapper'}>
        <SidebarOuter>
          <HeaderWrapper>
            {header && <> {header} </>}
            <CloseButton onClose={onClose} />
          </HeaderWrapper>

          <SidebarInner footer={Boolean(footer)}>
            <OverflowContainer>{children}</OverflowContainer>
          </SidebarInner>

          {footer && <FooterWrapper>{footer}</FooterWrapper>}
        </SidebarOuter>
      </SidebarWrapper>
    );
  },
);

Sidebar.displayName = 'Sidebar';

export default Sidebar;
