import { css } from 'styled-components';

const quiteAnimationRectangle =
  'data:image/svg+xml,%3Csvg%20width%3D%2290%22%20height%3D%224%22%20viewBox%3D%220%200%2090%204%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2290%22%20height%3D%224%22%20fill%3D%22url(%23paint0_linear)%22%2F%3E%0A%3Cdefs%3E%0A%3ClinearGradient%20id%3D%22paint0_linear%22%20x1%3D%2267.3308%22%20y1%3D%224.00008%22%20x2%3D%2210.4887%22%20y2%3D%224.00008%22%20gradientUnits%3D%22userSpaceOnUse%22%3E%0A%3Cstop%20stop-color%3D%22white%22%20stop-opacity%3D%220.7%22%2F%3E%0A%3Cstop%20offset%3D%221%22%20stop-color%3D%22white%22%20stop-opacity%3D%220%22%2F%3E%0A%3C%2FlinearGradient%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E%0A';

const lineQuiteAnimation = css`
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  ::after {
    @keyframes lineAnimate {
      0% {
        transform: translateX(-100%);
      }
      30% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(100%);
      }
    }

    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    height: 4px;

    background-image: url('${quiteAnimationRectangle}');
    background-repeat: repeat-y;

    animation-name: lineAnimate;
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
`;

export default lineQuiteAnimation;
