import styled from 'styled-components';
import {
  DISABLED_STATE,
  Iconography,
  TOUCHABLE_STYLE,
  fontM,
  fontXS,
} from 'components/styles';

export const DefaultSelect = styled.select<{
  active: boolean;
  selected: boolean;
}>`
  height: ${({ theme }) => theme.spacing.customSpacing('48px')};
  width: 100%;
  position: absolute;
  border: 0 transparent;
  margin-left: ${({ theme }) => theme.spacing.customSpacing('11px')};
  outline: 0px;
  padding: 0px;
  ${fontM}
  font-weight: 400;
  top: 0;
  opacity: 0;
  cursor: ${({ disabled }) => (disabled ? 'deafult' : 'pointer')};
`;

export const InputWrap = styled.div<{
  active: boolean;
  success: boolean;
  error: boolean;
  highContrast: boolean;
  isTransparent: boolean;
}>`
  height: ${({ theme }) => theme.spacing.customSpacing('48px')};
  background-color: ${({ theme, isTransparent }) =>
    isTransparent ? 'transparent' : theme.new.background};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  border-radius: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  border-width: ${({ theme }) => theme.spacing.customSpacing('1px')};
  border-style: solid;

  border-color: ${({ theme, highContrast }) =>
    highContrast ? theme.new.content : theme.new.divisions};
  border-radius: 8px;
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const DefaultLabel = styled.label`
  ${fontM}
  color: ${({ theme }) => theme.new.content};

  white-space: nowrap;
`;

export const InputBox = styled.div<{ setValueMaxWidth?: string }>`
  width: ${({ setValueMaxWidth }) => setValueMaxWidth || '100%'};
  display: flex;
  flex-direction: column;

  @media (min-width: 340px) {
    width: 100%;
  }
`;

export const DefaultHelperMessage = styled.div<{
  active: boolean;
  success: boolean;
  error: boolean;
}>`
  ${fontXS}
  height: ${({ theme }) => theme.spacing.customSpacing('12px')};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-top: ${({ theme }) => theme.spacing.customSpacing('2px')};
`;

export const CaretButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing.customSpacing('10px')};
  height: 100%;
  width: ${({ theme }) => theme.spacing.customSpacing('48px')};
  background-color: transparent;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'deafult' : 'pointer')};
  fill: ${({ theme }) => theme.new.subContent};

  :active {
    fill: ${({ theme }) => theme.new.content};
  }
`;

export const InputComponentWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  position: relative;
  cursor: pointer;
  ${TOUCHABLE_STYLE}
  ${DISABLED_STATE}
`;

export const SelectedValue = styled.span<{ active: boolean; value?: string }>`
  ${fontM}
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.new.content};
  height: ${({ active, value, theme }) =>
    !active && !value ? '0px' : theme.spacing.BASE_SPACING_AND_HALF};
`;

export const DownArrow = styled(Iconography)<{ isOpened: boolean }>`
  transition: transform 300ms ease-in-out;
  transform: ${({ isOpened }) =>
    isOpened ? 'rotate(180deg)' : 'rotate(0deg)'};
`;
