import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'svgs';
import { lightTheme } from 'components/styles/theme';
import {
  NumberViewingText,
  ViewingMatch,
  MatchViewingWrapper,
  IconAndTextWrapper,
} from './match-viewing.styles';

const propTypes = {
  numberViewing: PropTypes.number.isRequired,
  textColor: PropTypes.string,
};

const iconSize = '0.75em';

const MatchViewing = ({ numberViewing, textColor }) => {
  return (
    <MatchViewingWrapper>
      <IconAndTextWrapper>
        <SVG
          icon="watching"
          height={iconSize}
          width={iconSize}
          fill={
            textColor === 'dark'
              ? lightTheme.palette.misc.icon.light
              : lightTheme.palette.misc.icon.blue
          }
        />
        <NumberViewingText textColor={textColor}>
          {numberViewing.toLocaleString()}
        </NumberViewingText>
      </IconAndTextWrapper>
      <ViewingMatch textColor={textColor}>Viewing match</ViewingMatch>
    </MatchViewingWrapper>
  );
};

MatchViewing.defaultProps = {
  textColor: 'light',
};

MatchViewing.propTypes = propTypes;
export default MatchViewing;
