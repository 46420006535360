import React from 'react';
import Surface from 'components/atoms/surface';
import { fontL } from 'components/styles';
import styled from 'styled-components';
import Chat from 'components/organisms/chat';
import { MatchContestFragment } from 'graphpl/core';
import { Divider } from 'components/atoms';

export const StyledSurface = styled(Surface)`
  margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
  flex: 1;
  overflow: hidden;
  min-height: ${({ theme }) => theme.spacing.customSpacing(308)};
`;

export const SectionHeading = styled.h2`
  ${fontL}
  color: ${({ theme }) => theme.new.content};
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

type MatchChatProps = {
  matchData: MatchContestFragment;
};

export const MatchChat = ({ matchData }: MatchChatProps) => {
  return (
    <StyledSurface size="large">
      <SectionHeading>Chat</SectionHeading>
      <Divider />
      <Chat isOpen channelName={`match-${matchData.id}`} channelType="global" />
    </StyledSurface>
  );
};
