import styled from 'styled-components';
import {
  DISABLED_STATE,
  TOUCHABLE_STYLE,
  fontL,
  fontMContrast,
  fontS,
  fontXL,
  fontXXS,
} from 'components/styles';
import { ResponsivePill } from 'components/atoms/button/button';

export const ModalWrapper = styled.div<{
  topPadding: number;
  bottomPadding: number;
}>`
  background-color: ${({ theme }) => theme.new.surface};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding-top: ${({ theme, topPadding }) =>
    theme.spacing.customSpacing(topPadding)};
  padding-bottom: ${({ theme, bottomPadding }) =>
    theme.spacing.customSpacing(bottomPadding)};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing.DOUBLE_BASE_SPACING};
`;

export const TitleWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const HeaderText = styled.h2`
  ${fontXL}
  color: ${({ theme }) => theme.new.content};
  padding-bottom: 1px;
`;

export const SubHeaderText = styled.p`
  ${fontS}
  color: ${({ theme }) => theme.new.content};
`;

export const HeaderIconWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  cursor: pointer;
  ${TOUCHABLE_STYLE}
`;

export const SubHeader = styled.h3`
  ${fontL}
  color: ${({ theme }) => theme.new.content};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const InputWrapper = styled.div`
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const TopContents = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  overflow-y: auto;
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const BottomPadding = styled.div`
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const BottomWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.new.surface};
  box-sizing: border-box;
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  width: 100%;
`;

export const ServiceFeeText = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
  color: ${({ theme }) => theme.new.subContent};
  ${fontXXS}
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  gap: ${({ theme }) => theme.spacing.DOUBLE_BASE_SPACING};
  padding-bottom: ${({ theme }) => theme.spacing.customSpacing('44px')};
`;

export const CheckboxRow = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.BASE_SPACING};

  cursor: pointer;
  ${TOUCHABLE_STYLE}
  ${DISABLED_STATE}
`;

export const CheckboxToggleWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
`;

export const CheckboxTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  gap: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
`;

export const CheckboxName = styled.h4`
  ${fontMContrast}
  color: ${({ theme }) => theme.new.content};
`;

export const CheckboxDescription = styled.p`
  ${fontXXS}
  color: ${({ theme }) => theme.new.content};
`;

export const DisabledWrapper = styled.div`
  opacity: 0.5;
`;

export const FreePlayPill = styled(ResponsivePill)`
  background: linear-gradient(90deg, #2f9bd8 0%, #5e2fd8 50%, #2f9bd8 100%);

  background-repeat: repeat-x;
  background-size: 200%;
  -webkit-animation: LCAnimation 3s linear infinite;
  -moz-animation: LCAnimation 3s linear infinite;
  animation: LCAnimation 3s linear infinite;

  @keyframes LCAnimation {
    0% {
      background-position: 200% 0%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 100%;
    }
  }

  animation-play-state: paused;

  :hover:not([disabled]) {
    animation-play-state: running;
  }

  margin-bottom: ${({ theme }) => theme.spacing.customSpacing('12px')};
`;

export const LCButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const RightButtonAdditionalPadding = styled.div`
  width: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  height: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;
