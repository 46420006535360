import styled from 'styled-components';

import { MAX_PHONE } from 'components/styles';

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${({ theme }) => theme.new.background};
`;

export const ChatContainer = styled.div`
  overflow-y: hidden;
  display: flex;
  flex: 1;

  @media (max-width: ${MAX_PHONE}) {
    border-bottom-left-radius: 0;
  }
`;
