import styled, { css } from 'styled-components';
import { TOUCHABLE_STYLE } from 'components/styles';
import { ButtonAnimation, getButtonAnimation } from './button-animation';
import { ButtonSize, getButtonSize } from './button-sizes';
import { ButtonVariant, getButtonVariant } from './button-variants';

type BaseButtonProps = {
  disabled?: boolean;
  size: ButtonSize;
  animation?: ButtonAnimation;
  purpose?: ButtonVariant;
  selected?: boolean;
};

type AuthProps =
  | {
      requiresAuth: true;
      authenticated: boolean;
    }
  | {
      requiresAuth?: false;
      authenticated?: never;
    };

export type ButtonProps = BaseButtonProps & AuthProps;

export const Pill = styled.button.attrs<ButtonProps>(
  ({ requiresAuth, authenticated, onClick }) => ({
    onClick:
      requiresAuth && !authenticated
        ? () => {
            // Redirect to the signup page
            window.location.href = '/signup';
          }
        : onClick,
  }),
)<ButtonProps>`
  border-box: content-box;
  position: relative;
  cursor: pointer;
  outline: none;
  text-align: center;
  border-radius: 8px;

  ${TOUCHABLE_STYLE}

  ${({ selected }) =>
    selected &&
    css`
      background-color: #2f9bd8 !important; // TODO: fix this and remove important flag when possible
    `}

  ${({ disabled }) =>
    disabled &&
    `
      cursor: default;
      opacity: 0.5;
    `};

  ${({ size }) => getButtonSize(size)};
  ${({ purpose }) => getButtonVariant(purpose) || ''};
  ${({ animation }) => getButtonAnimation(animation) || ''};
`;

Pill.defaultProps = {
  requiresAuth: false,
};

export const ResponsivePill = styled(Pill)`
  width: 100%;
`;

export default Pill;
