import styled from 'styled-components';
import { fontM } from 'components/styles';
import Link from 'components/atoms/link';

export const DropdownItemSubMenuSVGContainer = styled.div`
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING};
  display: flex;
  align-items: center;
`;

export const DropdownItemWrapper = styled.div<{
  isNewSection?: boolean;
  disableHoverState?: boolean;
  removeTopBorder?: boolean;
}>`
  background-color: ${({ theme }) => theme.new.surface};
  padding-left: ${({ theme, isNewSection }) =>
    isNewSection ? '0' : theme.spacing.BASE_SPACING};
  cursor: pointer;

  ${({ disableHoverState, theme }) => {
    if (disableHoverState) return ``;
    return `
      :hover {
        background-color: ${theme.new.background};
        > * {
          background-color: ${theme.new.background};
        }
      };
    `;
  }}

  :hover:not(:first-child) > div {
    border-top: none;
    padding-top: 1px;
  }

  :not(:first-child) > div {
    border-top: ${({ theme, removeTopBorder }) =>
      removeTopBorder
        ? '1px solid transparent'
        : `1px solid ${theme.new.divisions}`};
  }

  :first-child > div {
    padding-top: 0;
  }
`;

export const DropdownItemBorderWrapper = styled.div<{
  noBorder?: boolean;
  isNewSection?: boolean;
}>`
  height: inherit;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  ${({ noBorder }) => (noBorder ? 'border-top: none !important;' : '')}
  ${({ noBorder }) => (noBorder ? 'padding-top: 1px;' : '')}
  padding-left: ${({ theme, isNewSection }) =>
    isNewSection ? theme.spacing.BASE_SPACING : '0'};
`;

export const DropdownItemBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
  width: auto;
  min-width: ${({ theme }) => theme.spacing.customSpacing('135px')};
  min-height: ${({ theme }) => theme.spacing.customSpacing('50px')};
  ${fontM}
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING};
  fill: ${({ theme }) => theme.new.subContent};
  color: ${({ theme }) => theme.new.content};

  > *:not(:first-child) {
    padding-left: ${({ theme }) => theme.spacing.BASE_SPACING};
  }
`;

export const DropdownItemLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
  width: auto;
  min-width: ${({ theme }) => theme.spacing.customSpacing('135px')};
  min-height: ${({ theme }) => theme.spacing.customSpacing('50px')};
  ${fontM}
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING};
  fill: ${({ theme }) => theme.new.subContent};
  color: ${({ theme }) => theme.new.content};

  > *:not(:first-child) {
    padding-left: ${({ theme }) => theme.spacing.BASE_SPACING};
  }
`;
