import Palette from 'components/styles/colors';
import styled from 'styled-components';

const Divider = styled.hr<{ color?: Palette }>`
  height: ${({ theme }) => theme.spacing.customSpacing('1px')};
  background-color: ${({ theme, color }) => color || theme.new.divisions};
`;

export const VerticalDivider = styled.div`
  width: ${({ theme }) => theme.spacing.customSpacing('1px')};
  background-color: ${({ theme }) => theme.new.divisions};
`;

export default Divider;
