import {
  DISABLED_STATE,
  TOUCHABLE_STYLE,
  fontMContrast,
  fontS,
  plPlusAnimation,
} from 'components/styles';
import styled from 'styled-components';

export const RowWrapper = styled.div<{ disabled: boolean }>`
  box-sizing: border-box;
  width: 100%;
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.BASE_SPACING};
  ${DISABLED_STATE}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
`;

export const PlPlusUsernameText = styled.h5`
  ${fontMContrast}
  color: ${({ theme }) => theme.new.content};
  ${plPlusAnimation}
`;

export const UsernameText = styled.h5`
  ${fontMContrast}
  color: ${({ theme }) => theme.new.content};
`;

export const JoinedDetailsText = styled.p`
  ${fontS}
  color: ${({ theme }) => theme.new.subContent};
`;

export const RightTextWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-top: ${({ theme }) => theme.spacing.customSpacing(12)};
  padding-bottom: ${({ theme }) => theme.spacing.customSpacing(12)};
  cursor: pointer;
  ${DISABLED_STATE}
  ${TOUCHABLE_STYLE}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const RejectButton = styled.button`
  height: 48px;
  width: 48px;
  background-color: transparent;
  cursor: pointer;
  ${TOUCHABLE_STYLE}
  ${DISABLED_STATE}
  padding: ${({ theme }) => theme.spacing.customSpacing('12px')};
`;

export const AcceptButton = styled.button`
  height: 48px;
  width: 48px;
  background-color: transparent;
  cursor: pointer;
  ${TOUCHABLE_STYLE}
  ${DISABLED_STATE}
  padding: ${({ theme }) => theme.spacing.customSpacing('12px')};
  padding-rigth: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;
