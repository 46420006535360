import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { lightTheme } from 'components/styles/theme';
import SVG from 'svgs';
import Palette from 'components/styles/colors';

type StarRatingColor = 'light' | 'dark';
type StarRatingType = 'empty' | 'half' | 'filled';

const propTypes = {
  rating: PropTypes.number.isRequired,
  color: PropTypes.string,
};

const StarsWrapper = styled.div({
  display: 'flex',
});

const getColor = (color: StarRatingColor): Palette => {
  if (color === 'light') {
    return lightTheme.palette.misc.icon.blue;
  }
  return lightTheme.palette.misc.icon.light;
};

const renderStars = (
  type: StarRatingType,
  amount: number,
  color: StarRatingColor,
) => {
  return [...Array(amount)].map(() => {
    if (type === 'empty') {
      return (
        <SVG
          icon="starEmpty"
          fill={getColor(color)}
          key={`${type}-${amount}-${color}`}
        />
      );
    }
    if (type === 'half') {
      return (
        <SVG
          icon="starHalf"
          fill={getColor(color)}
          key={`${type}-${amount}-${color}`}
        />
      );
    }
    if (type === 'filled') {
      return (
        <SVG
          icon="starFilled"
          fill={getColor(color)}
          key={`${type}-${amount}-${color}`}
        />
      );
    }
    return null;
  });
};

const renderContent = (rating: number, color: StarRatingColor) => {
  if (rating === 0) return renderStars('empty', 5, color);
  if (rating === 5) return renderStars('filled', 5, color);
  if (rating % 1 !== 0) {
    return (
      <>
        {renderStars('filled', rating - 0.5, color)}
        {renderStars('half', 1, color)}
        {renderStars('empty', 5 - rating - 0.5, color)}
      </>
    );
  }
  if (rating % 1 === 0) {
    return (
      <>
        {renderStars('filled', rating, color)}
        {renderStars('empty', 5 - rating, color)}
      </>
    );
  }
  return null;
};

const StarRating = ({
  rating,
  color,
}: {
  rating: number;
  color: StarRatingColor;
}) => {
  return (
    <StarsWrapper data-testid="star-rating">
      {renderContent(rating, color)}
    </StarsWrapper>
  );
};

StarRating.defaultProps = {
  color: 'light',
};

StarRating.propTypes = propTypes;
export default StarRating;
