export type FontTypes =
  | 'XXXXL'
  | 'XXXL'
  | 'XXL'
  | 'XL'
  | 'L'
  | 'M'
  | 'MContrast'
  | 'S'
  | 'SContrast'
  | 'XS'
  | 'XSContrast'
  | 'XXS'
  | 'XXSContrast';

type FontWeightOptions = '400' | '500' | '600' | '700' | '800';

type FontWeight = {
  [key: string]: FontWeightOptions;
};

type FontFamilyOptions = 'Plus Jakarta Sans';

type FontFamily = {
  [key: string]: FontFamilyOptions;
};

type FontNumericProperties = {
  [key: string]: number;
};

const fontFamily: FontFamily = {
  XXXXL: 'Plus Jakarta Sans',
  XXXL: 'Plus Jakarta Sans',
  XXL: 'Plus Jakarta Sans',
  XL: 'Plus Jakarta Sans',
  L: 'Plus Jakarta Sans',
  M: 'Plus Jakarta Sans',
  MContrast: 'Plus Jakarta Sans',
  S: 'Plus Jakarta Sans',
  SContrast: 'Plus Jakarta Sans',
  XS: 'Plus Jakarta Sans',
  XSContrast: 'Plus Jakarta Sans',
  XXS: 'Plus Jakarta Sans',
  XXSContrast: 'Plus Jakarta Sans',
};

const fontWeights: FontWeight = {
  XXXXL: '800',
  XXXL: '800',
  XXL: '800',
  XL: '700',
  L: '700',
  M: '400',
  MContrast: '600',
  S: '400',
  SContrast: '500',
  XS: '400',
  XSContrast: '600',
  XXS: '400',
  XXSContrast: '600',
};

const fontSizes: FontNumericProperties = {
  XXXXL: 60, // '3.75em', // 60px
  XXXL: 48, // '3em', // 48px
  XXL: 34, // '2.125em', // 34px
  XL: 24, // '1.5em', // 24px
  L: 18, // '1.125em', // 18px
  M: 14, // '0.875em', // 14px
  MContrast: 14, // '0.875em', // 14px
  S: 13, // '0.8125em', // 13px
  SContrast: 13, // '0.8125em', // 13px
  XS: 11, // '0.6875em', // 11px
  XSContrast: 11, // '0.6875em', // 11px
  XXS: 10, // '0.625em', // 10px
  XXSContrast: 10, // '0.625em', // 10px
};

const lineHeights: FontNumericProperties = {
  XXXXL: 80,
  XXXL: 60.48,
  XXL: 42.84,
  XL: 30.24,
  L: 22.68,
  M: 17.64,
  MContrast: 17.64,
  S: 20,
  SContrast: 20,
  XS: 13.86,
  XSContrast: 13.86,
  XXS: 12.6,
  XXSContrast: 12.6,
};

type FontObject = {
  fontFamily: FontFamilyOptions;
  fontWeight: FontWeightOptions;
  fontSize: number;
  lineHeight: number;
};

export const reactNativeFontConstructor = (type: FontTypes): FontObject => ({
  fontFamily: fontFamily[type],
  fontWeight: `${fontWeights[type]}`,
  fontSize: fontSizes[type],
  lineHeight: lineHeights[type],
});

const font = (type: FontTypes): string => {
  return `
    font-family: ${fontFamily[type]};
    font-weight: ${fontWeights[type]};
    font-size: ${fontSizes[type]}px;
    line-height: ${lineHeights[type]}px;
  `;
};

export const font4XL = font('XXXXL');
export const font3XL = font('XXXL');
export const font2XL = font('XXL');
export const fontXL = font('XL');
export const fontL = font('L');
export const fontM = font('M');
export const fontMContrast = font('MContrast');
export const fontS = font('S');
export const fontSContrast = font('SContrast');
export const fontXS = font('XS');
export const fontXSContrast = font('XSContrast');
export const fontXXS = font('XXS');
export const fontXXSContrast = font('XXSContrast');
