import {
  fontL,
  fontMContrast,
  fontM,
  TOUCHABLE_STYLE,
  DISABLED_STATE,
} from 'components/styles';
import styled from 'styled-components';

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderIconWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
  cursor: pointer;
  ${TOUCHABLE_STYLE}
`;

export const HeadingText = styled.h2`
  ${fontL}
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
  color: ${({ theme }) => theme.new.content};
`;

export const ContentWrapper = styled.div`
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const SubHeadingText = styled.h3`
  ${fontMContrast}
  color: ${({ theme }) => theme.new.content};
  padding-top: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  padding-bottom: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
`;

export const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.BASE_SPACING};
  padding-top: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const SelectorRow = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.customSpacing('10px')};

  cursor: pointer;
  ${TOUCHABLE_STYLE}
  ${DISABLED_STATE}
`;

export const SelectorText = styled.div`
  ${fontM}
  color: ${({ theme }) => theme.new.content};
`;

export const HalfSpacing = styled.div`
  padding-top: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
`;
