import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
} from 'react';
import { I18n, useT } from 'react-polyglot-hooks';
import HTMLParser from 'html-react-parser';
import { getTranslations, getFallback, Locales } from 'shared/phrases';

type PhrasesCtxType = {
  setLocale: Dispatch<SetStateAction<Locales>>;
};

const PhrasesCtx = createContext<PhrasesCtxType>({
  setLocale: () => null,
});

const PhrasesProvider = ({ children }: PropsWithChildren) => {
  const [locale, setLocale] = useState<Locales>(Locales.en);
  const [phrases, setPhrases] = useState(getTranslations(locale || 'en'));

  useEffect(() => {
    setPhrases(getTranslations(locale));
  }, [locale]);

  const onMissingKey = (key: string) => getFallback(key);

  return (
    <PhrasesCtx.Provider value={{ setLocale }}>
      {/* @ts-ignore */}
      <I18n
        locale={locale}
        phrases={phrases}
        allowMissing
        onMissingKey={onMissingKey}
      >
        {children}
      </I18n>
    </PhrasesCtx.Provider>
  );
};

export default PhrasesProvider;
export const usePhrases = () => useContext(PhrasesCtx);

export const HtmlT = ({
  phrase,
  interpolations = {},
}: {
  phrase: string;
  interpolations?: unknown;
}) => {
  const translate = useT();

  const translation = translate(phrase, interpolations);

  // @ts-ignore
  return HTMLParser(translation);
};
