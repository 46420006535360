type AddCommasToNumber = (number: number, includePositive?: boolean) => string;

export const addCommasToNumber: AddCommasToNumber = (
  number,
  includePositive = false,
): string => {
  const stringArray = number.toString().split('.');
  const prefix = includePositive && number > 0 ? '+' : '';
  if (stringArray.length === 1) {
    return `${prefix}${number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }
  const fixedNumber = number.toFixed(2);
  return `${prefix}${fixedNumber
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};
