import React from 'react';
import { useTheme } from 'styled-components';
import {
  EntryFeeInputContainer,
  EntryFeeLabel,
  EntryFeeWrapper,
  StyledInput,
} from './entry-fee-input.styles';

type EntryFeeInputProps = {
  id: string;
  label: string;
  initialValue: number;
  error?: boolean;
  highContrast?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export const EntryFeeInput = ({
  id,
  label,
  initialValue,
  error = false,
  highContrast = false,
  disabled = false,
  onChange,
}: EntryFeeInputProps) => {
  const theme = useTheme();
  const background = theme.new.surface;

  return (
    <EntryFeeInputContainer disabled={disabled}>
      <EntryFeeLabel htmlFor={id} background={background} error={error}>
        {label}
      </EntryFeeLabel>
      <EntryFeeWrapper
        background={background}
        error={error}
        highContrast={highContrast}
      >
        $
        <StyledInput
          id={id}
          name={id}
          onChange={onChange}
          type="number"
          data-testid={`entry-fee-input-${id}`}
          pattern="[0-9]*"
          inputMode="numeric"
          error={error}
          defaultValue={initialValue}
          background={background}
          disabled={disabled}
        />
      </EntryFeeWrapper>
    </EntryFeeInputContainer>
  );
};
