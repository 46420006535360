import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  isToday,
  format,
  isThisWeek,
} from 'date-fns';

export const getTimestampString = (
  value: string | number,
  amount: string | number,
): string => {
  return `${value} ${amount}${value === 1 ? '' : 's'} ago`;
};

export const getRelativeTimestampInPast = (
  timestamp: number | Date,
  comparingTimeStamp: number | Date,
): string | null => {
  if (!timestamp) {
    return null;
  }
  const minutesAgoFromNow = differenceInMinutes(comparingTimeStamp, timestamp);

  if (minutesAgoFromNow < 60) {
    return getTimestampString(minutesAgoFromNow, 'minute');
  }

  const hoursAgoFromNow = differenceInHours(comparingTimeStamp, timestamp);

  if (isToday(timestamp)) {
    return getTimestampString(hoursAgoFromNow, 'hour');
  }

  const daysAgoFromNow = differenceInDays(comparingTimeStamp, timestamp);

  if (daysAgoFromNow < 7) {
    return getTimestampString(daysAgoFromNow, 'day');
  }

  const monthsAgoFromNow = differenceInMonths(comparingTimeStamp, timestamp);
  const weeksAgoFromNow = differenceInWeeks(comparingTimeStamp, timestamp);

  if (monthsAgoFromNow > 0 && weeksAgoFromNow >= 5) {
    return getTimestampString(monthsAgoFromNow, 'month');
  }

  return getTimestampString(weeksAgoFromNow, 'week');
};

export const getNotificationTimestamp = (
  timestampInSecounds: number,
): string | null => {
  const newTimestamp = timestampInSecounds * 1000;
  const timestampDate = new Date(newTimestamp);

  const isTimestampFromThisWeek = isThisWeek(timestampDate);

  if (isTimestampFromThisWeek) {
    const day = format(timestampDate, 'ccc');
    const time = format(timestampDate, 'h:mmaaa');

    return `${day}\u00A0${time.toLowerCase()}`;
  }

  return getRelativeTimestampInPast(newTimestamp, Date.now());
};
