export default {
  social: {
    Instagram: 'playerslounge',
    Twitch: 'playerslounge',
    Twitter: '_playerslounge',
    Facebook: 'playersloungegaming',
    AppStore: 'us/app/playerslounge/id1474870497',
  },
  apps: {
    iOS: '',
  },
  footer: {
    links: [
      {
        text: 'About',
        href: '/about',
      },
      {
        text: 'FAQ',
        href: '/faq',
      },
      {
        text: 'Careers',
        href: 'https://angel.co/players_lounge/jobs',
      },
      {
        text: 'Support',
        href: '/support',
      },
      {
        text: 'Terms of Service',
        href: '/terms',
      },
      {
        text: 'Press',
        href: '/press',
      },
      {
        text: 'Refer a Friend',
        href: '/referrals',
      },
      {
        text: 'Privacy Policy',
        href: '/privacy',
      },
    ],
    disclaimer:
      'Players’ Lounge is not endorsed by, directly affiliated with, maintained or sponsored by Apple Inc, Electronic Arts, Activision Blizzard, Take-Two Interactive, Microsoft, Xbox, Sony, PlayStation or Epic Games. All content, games titles, trade names and/or trade dress, trademarks, artwork and associated imagery are trademarks and/or copyright material of their respective owners.',
  },
};
