import React from 'react';
import {
  PlrContainerRight,
  PlrContainerLeft,
  PlrContainer,
  PlrValues,
  PlrSizes,
  PlrTypes,
} from './plr-tile.styles';

type PlrTileProps = {
  plr: PlrValues | null;
  size?: PlrSizes;
  type?: PlrTypes;
  bold?: boolean;
  alignment?: 'center' | 'left' | 'right';
};

const PlrTile = ({
  plr = null,
  size = 'small',
  bold = true,
  alignment = 'center',
  type = 'transparentWhite',
}: PlrTileProps) => {
  if (alignment === 'right') {
    return (
      <PlrContainerRight plr={plr} type={type} bold={bold} size={size}>
        {plr}
      </PlrContainerRight>
    );
  }

  if (alignment === 'left') {
    return (
      <PlrContainerLeft plr={plr} type={type} bold={bold} size={size}>
        {plr}
      </PlrContainerLeft>
    );
  }

  return (
    <PlrContainer plr={plr} type={type} size={size} bold={bold}>
      {plr}
    </PlrContainer>
  );
};

export default PlrTile;
export type { PlrValues };
