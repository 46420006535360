import styled from 'styled-components';
import {
  fontL,
  fontM,
  fontS,
  offline,
  fontXL,
  font3XL,
  plPlusAnimation,
  fontXS,
} from 'components/styles';

export type UsernameFontSize =
  | 'extraSmall'
  | 'small'
  | 'mediumSmall'
  | 'medium'
  | 'mediumLarge'
  | 'largest';

const size: Record<UsernameFontSize, string> = {
  extraSmall: fontXS,
  small: fontS,
  mediumSmall: fontM,
  medium: fontL,
  mediumLarge: fontXL,
  largest: font3XL,
};

const UsernameSpan = styled.span<{
  fontSize: UsernameFontSize;
  offline: boolean;
  animated: boolean;
  underlined: boolean;
}>`
  color: ${({ theme }) => theme.new.content};
  ${({ fontSize }) => size[fontSize]};
  text-decoration: ${({ underlined }) => underlined && 'underline'};
  ${({ animated }) => animated && plPlusAnimation};
  ${offline};
`;

export default UsernameSpan;
