import styled from 'styled-components';
import { fontL, fontS, fontXXS } from 'components/styles';
import Surface from 'components/atoms/surface';

export const StyledSurface = styled(Surface)`
  margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const SectionHeading = styled.h2`
  ${fontL}
  color: ${({ theme }) => theme.new.content};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const RulesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const Rule = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const RuleDescription = styled.p`
  ${fontS}
  color: ${({ theme }) => theme.new.content};
`;

export const RuleTitle = styled.h3`
  ${fontXXS}
  color: ${({ theme }) => theme.new.subContent};
`;
