import React, { PropsWithChildren } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import {
  space,
  color,
  layout,
  flexbox,
  grid,
  border,
  typography,
  shadow,
  position,
  variant,
} from 'styled-system';

export const Box = styled.div(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  space,
  color,
  layout,
  flexbox,
  grid,
  border,
  typography,
  shadow,
  position,
);

const innerBoxHorizontalMargin = (type: string) => (type === 'FIND' ? 3 : 0);

const innerBoxPadding = (type: string) => {
  if (type === 'BIG_MONEY' || type === 'ACTIVE') return '14px';

  return type !== 'FIND' ? 3 : 0;
};

const MatchBoxWrapper = styled(Box)<{
  type: string | null;
  background?: string | null;
}>`
  display: inline-block;
  border-radius: ${({ theme }) => theme.spacing.customSpacing('10px')};
  min-width: ${({ theme }) => theme.spacing.customSpacing('157px')};
  height: ${({ theme }) => theme.spacing.customSpacing('188px')};
  padding: ${({ type, theme }) =>
    type === 'plc' ? theme.spacing.customSpacing('2px') : 0};
  background: ${({ theme, background }) =>
    background || theme.palette.background.secondary};
`;

const GoldCircleWrapper = styled(Box)<{ type: string }>(
  {
    height: '100%',
  },
  variant({
    prop: 'type',
    variants: {
      BIG_MONEY: {
        padding: '2px',
        borderRadius: '10px',
        background: (theme: DefaultTheme) =>
          `linear-gradient(to right top, ${theme.instantMatch.goldGradient}, 61.8033988%, ${theme.instantMatch.secondary})`,
      },
    },
  }),
);

const MatchBoxInner = styled(Box)<{
  type: string | null;
  lateralBorder: string | null;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ type }) =>
    type !== 'PLC' ? 'space-between' : 'flex-start'};
  height: 100%;
  max-height: ${({ type }) => (type === 'FIND' ? '152px' : '188px')};
  border-radius: ${({ type }) => (type === 'FIND' ? 0 : '8px')};
  border-left: ${({ lateralBorder }) => lateralBorder};
  border-right: ${({ lateralBorder }) => lateralBorder};
  border: ${({ type, theme }) =>
    type === 'PLC' ? `2px solid ${theme.palette.background.primary}` : null};
`;

const positionBorder = ({
  positioned,
  theme,
}: {
  positioned: 'top' | 'bottom';
  theme: DefaultTheme;
}) => {
  const positions = {
    top: `border-bottom: 1px solid ${theme.instantMatch.find.lines}`,
    bottom: `border-top: 1px solid ${theme.instantMatch.find.lines}`,
  };
  return positions[positioned];
};

const OuterBorderSkeleton = styled.div<{
  positioned: 'top' | 'bottom';
}>`
  ${positionBorder}
`;

const InnerBorderSkeleton = styled.div`
  height: ${({ theme }) => theme.spacing.customSpacing('17px')};
  margin: 0 ${({ theme }) => theme.spacing.BASE_SPACING};
  border-left: 1px solid ${({ theme }) => theme.instantMatch.find.lines};
  border-right: 1px solid ${({ theme }) => theme.instantMatch.find.lines};
`;

const SkeletonBorder = ({
  type,
  positioned,
}: {
  type: string;
  positioned: 'top' | 'bottom';
}) => {
  return type === 'FIND' ? (
    <OuterBorderSkeleton
      data-testid={`skeleton-${positioned}`}
      positioned={positioned}
    >
      <InnerBorderSkeleton>&nbsp;</InnerBorderSkeleton>
    </OuterBorderSkeleton>
  ) : null;
};

const innerBoxStyle = (type: string, background: string | null) =>
  type === 'BIG_MONEY' ? { background } : null;

type NewMatchBoxProps = {
  border?: string | null;
  lateralBorder?: string | null;
  background?: string | null;
  color?: string | null;
  type: string;
};

export const NewMatchBox = ({
  children,
  border = null, // eslint-disable-line no-shadow
  lateralBorder = null,
  background = null,
  color = null, // eslint-disable-line no-shadow
  type,
}: PropsWithChildren<NewMatchBoxProps>) => {
  return (
    <MatchBoxWrapper
      data-testid="match-box-wrapper"
      type={type}
      background={background}
      // @ts-ignore
      color={color || undefined}
    >
      <GoldCircleWrapper data-testid="gold-outline" type={type}>
        <SkeletonBorder type={type} positioned={'top'} />
        <MatchBoxInner
          type={type}
          border={border}
          lateralBorder={lateralBorder}
          // @ts-ignore
          style={innerBoxStyle(type, background)}
          p={innerBoxPadding(type)}
          mx={innerBoxHorizontalMargin(type)}
        >
          {children}
        </MatchBoxInner>
        <SkeletonBorder type={type} positioned={'bottom'} />
      </GoldCircleWrapper>
    </MatchBoxWrapper>
  );
};

export const ContainerBox = styled.div(
  {
    margin: 'auto',
    maxWidth: '1246px',
    fontSize: '12px',
  },
  space,
  color,
  layout,
  flexbox,
  grid,
  border,
  typography,
);
