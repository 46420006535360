import React from 'react';
import {
  EmptySpinner,
  Spinner,
  CenterSpinnerWrapper,
  SpinnerPosition,
} from './loading-spinner.styles';

const sourcePath = {
  blueThin: 'https://images.playerslounge.co/img/icons/loadingSpinner.png',
  blue: 'https://images.playerslounge.co/img/icons/blueLoadingSpinnerThick.png',
  white: 'https://images.playerslounge.co/img/icons/loadingSpinnerWhite.png',
  transparent: null,
};

type LoadingSpinnerProps = {
  color?: keyof typeof sourcePath;
  size?: string | number;
  left?: string;
  position?: SpinnerPosition;
  fullWidth?: boolean;
};

const LoadingSpinner = ({
  color = 'blue',
  size = '20px',
  left,
  position = 'relative',
}: LoadingSpinnerProps) => {
  const source = sourcePath[color] ? sourcePath[color] : null;
  if (!source)
    return (
      <EmptySpinner
        data-testid="empty-loading-spinner"
        size={size}
        left={left}
        position={position}
      />
    );
  return (
    <Spinner
      data-testid="loading-spinner"
      src={source}
      height={size}
      width={size}
      alt="searching"
      left={left}
      position={position}
    />
  );
};

export const CenterSpinner = ({ fullWidth, ...rest }: LoadingSpinnerProps) => (
  <CenterSpinnerWrapper fullWidth={fullWidth || false}>
    <LoadingSpinner {...rest} />
  </CenterSpinnerWrapper>
);

export default LoadingSpinner;
