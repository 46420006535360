import { fontM, fontXL, TOUCHABLE_STYLE } from 'components/styles';
import styled from 'styled-components';

export const Wrapper = styled.div<{
  topPadding: number;
  bottomPadding: number;
}>`
  background-color: ${({ theme }) => theme.new.surface};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${({ theme, topPadding }) =>
    theme.spacing.customSpacing(topPadding)};
  padding-bottom: ${({ theme, bottomPadding }) =>
    theme.spacing.customSpacing(bottomPadding)};
`;

export const Content = styled.div`
  overflow-y: auto;
`;

export const SpinnerWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const InternalContent = styled.div`
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const Footer = styled.div<{ disabled: boolean }>`
  background-color: ${({ theme }) => theme.new.surface};
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  display: flex;
  flex-direction: column;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  position: relative;
  margin-top: 0px;
  border-top: 1px solid ${({ theme }) => theme.new.divisions};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing.DOUBLE_BASE_SPACING};
`;

export const HeaderIconWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  cursor: pointer;
  height: 24px;
  ${TOUCHABLE_STYLE}
`;

export const ErrorHeading = styled.h1`
  color: ${({ theme }) => theme.new.content};
  ${fontXL}
  padding-bottom: ${({ theme }) => theme.spacing.DOUBLE_BASE_SPACING};
`;

export const ErrorContent = styled.h1`
  color: ${({ theme }) => theme.new.content};
  ${fontM}
`;
