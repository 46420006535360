import React, { FC, SVGProps } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import { ValidColor } from '../colors';

import { MaterialIcons, standardIconComponentMap } from './standard-icons';
import { SocialIcons, socialIconsComponentMap } from './social-icons';
import { GameIcons, gameIconsComponentMap } from './game-icons';
import { PlatformIcons, platformIconsComponentMap } from './platform-icons';

export const AvailableIcons = {
  ...MaterialIcons,
  ...SocialIcons,
  ...GameIcons,
  ...PlatformIcons,
};

export type AvailableIcons =
  | MaterialIcons
  | SocialIcons
  | GameIcons
  | PlatformIcons;

const componentMap: Record<AvailableIcons, FC<SVGProps<SVGElement>>> = {
  ...standardIconComponentMap,
  ...socialIconsComponentMap,
  ...gameIconsComponentMap,
  ...platformIconsComponentMap,
};

export const gameSeriesIconMap: (
  theme?: DefaultTheme,
) => Record<string, AvailableIcons> = (theme) => ({
  apex: GameIcons.APEX,
  cfb: GameIcons.CFB,
  cod: GameIcons.COD,
  destiny: GameIcons.DESTINY,
  dragonball: GameIcons.DRAGONBALL,
  fifa: GameIcons.FIFA,
  fortnite: GameIcons.FORTNITE,
  fn: GameIcons.FORTNITE,
  halo: GameIcons.HALO,
  madden: GameIcons.MADDEN,
  mlb: GameIcons.MLB,
  nba: GameIcons.NBA,
  generic: MaterialIcons.CONTROLLER,
  rocketleague:
    theme?.new.theme === 'light'
      ? GameIcons.ROCKET_LEAGUE_LIGHT
      : GameIcons.ROCKET_LEAGUE_DARK,
});

export const platformIconMap: (
  theme?: DefaultTheme,
) => Record<string, AvailableIcons> = () => ({
  any: MaterialIcons.CONTROLLER,
  controller: MaterialIcons.CONTROLLER,
  // kbm: PlatformIcons.KBM,
  ngcrossplay: MaterialIcons.CONTROLLER,
  // pc: PlatformIcons.PC,
  ps4: PlatformIcons.PLAYSTATION,
  ps5: PlatformIcons.PLAYSTATION,
  ps: PlatformIcons.PLAYSTATION,
  playstation: PlatformIcons.PLAYSTATION,
  xboxOne: PlatformIcons.XBOX,
  xboxX: PlatformIcons.XBOX,
  xbox: PlatformIcons.XBOX,
  // switch: PlatformIcons.SWITCH,
  activision: PlatformIcons.ACTIVISION,
  epic: PlatformIcons.EPIC,
  generic: MaterialIcons.CONTROLLER,
});

export type IconographyProps = {
  name: AvailableIcons;
  color?: ValidColor;
  size?: number;
  viewBox?: string;
};

export const Iconography = ({
  name,
  color,
  size = 24,
  viewBox = '0 0 24 24',
  ...rest
}: IconographyProps) => {
  const Component = componentMap[name];
  const theme = useTheme();

  const actualColor = color || theme.new.content;
  if (process.env.NODE_ENV === 'test') return <div {...rest} />;
  return (
    <Component
      width={size}
      height={size}
      fill={actualColor}
      viewBox={viewBox}
      {...rest}
    />
  );
};
