/* eslint-disable no-console */
export const RED = '\u001b[1;31m';
export const GREEN = '\u001b[1;32m';
export const YELLOW = '\u001b[1;33m';
export const BLUE = '\u001b[1;34m';
export const RESET_COLOR = '\u001B[0m';

const LOG_LEVEL_DEBUG = process.env.NODE_ENV === 'development';

const getColouredTime = (time: number): string => {
  if (time > 1000) return `🐌 ${RED}${time}ms${RESET_COLOR}`;
  if (time > 50) return `🏎  ${YELLOW}${time}ms${RESET_COLOR}`;
  return `🚀  ${GREEN}${time}ms${RESET_COLOR}`;
};

type syncTimerFunc = (
  callback: (...props: unknown[]) => unknown,
  name: string,
) => (...props: unknown[]) => unknown;

export const timeSync: syncTimerFunc = (callback, name) => (...props) => {
  const startTime = Date.now();

  const result = callback(...props);
  const timeTaken = Date.now() - startTime;

  if (LOG_LEVEL_DEBUG) {
    console.info(`${getColouredTime(timeTaken)} - sync - ${name}`);
  }

  return result;
};

export const timeAsync = <TFuncReturn, TFuncArgs extends []>(
  callback: (...props: TFuncArgs[]) => Promise<TFuncReturn>,
  name: string,
): ((...props: TFuncArgs[]) => Promise<TFuncReturn>) => async (...props) => {
  const startTime = Date.now();

  const result = await callback(...props);
  const timeTaken = Date.now() - startTime;

  if (LOG_LEVEL_DEBUG) {
    console.info(`${getColouredTime(timeTaken)} - async - ${name}`);
  }

  return result;
};

export const logger = (...props: unknown[]) => {
  if (LOG_LEVEL_DEBUG) {
    console.info(...props);
  }
};
