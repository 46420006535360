import React, { MouseEvent, MouseEventHandler, PropsWithChildren } from 'react';
import styled from 'styled-components';
import NextLink from 'next/link';
import { useApp } from 'components/util/app-context';
import { fontS, TOUCHABLE_STYLE } from 'components/styles';
import routing from 'shared/routing';
import { LinkAnalyticsObject } from 'analytics/get-analytics-object';

export type LinkProps = PropsWithChildren<{
  disabled?: boolean;
  href?: string;
  analytics?: LinkAnalyticsObject;
  openInNewTab?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}>;

const StyledLink = styled.a`
  ${fontS}
  text-decoration: 'none';
  color: ${({ theme }) => theme.navigationLink};
  ${TOUCHABLE_STYLE}
`;

// @ts-ignore
const StyledNextLink = styled(NextLink)`
  ${fontS}
  text-decoration: 'none';
  color: ${({ theme }) => theme.navigationLink};
  ${TOUCHABLE_STYLE}
`;

const internalOnClick = ({
  analytics,
  href,
  openInNewTab,
}: {
  href?: string;
  analytics?: LinkAnalyticsObject;
  openInNewTab?: boolean;
}): void => {
  if (analytics) {
    const { event, properties } = analytics;
    // @ts-ignore
    global.analytics.track(event, {
      ...properties,
      destination: href,
      opensNewTab: openInNewTab,
    });
  }
};

type GetHrefType = {
  isApp: boolean;
  rawHref?: string;
  deepLink?: string;
  regex?: RegExp;
};

const getHref = ({
  rawHref,
  isApp,
  deepLink,
  regex,
}: GetHrefType): string | undefined => {
  if (!rawHref) return;
  if (!regex) return rawHref;
  if (isApp && deepLink) {
    const result = regex.exec(rawHref);
    if (!result) return rawHref;
    const [, ...matchGroups] = result;
    return matchGroups.reduce((string, matchGroup, index) => {
      if (!matchGroups) return string;
      return string.replace(`:regexGroup${index}`, matchGroup);
    }, deepLink);
  }

  return rawHref;
};

const Link = ({
  className,
  href: rawHref,
  children,
  analytics,
  openInNewTab,
  onClick,
  ...rest
}: LinkProps) => {
  const { isApp } = useApp();

  const knownRoute = routing.find(({ regex }) => regex.test(rawHref || ''));
  const href = getHref({ rawHref, isApp, ...knownRoute });
  const shouldUseDeepLink = Boolean(knownRoute?.deepLink) && isApp;

  if (knownRoute?.isInternal && !shouldUseDeepLink) {
    return (
      <StyledNextLink
        href={href}
        className={className}
        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
          // Add document click to close all modals when navigating
          document.body.click();
          if (onClick) onClick(e);
          internalOnClick({ analytics, href, openInNewTab });
        }}
        target={openInNewTab ? '_blank' : null}
        rel={openInNewTab ? 'noopener noreferrer' : null}
        data-testid={href}
        {...rest}
      >
        {children}
      </StyledNextLink>
    );
  }

  return (
    <StyledLink
      href={href}
      className={className}
      onClick={(e) => {
        if (onClick) onClick(e);
        internalOnClick({ analytics, href, openInNewTab });
      }}
      target={openInNewTab ? '_blank' : undefined}
      rel={openInNewTab ? 'noopener noreferrer' : undefined}
      data-testid="link"
      {...rest}
    >
      {children}
    </StyledLink>
  );
};

Link.displayName = 'Link';

export default Link;
