import { DropdownList, DropdownPicker } from 'components/atoms/dropdown';
import styled from 'styled-components';
import SVG from 'svgs';
import { fontM, fontS, fontXS, MIN_TABLET } from 'components/styles';

export const StyledDropdownList = styled(DropdownList)`
  @media (min-width: ${MIN_TABLET}) {
    width: ${({ theme }) => theme.spacing.customSpacing('269px')};
    top: ${({ theme }) => theme.spacing.customSpacing('40px')};
    right: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
    z-index: ${({ theme }) => theme.depth.DROPDOWN};
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;

    a {
      padding-right: ${({ theme }) => theme.spacing.customSpacing('12px')};
    }
  }
`;

export const NotificationWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING} 0;
  width: 100%;
  max-width: 90vw;
  display: flex;
  flex-direction: column;

  @media (min-width: ${MIN_TABLET}) {
    max-width: ${({ theme }) => theme.spacing.customSpacing('230px')};
  }
  color: ${({ theme }) => theme.new.subContent};
  :hover {
    color: ${({ theme }) => theme.new.subContent};
  }
`;

export const NotificationTitle = styled.h3`
  ${fontM}
  color: ${({ theme }) => theme.new.content};
`;

export const NotificationBody = styled.p`
  ${fontS}
  color: ${({ theme }) => theme.new.content};
  @media (min-width: ${MIN_TABLET}) {
    max-width: ${({ theme }) => theme.spacing.customSpacing('200px')};
  }
`;

export const NotificationTime = styled.p`
  ${fontXS}
  margin-top: auto;
  color: ${({ theme }) => theme.new.subContent};
  align-self: flex-end;
  text-align: left;
`;

export const NotificationBell = styled(DropdownPicker)<{ isOpen: boolean }>`
  fill: ${({ theme, isOpen }) =>
    isOpen ? theme.dropdown.item.selected : theme.dropdown.item.icon};
  transition: 0.25s;

  :hover,
  :active,
  :focus {
    fill: ${({ theme }) => theme.dropdown.item.selected};
  }
`;

export const UnreadDot = styled.div`
  height: ${({ theme }) => theme.spacing.customSpacing('7px')};
  width: ${({ theme }) => theme.spacing.customSpacing('7px')};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.messaging.notificationBG};
  left: ${({ theme }) => theme.spacing.customSpacing('-10px')};
  position: absolute;
  top: ${({ theme }) => theme.spacing.customSpacing('7px')};
`;

export const TitleWrapper = styled.div`
  position: relative;
`;

export const BellIcon = styled(SVG)`
  display: block;
`;

export const SeeMore = styled.span`
  color: ${({ theme }) => theme.new.information.background};
`;

export const LoadingWrapper = styled.div`
  width: 100%;
`;
