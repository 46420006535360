import React from 'react';
import styled from 'styled-components';

type NewRadioProps = {
  checked?: boolean;
  isTransparent?: boolean;
};

const RadioWrapper = styled.div<{
  checked: boolean;
  isTransparent: boolean;
}>`
  box-sizing: border-box;
  width: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  height: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, checked }) =>
    checked ? theme.new.information.background : theme.new.divisions};

  background-color: ${({ theme, isTransparent }) => {
    if (isTransparent) {
      return 'transparent';
    }
    return theme.new.background;
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 150ms;
`;

const CenterCircle = styled.div<{ checked: boolean }>`
  width: ${({ theme, checked }) =>
    checked ? theme.spacing.BASE_SPACING : '0rem'};
  height: ${({ theme, checked }) =>
    checked ? theme.spacing.BASE_SPACING : '0rem'};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.new.information.background};

  transition: width 150ms cubic-bezier(0.33, 0.23, 0.55, 1.76),
    height 150ms cubic-bezier(0.33, 0.23, 0.55, 1.76);
`;

export const NewRadio = ({
  checked = false,
  isTransparent = false,
}: NewRadioProps) => {
  return (
    <RadioWrapper checked={checked} isTransparent={isTransparent}>
      <CenterCircle checked={checked} />
    </RadioWrapper>
  );
};
