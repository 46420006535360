export const crossplayConsoles = ['ps5', 'xboxX'];
export const crossplayGames = ['madden', 'nba', 'fifa', 'eafc', 'cfb'];

export const isCrossplayGame = (game: string): boolean => {
  return crossplayGames.includes(game);
};

export const isCrossplayConsole = (console: string): boolean => {
  return crossplayConsoles.includes(console);
};

export const isCrossplay = (console: string, game: string): boolean => {
  return isCrossplayGame(game) && isCrossplayConsole(console);
};

export const convertConsoleToCorrectUpper = (console: string): string => {
  switch (console) {
    case 'XBOXX':
      return 'XboxX';
    case 'XBOXS':
      return 'XboxS';
    case 'XBOXONE':
      return 'XboxOne';
    default:
      return console;
  }
};
