import { FeatureFlagFragment } from 'graphpl/cms';

export const featureFlagFunc = (
  allFeatureFlags: Record<string, FeatureFlagFragment>,
) => (flag: string): FeatureFlagFragment => {
  if (!allFeatureFlags?.[flag])
    return {
      id: 'no flag',
      featureFlag: flag,
      description: '',
      enabled: false,
    };

  return allFeatureFlags[flag];
};
