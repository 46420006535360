import styled from 'styled-components';

export type SpinnerPosition = 'absolute' | 'relative' | 'fixed';

export const Spinner = styled.img<{
  position: SpinnerPosition;
  width: string | number;
  height: string | number;
  left?: string;
}>`
  position: ${({ position }) => position};
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  height: ${({ height }) =>
    typeof height === 'number' ? `${height}px` : height};
  left: ${({ left }) => left};
  animation: search-spin infinite 2s linear;
  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }

  @keyframes search-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const EmptySpinner = styled.div<{
  position: SpinnerPosition;
  size: string | number;
  left?: string;
}>`
  position: ${({ position }) => position};
  width: ${({ size }) => (typeof size === 'number' ? `${size}px` : size)};
  height: ${({ size }) => (typeof size === 'number' ? `${size}px` : size)};
  left: ${({ left }) => left};
`;

export const CenterSpinnerWrapper = styled.div<{ fullWidth: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
`;
