import styled from 'styled-components';
import { fontS, fontXL, TOUCHABLE_STYLE } from 'components/styles';

export const SectionHeader = styled.span`
  ${fontXL};
  color: ${({ theme }) => theme.new.content};
`;

export const SectionLink = styled.div`
  ${fontS}
  width: fit-content;
  color: ${({ theme }) => theme.new.information.background};
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING};
  cursor: pointer;
  ${TOUCHABLE_STYLE}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RulesWrapper = styled.div`
  margin: ${({ theme }) => `${theme.spacing.BASE_SPACING} 0`};
`;
