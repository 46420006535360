import React from 'react';
import Palette, { ValidColor } from 'components/styles/colors';
import Action from './action';
import Content from './content';
import Notification from './notification';
import Social from './social';
import Logo from './logo';
import Status from './status';
import Platform from './platform';
import Miscellaneous from './miscellaneous';
import Investors from './investors';
import PickEmLogo from './pick-em-logo';

export type SVGImages =
  | keyof typeof Action
  | keyof typeof Content
  | keyof typeof Logo
  | keyof typeof PickEmLogo
  | keyof typeof Notification
  | keyof typeof Social
  | keyof typeof Status
  | keyof typeof Platform
  | keyof typeof Miscellaneous
  | keyof typeof Investors;

type SVGType = (
  props: React.SVGProps<SVGSVGElement> & {
    icon: SVGImages;
    gradientColors?: string[];
  },
) => React.JSX.Element;

const allSVGs: Record<SVGImages, SVGType> = {
  ...Action,
  ...Content,
  ...Logo,
  ...PickEmLogo,
  ...Notification,
  ...Social,
  ...Status,
  ...Platform,
  ...Miscellaneous,
  ...Investors,
};

type SVGProps = {
  icon: SVGImages;
  className?: string;
  gradientColors?: string[];
  fill?: ValidColor;
};

const SVG = ({
  icon,
  className,
  fill = Palette.white,
  ...rest
}: SVGProps & React.SVGProps<SVGSVGElement>) => {
  const attrs = {
    className,
    'aria-hidden': true,
    focusable: false,
    fill,
    ...rest,
  };

  if (!allSVGs[icon]) {
    // eslint-disable-next-line no-console
    console.error(
      `SVG ${icon} does not exist in allSVGs. Please check the icon name.`,
    );
    return null;
  }

  const Icon = allSVGs[icon];

  return <Icon data-testid="svg" icon={icon} {...attrs} />;
};

SVG.images = Object.keys(allSVGs) as SVGImages[];

export default SVG;
