import React from 'react';
import {
  SectionTitle,
  SectionDescription,
  RuleWrapper,
} from './match-details-section-rule.styles';

type MatchDetailsSectionRuleProps = {
  rule: {
    __typename?: 'Rule' | undefined;
    valueName?: string | null | undefined;
    optionDescription?: string | null | undefined;
    optionName?: string | null | undefined;
    isDefault?: boolean | null | undefined;
  };
};

const MatchDetailsSectionRule = ({ rule }: MatchDetailsSectionRuleProps) => {
  return (
    <RuleWrapper>
      <SectionTitle>{rule.optionName}</SectionTitle>
      <SectionDescription>{rule.optionDescription}</SectionDescription>
    </RuleWrapper>
  );
};

export default MatchDetailsSectionRule;
