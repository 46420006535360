import { LobbiesFragment } from 'graphpl/core';

type NavigationProps = {
  action: 'NAVIGATE';
  source: string;
  data: {
    url: string;
    targetId?: string;
  };
};

type DismissModalProps = {
  action: 'DISMISS_MODAL';
  source: string;
};

type RefetchUserProps = {
  action: 'REFETCH_USER';
  source: string;
};

type LobbyUpdateProps = {
  action: 'LOBBY_UPDATE';
  source: string;
  data: LobbiesFragment;
};

type MessageParentProps =
  | NavigationProps
  | DismissModalProps
  | RefetchUserProps
  | LobbyUpdateProps;

export const messageParent = (props: MessageParentProps) => {
  if (window?.parent?.postMessage) {
    window?.parent?.postMessage(JSON.stringify(props, null, 2), '*');
  }
  // @ts-expect-error
  if (window?.ReactNativeWebView?.postMessage) {
    // @ts-expect-error
    window?.ReactNativeWebView?.postMessage(JSON.stringify(props, null, 2));
  }
};
