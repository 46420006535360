import React from 'react';

import styled from 'styled-components';
import { MatchCompetitorFragment } from 'graphpl/core';
import {
  AddAvatar,
  Avatar,
  AvatarSize,
  MaxUsersAvatar,
  wrapperSizes,
} from './avatar';

const Wrapper = styled.div<{ size: AvatarSize; zIndex?: number }>`
  margin-left: ${({ size }) => `-${wrapperSizes[size].size / 2}`}px;
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
`;

const AvatarsWrapper = styled.div<{ size: AvatarSize }>`
  display: flex;
  flex-direction: row;
  margin-left: ${({ size }) => wrapperSizes[size].size / 2}px;
`;

export const GroupAvatar = ({
  users,
  size,
  addTeam,
  maxUsers,
}: {
  users: Array<MatchCompetitorFragment>;
  size: AvatarSize;
  addTeam?: boolean;
  maxUsers?: number;
}) => {
  const exceedsMaxUsersToRender = maxUsers && users.length > maxUsers;
  return (
    <AvatarsWrapper size={size}>
      {users?.map((user, index) => {
        if (maxUsers && index >= maxUsers) return null;
        return (
          <Wrapper key={user?.id} size={size}>
            <Avatar
              user={user}
              size={size}
              disableActivityDot
              displayOffline={false}
            />
          </Wrapper>
        );
      })}
      {exceedsMaxUsersToRender && (
        <Wrapper key="exceeds-max-users" size={size} zIndex={1}>
          <MaxUsersAvatar size={size} userCount={users.length - maxUsers} />
        </Wrapper>
      )}
      {addTeam && users?.length < 4 && (
        <Wrapper key="add-team-mate" size={size}>
          <AddAvatar size={size} />
        </Wrapper>
      )}
    </AvatarsWrapper>
  );
};
