import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import Switch from 'react-switch';
import { usePrevious } from 'helpers';
import { parseToRgb, rgb } from 'polished';
import StyledCheckbox, { SwitchBorder } from './checkbox.styles';

type CheckboxProps = {
  defaultChecked?: boolean;
  variant?: 'checkbox' | 'toggle';
  id: string;
  onChange: (value: boolean) => void;
  disabled?: boolean;
};

const Checkbox = ({
  defaultChecked,
  variant = 'checkbox',
  id,
  onChange,
  disabled,
}: CheckboxProps) => {
  const theme = useTheme();
  const prevChecked = usePrevious(defaultChecked);
  const [isChecked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    if (prevChecked && !defaultChecked && isChecked) {
      setChecked(false);
    }
  }, [defaultChecked, isChecked, prevChecked]);

  const handleSwitchChange = (value: boolean) => {
    setChecked(value);
    onChange?.(value);
  };

  const handleInputChange = () => {
    const oldValue = isChecked || defaultChecked || false;
    const value = !oldValue;
    setChecked(value);
    onChange?.(value);
  };

  if (variant === 'toggle') {
    return (
      <SwitchBorder>
        <Switch
          onChange={(value, e) => {
            e.stopPropagation();
            handleSwitchChange(value);
          }}
          checked={Boolean(isChecked ?? defaultChecked)}
          checkedIcon={false}
          offColor={rgb(parseToRgb(theme.new.background))}
          onColor={rgb(parseToRgb(theme.new.information.background))}
          uncheckedIcon={false}
          handleDiameter={16}
          height={22}
          width={38}
          id={id}
          data-testid={`${id}-checkbox`}
          activeBoxShadow={undefined}
          disabled={disabled}
        />
      </SwitchBorder>
    );
  }

  const getIsChecked = () => {
    if (!defaultChecked) {
      return false;
    }
    return true;
  };

  return (
    <StyledCheckbox
      handleInputChange={handleInputChange}
      checked={getIsChecked() || isChecked}
      id={id}
      disabled={disabled}
    />
  );
};

export default Checkbox;
