// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useUser } from 'components/util/user-context';
import UserBalance from 'components/molecules/user-balance';
import GlobalNavElement from 'components/atoms/global-nav-element';
import Notifications from 'components/organisms/global-header/notification-dropdown';
import { useGlobal } from 'components/util/global-context';
import {
  BottomBar,
  LobbyBottomBarWrapper,
  BottomBarNotificationsAdjust,
  BottomBarWrapper,
  BottomBarContentAdjust,
} from './global-bottom-bar.styles';
import { NewCreateMatchButton } from '../new-create-match-flow';

const CreateAMatch = dynamic(import('components/organisms/create-match'), {
  ssr: false,
});

const ChatPanel = dynamic(
  // @ts-ignore
  import('components/organisms/lobby-header').then((mod) => mod.ChatPanel),
  {
    ssr: false,
  },
);
const Challenges = dynamic(
  // @ts-ignore
  import('components/organisms/lobby-header').then((mod) => mod.Challenges),
  {
    ssr: false,
  },
);

type TestHook = {
  createAMatchBottom?: boolean;
  challengesBottom?: boolean;
  chatBottom?: boolean;
};

export type LobbyBottomBarType = {
  testHook?: TestHook;
  toggleCreateMatch: () => void;
  forceCreateMatch?: boolean;
  lobby: any;
  defaultGameType: string | undefined;
  setDefaultGameType: (val: string | undefined) => void;
  gameSeriesName: string;
  gameSeriesId: string;
  gameFormatMatch?: boolean;
  selectedMatch?: string | null;
  setSelectedMatch?: (val: string | null) => void;
};

type GlobalBottomBarType = {
  isOpen?: boolean;
  lobbyBottomBar: LobbyBottomBarType | null;
  shouldShowBottomBarNav?: boolean;
};

const LobbyBottomBar = ({
  testHook,
  forceCreateMatch,
  lobby,
  toggleCreateMatch,
  defaultGameType,
  setDefaultGameType,
  gameSeriesName,
  gameSeriesId,
  gameFormatMatch = false,
  selectedMatch,
  setSelectedMatch,
}: LobbyBottomBarType): JSX.Element => {
  const { featureFlag } = useGlobal();
  const newMatchFlowEnabled = featureFlag('new-create-match-flow').enabled;

  return (
    <LobbyBottomBarWrapper>
      {lobby?.id?.includes('dfs') ? null : (
        <Challenges
          // @ts-ignore
          id="bottom-challenge"
          isOpen={testHook?.createAMatchBottom}
          toggleCreateMatch={toggleCreateMatch}
          setDefaultGameType={setDefaultGameType}
          mobile
        />
      )}
      {newMatchFlowEnabled ? (
        <NewCreateMatchButton />
      ) : (
        <CreateAMatch
          id="bottom-create-match"
          isOpen={testHook?.challengesBottom || forceCreateMatch}
          lobby={lobby}
          defaultGameType={defaultGameType}
          setDefaultGameType={setDefaultGameType}
          mobile
          // @ts-ignore
          gameFormatMatch={gameFormatMatch}
          selectedMatch={selectedMatch as null}
          setSelectedMatch={setSelectedMatch}
          toggleCreateMatchParent={toggleCreateMatch}
        />
      )}
      <ChatPanel
        // @ts-ignore
        id="bottom-chat"
        gameSeriesName={gameSeriesName}
        gameSeriesId={gameSeriesId}
        isOpen={testHook?.chatBottom}
        mobile
      />
    </LobbyBottomBarWrapper>
  );
};

const defaultLobby = {
  id: 'madden-ps5',
  consoleSetting: {
    id: 'ps5',
    displayName: 'PlayStation 5',
  },
  gameSeries: {
    id: 'madden',
    name: 'Madden',
  },
};

const GlobalBottomBar = ({
  lobbyBottomBar,
  shouldShowBottomBarNav = true,
}: GlobalBottomBarType) => {
  const { route } = useRouter();
  const { lobby = null } = lobbyBottomBar || {};
  const [lobbyGameSeriesId, setLobbyGameSeriesId] = useState(
    defaultLobby.gameSeries.id,
  );
  const [lobbyConsoleSettingId, setLobbyConsoleSettingId] = useState(
    defaultLobby.consoleSetting.id,
  );

  useEffect(() => {
    if (lobby) {
      const { gameSeries, consoleSetting } = lobby;

      setLobbyGameSeriesId(gameSeries?.id);
      setLobbyConsoleSettingId(consoleSetting?.id);
    }
  }, [lobby]);

  const { user } = useUser();

  if (!shouldShowBottomBarNav) {
    return null;
  }

  return (
    <BottomBar>
      {lobbyBottomBar && <LobbyBottomBar {...lobbyBottomBar} />}
      {shouldShowBottomBarNav && (
        <BottomBarWrapper>
          <GlobalNavElement
            icon="home"
            text="Lounge"
            href="/lounge"
            selected={route === '/lounge'}
          />
          {/* <BottomBarContentAdjust>
            <GlobalNavElement
              href={`/pickem`}
              selected={route === '/pickem'}
              text="Pick'Em"
              icon="pickEmIcon"
            />
          </BottomBarContentAdjust> */}
          <BottomBarContentAdjust>
            <GlobalNavElement
              href={`/twitch`}
              selected={route === '/twitch'}
              text="Livestreams"
              icon="allStreams"
            />
          </BottomBarContentAdjust>
          <BottomBarContentAdjust>
            <GlobalNavElement
              href={`/tournaments/${lobbyGameSeriesId}/${lobbyConsoleSettingId}`}
              selected={route.includes('/tournaments')}
              text="Tournaments"
              icon="trophy"
            />
          </BottomBarContentAdjust>
          <BottomBarNotificationsAdjust>
            {/* TODO: Move notifications to TS  */}
            {/* @ts-ignore */}
            <Notifications notificationCount={user?.notificationCount} />
          </BottomBarNotificationsAdjust>
          <UserBalance
            currency="$"
            value={`${user?.balance || ''}` || '0.00'}
            loungeCredits={user?.wallet?.loungeCredits}
          />
        </BottomBarWrapper>
      )}
    </BottomBar>
  );
};

export default GlobalBottomBar;
