import { Pill } from 'components/atoms';
import styled from 'styled-components';
import { MIN_TABLET } from 'components/styles';

export const PLPlusPill = styled(Pill)`
  background: linear-gradient(
    45deg,
    ${({ theme }) => theme.plPlus.gradientStart} 10%,
    ${({ theme }) => theme.plPlus.gradientMiddle},
    ${({ theme }) => theme.plPlus.gradientEnd},
    ${({ theme }) => theme.plPlus.gradientMiddle},
    ${({ theme }) => theme.plPlus.gradientStart} 90%
  );
  width: 100%;
  background-repeat: repeat-x;
  background-size: 200%;
  -webkit-animation: PlusGradient 7s linear infinite;
  -moz-animation: PlusGradient 7s linear infinite;
  animation: PlusGradient 7s linear infinite;

  @keyframes PlusGradient {
    0% {
      background-position: 200% 0%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 100%;
    }
  }
  @media (min-width: ${MIN_TABLET}) {
    width: ${({ theme }) => theme.spacing.customSpacing('237px')};
  }
  margin: ${({ theme }) => theme.spacing.HALF_BASE_SPACING} 0
    ${({ theme }) => theme.spacing.BASE_SPACING} 0;

  :hover {
    animation-play-state: paused;
  }
`;
