import React from 'react';
import styled from 'styled-components';
import { visuallyHidden } from 'components/styles';

const RadioContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  ${visuallyHidden}
`;

const Icon = styled.svg`
  fill: ${({ theme }) => theme.new.information.background};
  stroke: transparent;
  stroke-width: 0;
`;

const StyledRadio = styled.button<{ checked: boolean }>`
  box-sizing: border-box;
  display: flex;
  width: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  height: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.new.background};
  padding-bottom: 1px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, checked }) =>
    checked ? theme.new.information.background : theme.new.divisions};

  transition: all 150ms;
  ${HiddenRadio}:focus + & {
    box-shadow: ${({ theme }) =>
      `0 0 0 ${theme.spacing.customSpacing('1px')} ${
        theme.new.information.background
      }`};
  }
  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
  padding: 0px;
  outline: none;
`;

const CenterCircle = styled.div<{ checked: boolean }>`
  width: ${({ theme, checked }) =>
    checked ? theme.spacing.BASE_SPACING : '0rem'};
  height: ${({ theme, checked }) =>
    checked ? theme.spacing.BASE_SPACING : '0rem'};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.new.information.background};

  transition: width 150ms cubic-bezier(0.33, 0.23, 0.55, 1.76),
    height 150ms cubic-bezier(0.33, 0.23, 0.55, 1.76);
`;

type RadioProps = {
  group: string;
  id: string;
  selected: boolean;
  onChange: (id: string) => void;
  disabled?: boolean;
};

const Radio = ({
  group = 'defaultGroup',
  id = 'null',
  selected = false,
  onChange = () => {},
  disabled,
}: RadioProps) => {
  const handleClick = () => {
    onChange?.(id);
  };

  return (
    <RadioContainer>
      <HiddenRadio
        onChange={handleClick}
        onClick={handleClick}
        checked={selected}
        name={group}
        id={id}
        data-testid={`${group}-${id}-radio`}
        disabled={disabled}
      />
      <StyledRadio
        onChange={handleClick}
        onClick={handleClick}
        checked={selected}
        disabled={disabled}
      >
        <CenterCircle checked={selected} />
      </StyledRadio>
    </RadioContainer>
  );
};

export default Radio;
