import React, { PropsWithChildren } from 'react';
import styled, {
  css,
  ThemeProvider,
  useTheme,
  DefaultTheme,
  ThemeProps,
  FlattenInterpolation,
} from 'styled-components';
import { lightTheme, MIN_TABLET } from 'components/styles';

type SurfaceType = 'active' | 'default' | 'warning' | 'surface' | 'inverse';
type SurfaceSize = 'small' | 'medium' | 'large';
type SurfaceContext = 'default' | 'confirmationModal';

type SurfaceProps = {
  type?: SurfaceType;
  className?: string;
  border?: boolean;
  context?: SurfaceContext;
  size?: SurfaceSize;
  includePadding?: boolean;
};

const surfaceColor: Record<
  SurfaceType,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  active: css`
    background: ${({ theme }) => theme.new.information.background};
  `,
  warning: css`
    background: ${({ theme }) => theme.new.warning.background};
  `,
  default: css`
    background: ${({ theme }) => theme.new.surface};
  `,
  surface: css`
    background: ${({ theme }) => theme.new.background};
  `,
  inverse: css`
    background: ${({ theme }) => theme.new.content};
  `,
};

const contentColor: Record<
  SurfaceType,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  active: css`
    color: ${({ theme }) => theme.new.surface};
  `,
  warning: css`
    color: ${({ theme }) => theme.new.warning.text};
  `,
  default: css`
    color: ${({ theme }) => theme.new.content};
  `,
  surface: css`
    color: ${({ theme }) => theme.new.content};
  `,
  inverse: css`
    color: ${({ theme }) => theme.new.surface};
  `,
};

const surfaceSizeBorderRadiusMap: Record<SurfaceSize, string> = {
  small: '4px',
  medium: '8px',
  large: '16px',
};

const surfaceSizePaddingMap: Record<SurfaceSize, string> = {
  small: '8px',
  medium: '16px',
  large: '24px',
};

const StyledSurface = styled.div<SurfaceProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${({ size, includePadding }) => {
    if (!includePadding) return '0px';
    return size ? surfaceSizePaddingMap[size] : surfaceSizePaddingMap.medium;
  }};

  width: 100%;

  border-radius: ${({ size }) =>
    size
      ? surfaceSizeBorderRadiusMap[size]
      : surfaceSizeBorderRadiusMap.medium};
  border: 1px solid ${({ theme }) => theme.new.divisions};

  ${({ type }) => (type ? surfaceColor[type] : surfaceColor.default)}
  ${({ type }) => (type ? contentColor[type] : contentColor.default)}
  ${({ border, theme }) =>
    border &&
    `
    border-width: 1px;
    border-color: ${theme.new.divisions};
  `}

  ${({ context, size, theme }) =>
    context === 'confirmationModal' &&
    `
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border: 0px solid transparent;

      @media (min-width: ${MIN_TABLET}) {
        border: 1px solid ${theme.new.divisions};
        border-radius: ${
          size
            ? surfaceSizeBorderRadiusMap[size]
            : surfaceSizeBorderRadiusMap.medium
        };
      }
    `}
`;

const Surface = ({
  type,
  className,
  border,
  context = 'default',
  children,
  includePadding,
  size,
}: PropsWithChildren<SurfaceProps>) => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={type === 'warning' ? lightTheme : theme}>
      <StyledSurface
        size={size}
        type={type}
        className={className}
        border={border}
        includePadding={includePadding}
        context={context}
      >
        {children}
      </StyledSurface>
    </ThemeProvider>
  );
};

export default Surface;
