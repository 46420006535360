import { MIN_DESKTOP, fontL } from 'components/styles';
import styled from 'styled-components';

export const Logo = styled.div`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacing.customSpacing('60px')};
  align-items: center;
  justify-content: center;
`;

export const MatchScreenWrapper = styled.div<{ userIsLoggedIn: boolean }>`
  box-sizing: border-box;
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING};
  padding-top: ${({ theme }) => theme.spacing.customSpacing('84px')};
  width: 100%;
  max-width: ${({ theme, userIsLoggedIn }) =>
    userIsLoggedIn ? MIN_DESKTOP : theme.spacing.customSpacing('380px')};

  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${MIN_DESKTOP}) {
    gap: ${({ theme }) => theme.spacing.BASE_SPACING};
    grid-template-columns: repeat(
      ${({ userIsLoggedIn }) => (userIsLoggedIn ? 2 : 1)},
      1fr
    );
  }
`;

export const MatchDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomSectionWrapper = styled(MatchDetailsColumn)`
  box-sizing: border-box;
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
  width: 100%;
  max-width: ${MIN_DESKTOP};
`;

export const SectionHeading = styled.h2`
  ${fontL}
  color: ${({ theme }) => theme.new.content};
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;
