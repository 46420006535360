import { ApolloError } from '@apollo/client';

/**
 * This function is intended to be a helper to abstract
 * unique error codes from BE responses where we can action
 * them more appropriately.
 *
 * @param {ApolloError} error
 * @returns string | null
 */
const getErrorCode = (error?: ApolloError): string | null => {
  if (!error?.graphQLErrors || error?.graphQLErrors.length < 1) return null;

  const errorCode =
    (error?.graphQLErrors.find((gqlError) => {
      return Boolean(gqlError.extensions) && Boolean(gqlError.extensions?.code);
    })?.extensions?.code as string | undefined) || null;

  return errorCode;
};

export default getErrorCode;
