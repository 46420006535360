import React from 'react';
import { MatchContestFragment } from 'graphpl/core';
import Surface from 'components/atoms/surface';
import { useModal } from 'components/util/modal-context';
import { AvailableIcons, Iconography } from 'components/styles';
import {
  ContentWrapper,
  HeaderIconWrapper,
  HeadingText,
  HeadingWrapper,
  SurfaceWrapper,
} from './players-joined.styles';
import { JoinedUserRow, PendingUserRow } from '../joined-user-row';

type PlayersJoinedProps = {
  matchData: MatchContestFragment;
};

export const PlayersJoined = ({ matchData }: PlayersJoinedProps) => {
  const { dismissModal } = useModal();

  return (
    <Surface size="large" context="confirmationModal">
      <SurfaceWrapper>
        <HeadingWrapper>
          <HeadingText>Potential Players</HeadingText>
          <HeaderIconWrapper onClick={dismissModal}>
            <Iconography name={AvailableIcons.CROSS} />
          </HeaderIconWrapper>
        </HeadingWrapper>

        <ContentWrapper>
          {matchData?.playerQueue?.length > 0 &&
            matchData.playerQueue.map((opponent) => (
              <JoinedUserRow
                matchData={matchData}
                opponent={opponent}
                key={`${opponent?.user?.id} - ${opponent?.joiningFromConsole} - ${opponent?.status} - ${opponent?.proposedAmount} - ${opponent?.proposedFormat}`}
              />
            ))}

          {matchData.invites &&
            matchData.invites.length > 0 &&
            matchData.invites.map((opponent) => {
              if (!opponent?.user) return null;
              return (
                <PendingUserRow
                  matchData={matchData}
                  opponent={opponent.user}
                  key={`${opponent?.user?.id} - ${opponent?.status}`}
                />
              );
            })}
        </ContentWrapper>
      </SurfaceWrapper>
    </Surface>
  );
};
