import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const BackgroundHeaderWrapper = styled.div`
  position: relative;
  min-height: ${({ theme }) => theme.spacing.customSpacing('250px')};
  background-color: ${({ theme }) => theme.new.background};
`;

const ContentWrapper = styled.div`
  position: relative;
`;

type BackgroundHeaderProps = PropsWithChildren;

const BackgroundHeader = ({ children }: BackgroundHeaderProps) => {
  return (
    <BackgroundHeaderWrapper data-testid="background-header">
      <ContentWrapper>{children}</ContentWrapper>
    </BackgroundHeaderWrapper>
  );
};

export default BackgroundHeader;
