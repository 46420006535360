import styled from 'styled-components';
import {
  MIN_TABLET,
  QUARTER_BASE_SPACING,
  HALF_BASE_SPACING,
  BASE_SPACING,
  customSpacing,
} from 'components/styles';

export const BottomBar = styled.div`
  padding-top: ${BASE_SPACING};
  padding-bottom: ${BASE_SPACING};
  background-color: ${({ theme }) => theme.palette.background.alternative};
  border-top-left-radius: ${HALF_BASE_SPACING};
  border-top-right-radius: ${HALF_BASE_SPACING};

  @media (min-width: ${MIN_TABLET}) {
    display: none;
  }
`;

export const LobbyBottomBarWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;

  fill: ${({ theme }) => theme.palette.text.alternative};
  padding-left: ${QUARTER_BASE_SPACING};
  padding-right: ${QUARTER_BASE_SPACING};

  @media (min-width: ${customSpacing('350px')}) {
    padding-left: ${HALF_BASE_SPACING};
    padding-right: ${HALF_BASE_SPACING};
  }

  > button {
    margin-left: ${QUARTER_BASE_SPACING};
    margin-right: ${QUARTER_BASE_SPACING};
    @media (min-width: ${customSpacing('350px')}) {
      margin-left: ${HALF_BASE_SPACING};
      margin-right: ${HALF_BASE_SPACING};
    }
  }
`;

export const BottomBarNotificationsAdjust = styled.div`
  margin-top: -${customSpacing('2px')};
  margin-right: -${HALF_BASE_SPACING};
  display: flex;
  justify-content: center;
`;

export const BottomBarContentAdjust = styled.div`
  margin-right: ${HALF_BASE_SPACING};
  margin-left: ${HALF_BASE_SPACING};
  display: flex;
  justify-content: center;
`;

const dmEnabled = process.env.DIRECT_MESSAGES === 'true';

export const BottomBarWrapper = styled.div`
  padding-left: ${BASE_SPACING};
  padding-right: ${BASE_SPACING};
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: repeat(${dmEnabled ? '4' : '3'}, 1fr);
  grid-column-gap: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
`;
