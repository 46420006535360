import React from 'react';
import styled from 'styled-components';
import { TOUCHABLE_STYLE, visuallyHidden } from 'components/styles';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${visuallyHidden}
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: ${({ theme }) => theme.spacing.customSpacing('3px')};
`;

const StyledCheckbox = styled.button<{ checked?: boolean }>`
  display: inline-block;
  width: ${({ theme }) => theme.spacing.customSpacing('22px')};
  height: ${({ theme }) => theme.spacing.customSpacing('22px')};
  background: ${({ checked, theme }) =>
    checked ? theme.checkbox.selected : theme.checkbox.default};
  border-radius: ${({ theme }) => theme.spacing.customSpacing('2px')};
  border-width: ${({ theme }) => theme.spacing.customSpacing('2px')};
  border-style: solid;
  border-color: ${({ theme }) => theme.checkbox.default};
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: ${({ theme }) =>
      `0 0 0 ${theme.spacing.customSpacing('1px')} ${theme.checkbox.active}`};
  }
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
  padding: 1px;
  outline: none;
`;

export const SwitchBorder = styled.div`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.new.divisions};
  border-radius: 50px;
  height: 24px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${TOUCHABLE_STYLE}
`;

type LocalCheckboxProps = {
  handleInputChange: () => void;
  id: string;
  checked?: boolean;
  disabled?: boolean;
};

/**
 * NOTE that this checkbox component is just a wrapper to style the
 * `<input type="checkbox" />` HTML tag. Its callbacks and props
 * are driven by the parent component.
 */
const Checkbox = ({
  handleInputChange = () => {},
  id,
  checked,
  disabled,
}: LocalCheckboxProps) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox
        onChange={handleInputChange}
        checked={checked}
        id={id}
        data-testid={`${id}-checkbox`}
        disabled={disabled}
      />
      <StyledCheckbox
        checked={checked}
        onClick={handleInputChange}
        disabled={disabled}
        data-testid={`${id}-checkbox-button`}
      >
        <Icon viewBox="0 0 22 22">
          <polyline points="20 4 9 18 2 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;
