import { fontL, MIN_TABLET, TOUCHABLE_STYLE } from 'components/styles';
import styled from 'styled-components';

export const SurfaceWrapper = styled.div`
  max-height: 400px;
  height: 400px;
  overflow-y: scroll;

  @media (min-width: ${MIN_TABLET}) {
    max-height: calc(90vh - 2px);
    height: auto;
  }
`;

export const InputWrapper = styled.div<{ hasResults: boolean }>`
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderIconWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
  cursor: pointer;
  ${TOUCHABLE_STYLE}
`;

export const HeadingTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
`;

export const HeadingText = styled.h2`
  ${fontL}
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
  color: ${({ theme }) => theme.new.content};
`;

export const LoadingWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  height: 35px;
`;

export const ResultWrapper = styled.div<{ resultsShown: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-top: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
  padding-bottom: ${({ theme, resultsShown }) =>
    resultsShown
      ? theme.spacing.BASE_SPACING_AND_HALF
      : theme.spacing.HALF_BASE_SPACING};
  overflow: hidden;
`;
