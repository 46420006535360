import { StreamChat } from 'stream-chat';

let cachedStreamChat: StreamChat | undefined;
let userIsSetUp = false;

const getClient = () => {
  if (!cachedStreamChat) {
    cachedStreamChat = new StreamChat(process.env.STREAM_CHAT_API_KEY || '');
  }
  return cachedStreamChat;
};

type SetUserProps = {
  userToken: string;
  client?: StreamChat;
  id: string;
  username: string;
  picture?: string | null;
};

const setUser = async ({
  userToken,
  client,
  id,
  username,
  picture,
}: SetUserProps) => {
  if (userIsSetUp) {
    return;
  }
  userIsSetUp = true;

  if (!client) return;

  await client.connectUser(
    {
      id: String(id),
      name: username,
      image:
        picture === 'https://images.playerslounge.co/img/outline.png'
          ? null
          : picture,
    },
    userToken,
  );
};

type InitStreamProps = {
  userToken: string;
  id: string;
  username: string;
  picture?: string | null;
};

/**
 * Call this function to initialize the Stream chat. Make sure a valid
 * user token and the user's details are collected before.
 *
 * userToken, id, username and picture are all the user's props.
 */
const initStream = async ({
  userToken,
  id,
  username,
  picture,
}: InitStreamProps) => {
  const client = getClient();
  if (!client) {
    return null;
  }
  await setUser({ userToken, client, id, username, picture });
  return client;
};

export default initStream;
