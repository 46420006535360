import React, { useState } from 'react';
import { Divider } from 'components/atoms';
import { AvailableIcons } from 'components/styles';
import {
  StyledSurface,
  HeaderWrap,
  SectionHeading,
  FullRules,
  ContentWrapper,
  IconWrapper,
} from './match-rules-section.styles';

export const MatchRulesSection = ({
  fullRules,
  storybookIsOpen,
}: {
  fullRules: string;
  storybookIsOpen?: boolean;
}) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <StyledSurface size="large">
      <HeaderWrap
        role="button"
        data-testid="match-rules-section"
        tabIndex={0}
        onClick={() => setIsOpened((prev) => !prev)}
        onKeyPress={() => setIsOpened((prev) => !prev)}
      >
        <SectionHeading>Match Rules</SectionHeading>
        <IconWrapper name={AvailableIcons.EXPAND} isOpened={isOpened} />
      </HeaderWrap>
      <ContentWrapper
        isOpened={storybookIsOpen || isOpened}
        data-testid="match-rules-content-wrapper"
      >
        <Divider />
        <FullRules dangerouslySetInnerHTML={{ __html: fullRules || '' }} />
      </ContentWrapper>
    </StyledSurface>
  );
};

export const PLRulesSection = ({
  plRules,
  storybookIsOpen,
}: {
  plRules: string;
  storybookIsOpen?: boolean;
}) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <StyledSurface size="large">
      <HeaderWrap
        role="button"
        data-testid="match-rules-section"
        tabIndex={0}
        onClick={() => setIsOpened((prev) => !prev)}
        onKeyUp={() => setIsOpened((prev) => !prev)}
      >
        <SectionHeading>Players&apos; Lounge Rules</SectionHeading>
        <IconWrapper name={AvailableIcons.EXPAND} isOpened={isOpened} />
      </HeaderWrap>
      <ContentWrapper
        isOpened={storybookIsOpen || isOpened}
        data-testid="match-rules-content-wrapper"
      >
        <Divider />
        <FullRules dangerouslySetInnerHTML={{ __html: plRules || '' }} />
      </ContentWrapper>
    </StyledSurface>
  );
};
