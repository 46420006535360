import styled, { css } from 'styled-components';
import { MIN_TABLET, largeShadow, visuallyHidden } from 'components/styles';

export const SidebarWrapper = styled.aside<{ isOpen: boolean }>`
  display: block;
  position: fixed;
  top: ${({ isOpen }) => (isOpen ? 0 : `100vh`)};
  bottom: ${({ isOpen }) => (isOpen ? 0 : `-100vh`)};
  width: calc(100vw - 0px);
  right: 0;

  z-index: ${({ theme }) => theme.depth.SIDEBAR};

  transition-property: right, top, bottom, box-shadow;
  transition-duration: 0.25s;
  transition-timing-function: ease-in;

  @media (min-width: ${MIN_TABLET}) {
    border-radius: 40px 0 0 40px;
    ${({ isOpen }) => (isOpen ? largeShadow : '')};
    width: ${({ theme }) => theme.spacing.customSpacing('364px')};
    bottom: 0;
    top: ${({ theme }) => theme.spacing.customSpacing('118px')};
    right: ${({ theme, isOpen }) =>
      isOpen ? 0 : `-${theme.spacing.customSpacing('364px')}`};
    margin: ${({ theme }) => theme.spacing.BASE_SPACING} 0;
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;

export const SidebarOuter = styled.div`
  height: calc(100% - 0px);
  display: grid;
  grid-template-rows: min-content auto min-content;
  overflow: auto;
  overflow-x: hidden;
`;

export const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.new.surface};
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};

  @media (min-width: ${MIN_TABLET}) {
    border-radius: 40px 0 0 0;
  }
`;

const cruvedBorder = css`
  @media (min-width: ${MIN_TABLET}) {
    border-bottom-left-radius: 40px;
  }
`;

export const SidebarInner = styled.div<{ footer: boolean }>`
  overflow: hidden;
  background-color: ${({ theme }) => theme.new.background};

  ${({ footer }) => !footer && cruvedBorder}
`;

export const FooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.new.surface};
  width: 100%;
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};

  @media (min-width: ${MIN_TABLET}) {
    border-radius: 0 0 0 40px;
  }
`;

export const OverflowContainer = styled.div`
  overflow-y: hidden;
  height: calc(100% - 0px);
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SidebarCloseButton = styled.button`
  width: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  height: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding: 0;
  background-color: ${({ theme }) => theme.new.surface};
  position: absolute;
  top: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};

  :focus,
  :hover {
    background-color: ${({ theme }) => theme.new.background};
    outline: none;
  }

  :active {
    background-color: ${({ theme }) => theme.new.divisions};
    outline: none;
  }

  @media (min-width: ${MIN_TABLET}) {
    ${visuallyHidden};
  }
`;
