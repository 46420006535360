import { SelectInputOptions } from 'components/atoms/select-input/select-input';
import { ConsolesFragment, GamesFragment, UserFragment } from 'graphpl/core';

type GetConsolesProps = {
  selectedGame: GamesFragment;
  consoles: ConsolesFragment[];
  user: UserFragment | null;
};

const DEFAULT_CONSOLE: ConsolesFragment = {
  __typename: 'ConsoleSetting',
  id: 'ps5',
  displayName: 'PlayStation 5',
};

const sortConsoles = (
  consoles: ConsolesFragment[],
  lobbyConsole?: ConsolesFragment,
): ConsolesFragment[] => {
  const sortedConsoles = consoles.reduce<ConsolesFragment[]>((acc, val) => {
    if (lobbyConsole && lobbyConsole.id === val.id) return acc;
    if (val.id === 'ps4') return [...acc, val];
    if (val.id === 'ps5') return [val, ...acc];
    return [...acc, val];
  }, []);

  if (!lobbyConsole) return sortedConsoles;

  if (!consoles.find((console) => console.id === lobbyConsole.id))
    return sortedConsoles;

  return [lobbyConsole, ...sortedConsoles];
};

const getLobbyConsole = ({
  user,
}: {
  user: UserFragment | null;
}): ConsolesFragment | undefined => {
  const UserLobby = user?.selectedLobby;

  if (!UserLobby) return;

  const selectedLobby = UserLobby;

  return selectedLobby.consoleSetting || undefined;
};

export const getConsoles = ({
  selectedGame,
  consoles,
  user,
}: GetConsolesProps): ConsolesFragment[] =>
  sortConsoles(
    (
      selectedGame?.consoles?.map((consoleId) =>
        consoles.find((console) => console.id === consoleId),
      ) || [DEFAULT_CONSOLE]
    ).filter((console) => Boolean(console?.id)) as ConsolesFragment[],
    getLobbyConsole({ user }),
  );

export const getConsolesForSelectInput = ({
  consoles,
}: {
  consoles: ConsolesFragment[];
}): SelectInputOptions =>
  consoles.map((console) => ({
    name: console.displayName || 'PlayStation 5',
    value: console.id || 'ps5',
  }));

export const getConsole = (
  consoleString: string,
  consoles: ConsolesFragment[],
): ConsolesFragment => {
  return consoles.find(({ id }) => id === consoleString) || DEFAULT_CONSOLE;
};
