import React, { useState, useEffect } from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import Palette from 'components/styles/colors';
import { AvailableIcons, fontXS } from 'components/styles';
import { useT } from 'react-polyglot-hooks';
import {
  DefaultSelect,
  DefaultHelperMessage,
  InputBox,
  CaretButton,
  InputComponentWrapper,
  SelectedValue,
  DownArrow,
  InputWrap,
  DefaultLabel,
} from './select-input.styles';

const displayStrategies = (fallback: Palette) => ({
  active,
  success,
  error,
  theme,
}: {
  active: boolean;
  success: boolean;
  error: boolean;
  theme: DefaultTheme;
}) => {
  if (error) return theme.new.error.background;
  if (active) return theme.new.information.background;
  if (success) return theme.new.success.background;
  return fallback;
};

const labelActive = (theme: DefaultTheme) => css`
  ${fontXS}
  color: ${theme.new.information.background};
`;

const labelNotActiveWithValue = (theme: DefaultTheme) => css`
  ${fontXS}
  color: ${theme.new.content};
`;

const labelState = ({
  active,
  value,
  theme,
}: {
  active: boolean;
  value?: string;
  theme: DefaultTheme;
}) => {
  if (!active && value) return labelNotActiveWithValue(theme);
  if (active) return labelActive(theme);
  return '';
};

const Label = styled(DefaultLabel)`
  ${labelState}
  white-space: nowrap;
`;

const HelperMessage = styled(DefaultHelperMessage)`
  color: ${({ theme, ...rest }) =>
    displayStrategies(theme.new.content)({ theme, ...rest })};
`;

export type SelectInputOptions =
  | Array<{ name: string; value: number | string }>
  | Array<string>;

export type SelectInputProps = {
  id: string;
  name: string;
  label: string;
  options: SelectInputOptions;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  active?: boolean;
  success?: boolean;
  error?: boolean;
  message?: string;
  testId?: string;
  disabled?: boolean;
  isTransparent?: boolean;
  highContrast?: boolean;
  setValueMaxWidth?: string;
  defaultValue?: string;
  hideCaret?: boolean;
};

const SelectInput = ({
  id,
  name,
  label,
  options,
  onChange = () => {},
  value,
  active = false,
  success = false,
  error = false,
  message = '',
  testId,
  disabled = false,
  isTransparent = false,
  highContrast = false,
  hideCaret = false,
  setValueMaxWidth,
  defaultValue,
}: SelectInputProps) => {
  const [isActive, setActive] = useState(active);
  const [itemChosen, setItemChosen] = useState(value);

  useEffect(() => {
    setItemChosen(value);
  }, [value]);

  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const result = options.filter((option) => {
      return (
        // @ts-ignore
        option?.value?.toString() === event.target.value ||
        option.toString() === event.target.value
      );
    });
    onChange?.(event);
    // @ts-ignore
    setItemChosen(result[0]?.name || result[0]);

    setActive(false);
  };

  const translation = useT();

  return (
    <InputComponentWrapper disabled={disabled}>
      <InputWrap
        isTransparent={isTransparent}
        highContrast={highContrast}
        active={isActive}
        success={success}
        error={error}
        data-testid={`${id}-select-input`}
      >
        <InputBox setValueMaxWidth={setValueMaxWidth}>
          <Label
            htmlFor={id}
            active={isActive}
            value={itemChosen}
            data-testid={`${id}-select-input-label`}
          >
            {label}
          </Label>
          <SelectedValue active={isActive} value={itemChosen}>
            {/* @ts-ignore */}
            {options[(itemChosen || 1) - 1]?.name || itemChosen}
          </SelectedValue>
        </InputBox>
        {!hideCaret && (
          <CaretButton tab-index="-1" type="button" disabled={disabled}>
            <DownArrow
              name={AvailableIcons.TRIANGLE_DOWN}
              isOpened={isActive}
            />
          </CaretButton>
        )}
      </InputWrap>
      <HelperMessage
        success={Boolean(success)}
        active={Boolean(isActive)}
        error={Boolean(error)}
        data-testid={`${id}-helper-message`}
      >
        {message}
      </HelperMessage>
      <DefaultSelect
        id={id}
        disabled={disabled}
        data-testid={testId || `${id}-select-input-box`}
        name={name}
        active={isActive}
        onChange={handleChange}
        onFocus={() => {
          setActive(true);
        }}
        onBlur={() => {
          setActive(false);
        }}
        value={itemChosen}
        selected={Boolean(itemChosen)}
      >
        <option value="">
          {defaultValue ? translation(defaultValue) : ''}
        </option>
        {options.map((option) => {
          if (typeof option === 'string') {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            );
          }

          return (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          );
        })}
      </DefaultSelect>
    </InputComponentWrapper>
  );
};

export default SelectInput;
