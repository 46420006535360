import { FlattenSimpleInterpolation } from 'styled-components';
import { lineQuiteAnimation, lineStrongAnimation } from './animations';

export type ButtonAnimation = 'strong' | 'quite';

const availableAnimations = {
  strong: lineStrongAnimation,
  quite: lineQuiteAnimation,
};

export const getButtonAnimation = (
  animation?: ButtonAnimation,
): FlattenSimpleInterpolation | null => {
  if (!animation) return null;
  if (!availableAnimations[animation]) return null;
  return availableAnimations[animation];
};
