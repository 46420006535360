import { transparentize } from 'polished';
import { DefaultTheme } from 'styled-components';
import { pixelToRem } from './utils';
import Palette from './colors';

const shadowBlur = {
  smaller: pixelToRem(8),
  small: pixelToRem(16),
  large: pixelToRem(40),
};

export const customBoxShadow = ({
  color,
  transparency,
  blurRadius,
}: {
  color: Palette;
  transparency: number;
  blurRadius: string;
}) => {
  return `
    box-shadow: 0 0 ${blurRadius} 0 ${transparentize(transparency, color)};
  `;
};

const BOX_SHADOW_TRANSPARENCY = 0.5;

const smallerShadow = ({ theme }: { theme: DefaultTheme }) =>
  customBoxShadow({
    color: theme.palette.background.alternative,
    transparency: BOX_SHADOW_TRANSPARENCY,
    blurRadius: shadowBlur.smaller,
  });

const smallShadow = ({ theme }: { theme: DefaultTheme }) =>
  customBoxShadow({
    color: theme.palette.background.alternative,
    transparency: BOX_SHADOW_TRANSPARENCY,
    blurRadius: shadowBlur.small,
  });

const largeShadow = ({ theme }: { theme: DefaultTheme }) =>
  customBoxShadow({
    color: theme.palette.background.alternative,
    transparency: BOX_SHADOW_TRANSPARENCY,
    blurRadius: shadowBlur.large,
  });

export { smallShadow, largeShadow, smallerShadow };
