import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetMatchesDocument,
  GetMatchesQuery,
  GetMatchesQueryVariables,
  InstantMatchFragment,
  UserFragment,
} from 'graphpl/core';

type MatchesPropsReturn = {
  instantMatches: (InstantMatchFragment | null)[];
};

export const getMatchesProps = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  selectedLobby: UserFragment['selectedLobby'],
): Promise<MatchesPropsReturn> => {
  if (
    !selectedLobby ||
    !selectedLobby?.consoleSetting?.id ||
    !selectedLobby?.gameSeries?.id
  ) {
    return {
      instantMatches: [],
    };
  }

  try {
    const { data, errors } = await apolloClient.query<
      GetMatchesQuery,
      GetMatchesQueryVariables
    >({
      query: GetMatchesDocument,
      fetchPolicy: 'network-only',
      errorPolicy: 'all', // This prevents the error being thrown and instead returns the errors array.
      variables: {
        lookup: {
          consoleSettingId: selectedLobby?.consoleSetting?.id,
          gameSeriesId: selectedLobby?.gameSeries?.id,
        },
      },
    });

    if (errors) {
      // eslint-disable-next-line no-console
      console.error(errors);
    }

    return {
      instantMatches: data?.lobby?.instantMatches || [],
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return {
      instantMatches: [],
    };
  }
};
