import React from 'react';
import styled from 'styled-components';
import SVG from 'svgs';
import { Link } from 'components/atoms';

import { MAX_PHONE } from 'components/styles';

const SocialSVG = styled(SVG)`
  fill: ${({ theme }) => theme.palette.text.header};
`;

// eslint-disable-next-line no-shadow
enum SocialMedias {
  'Facebook' = 'Facebook',
  'Twitter' = 'Twitter',
  'Instagram' = 'Instagram',
  'Twitch' = 'Twitch',
  'AppStore' = 'AppStore',
}

export const SocialBarBox = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-column: span 3, auto;
  margin-right: 0;
`;

const SocialLinks = styled(Link)`
  padding: ${({ theme }) => theme.spacing.BASE_SPACING} 0;
  margin-left: ${({ theme }) => theme.spacing.BASE_SPACING};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.link.hover};
  }
`;

const AppStoreSocialLink = styled(SocialLinks)`
  @media (max-width: ${MAX_PHONE}) {
    display: none;
  }
`;

type Profiles = Record<keyof typeof SocialMedias, string>;

const mapSocials = (profiles: Profiles) =>
  Object.keys(profiles).map((value) => {
    switch (value) {
      case 'Facebook':
        return (
          <SocialLinks
            key={value}
            href={`https://www.facebook.com/${profiles[value]}`}
            openInNewTab
          >
            <SocialSVG icon="facebook" />
          </SocialLinks>
        );
      case 'Twitter':
        return (
          <SocialLinks
            key={value}
            href={`https://www.twitter.com/${profiles[value]}`}
            openInNewTab
          >
            <SocialSVG icon="twitter" />
          </SocialLinks>
        );
      case 'Instagram':
        return (
          <SocialLinks
            key={value}
            href={`https://www.instagram.com/${profiles[value]}`}
            openInNewTab
          >
            <SocialSVG icon="instagram" />
          </SocialLinks>
        );
      case 'Twitch':
        return (
          <SocialLinks
            key={value}
            href={`https://www.twitch.com/${profiles[value]}`}
            openInNewTab
          >
            <SocialSVG icon="twitch" />
          </SocialLinks>
        );
      case 'AppStore':
        return (
          <AppStoreSocialLink
            key={value}
            href={`https://apps.apple.com/${profiles[value]}`}
            openInNewTab
          >
            <SocialSVG icon="appStore" />
          </AppStoreSocialLink>
        );

      default:
        return null;
    }
  });

export const SocialBar = ({ profiles }: { profiles: Profiles }) => (
  <SocialBarBox>{mapSocials(profiles)}</SocialBarBox>
);
