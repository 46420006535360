import React, { useCallback, useState } from 'react';
import {
  MatchCompetitorFragment,
  MatchContestFragment,
  useSearchUsersLazyQuery,
} from 'graphpl/core';
import { debounce } from 'helpers';
import Surface from 'components/atoms/surface';
import TextInput from 'components/atoms/text-input';
import { useModal } from 'components/util/modal-context';
import { AvailableIcons, Iconography } from 'components/styles';
import { LoadingSpinner } from 'components/atoms';
import { useTheme } from 'styled-components';
import {
  InputWrapper,
  HeaderIconWrapper,
  HeadingText,
  HeadingWrapper,
  ResultWrapper,
  SurfaceWrapper,
  HeadingTextWrapper,
  LoadingWrapper,
} from './send-challenge.styles';
import { InviteUserRow } from '../invite-user-row';

type ConfirmEntryProps = {
  matchData: MatchContestFragment;
  storybookLoading?: boolean;
  storybookDisabled?: boolean;
};

export const SendChallenge = ({
  matchData,
  storybookLoading,
  storybookDisabled,
}: ConfirmEntryProps) => {
  const theme = useTheme();
  const { dismissModal } = useModal();

  const [searchResults, setSearchResults] = useState<
    (MatchCompetitorFragment | null)[]
  >([]);

  const [searchValue, setSearchValue] = useState<string>('');

  const [
    retrieveUsers,
    { loading: usersLoading, error: usersError },
  ] = useSearchUsersLazyQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    onCompleted: (data) => {
      if (data?.users?.nodes) {
        setSearchResults(data.users.nodes);
      }
    },
  });

  const searchInputDisabled = storybookLoading || storybookDisabled;

  const fetchUsers = useCallback(
    debounce<[string], void>((inputValue) => {
      if (inputValue?.length >= 3) {
        retrieveUsers({
          variables: {
            filters: {
              username: inputValue,
            },
          },
        });
      }
    }, 500),
    [],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    fetchUsers(e.target.value.toLocaleLowerCase());
    setSearchValue(e.target.value);
  };
  return (
    <Surface size="large" context="confirmationModal">
      <SurfaceWrapper>
        <HeadingWrapper>
          <HeadingTextWrapper>
            <HeadingText>Invite players</HeadingText>
            {usersLoading && (
              <LoadingWrapper>
                <LoadingSpinner
                  color={theme.new.theme === 'light' ? 'blue' : 'white'}
                />
              </LoadingWrapper>
            )}
          </HeadingTextWrapper>
          <HeaderIconWrapper onClick={dismissModal}>
            <Iconography name={AvailableIcons.CROSS} />
          </HeaderIconWrapper>
        </HeadingWrapper>

        <InputWrapper hasResults={false}>
          <TextInput
            id="entry-input"
            label="Search"
            name="search"
            onChange={handleSearchChange}
            disabled={searchInputDisabled}
            value={searchValue}
            error={Boolean(usersError)}
            message={usersError?.message || ''}
          />
        </InputWrapper>
        <ResultWrapper resultsShown={searchResults.length !== 0}>
          {searchResults.map((user) =>
            user ? (
              <InviteUserRow
                key={user.id}
                matchData={matchData}
                opponent={user}
              />
            ) : null,
          )}
        </ResultWrapper>
      </SurfaceWrapper>
    </Surface>
  );
};
