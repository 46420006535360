import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetOpponentDocument,
  GetOpponentQuery,
  GetOpponentQueryVariables,
  MatchCompetitorFragment,
} from 'graphpl/core';

type GetOpponentProps = {
  id?: string;
  username?: string;
  apolloClient: ApolloClient<NormalizedCacheObject>;
};

type GetOpponentPropsReturn = {
  opponent: MatchCompetitorFragment | null;
  opponentId: string | null;
};

export const getOpponentProps = async ({
  id,
  username,
  apolloClient,
}: GetOpponentProps): Promise<GetOpponentPropsReturn> => {
  if (!id && !username) return { opponent: null, opponentId: null };
  if (id) {
    const { data } = await apolloClient.query<
      GetOpponentQuery,
      GetOpponentQueryVariables
    >({
      query: GetOpponentDocument,
      variables: { id },
    });

    return { opponent: data?.user || null, opponentId: id };
  }
  if (username) {
    const { data } = await apolloClient.query<
      GetOpponentQuery,
      GetOpponentQueryVariables
    >({
      query: GetOpponentDocument,
      variables: { username },
    });

    return { opponent: data?.user || null, opponentId: data?.user?.id || null };
  }
  return { opponent: null, opponentId: null };
};
