const convertStringToNumber = (val: string) => parseInt(val, 10);
const normaliseToNumber = (value: string | number) =>
  typeof value === 'string' ? convertStringToNumber(value) : value;

const pixelToRem = (sizeInPixels: string | number) => {
  const size = normaliseToNumber(sizeInPixels);

  return `${size / 16}rem`;
};

export default pixelToRem;
