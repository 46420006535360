import styled from 'styled-components';
import { getTagSize, TagSize } from './tag-sizes';
import { getTagVariant, TagVariant } from './tag-variants';

export type TagProps = {
  tag?: TagVariant;
  size?: TagSize;
  customTextColor?: string;
  tagColor?: string;
};

const Tag = styled.div<TagProps>`
  border-radius: 8px;
  display: inline;
  white-space: nowrap;
  ${({ size = 'small' }) => getTagSize(size)};
  ${({ tag = 'light', customTextColor, tagColor }) =>
    getTagVariant(tag, customTextColor, tagColor)};
`;

export default Tag;
