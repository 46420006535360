import React, { ChangeEventHandler, useState } from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import { T } from 'react-polyglot-hooks';
import { AvailableIcons, Iconography, fontXS } from 'components/styles';
import Palette from 'components/styles/colors';
import SVG, { SVGImages } from 'svgs';
import {
  DefaultWrap,
  Input,
  DefaultLabel,
  DefaultHelperMessage,
  InputBox,
  StyledSVG,
  PasswordButton,
  InputComponentWrapper,
  TextInputBackground,
  InputWrapper,
} from './text-input.styles';

const displayStrategiesWrap = ({
  active,
  success,
  error,
  theme,
}: {
  active: boolean;
  success: boolean;
  error: boolean;
  theme: DefaultTheme;
}) => {
  if (active) return theme.new.information.background;
  if (error) return theme.new.error.background;
  if (success) return theme.new.success.background;
  return theme.new.divisions;
};

const InputWrap = styled(DefaultWrap)`
  border-color: ${displayStrategiesWrap};
  border-radius: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
`;

const labelActive = (theme: DefaultTheme) => css`
  ${fontXS}
  margin-top: 10px;
  color: ${theme.palette.info.background};
`;

const labelNotActiveWithValue = (theme: DefaultTheme) => css`
  ${fontXS}
  margin-top: 10px;
  color: ${theme.palette.input.textLabel};
`;

const labelState = ({
  active,
  value,
  theme,
}: {
  active: boolean;
  value: boolean;
  theme: DefaultTheme;
}) => {
  if (!active && value) return labelNotActiveWithValue(theme);
  if (active) return labelActive(theme);
  return 'max-width: 100px;';
};

const Label = styled.div`
  ${DefaultLabel}
  ${labelState}
`;

const SVGRightWrap = styled.div`
  margin-right: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

const displayStrategiesHelper = ({
  active,
  success,
  error,
  theme,
}: {
  active: boolean;
  success: boolean;
  error: boolean;
  theme: DefaultTheme;
}) => {
  if (active) return theme.new.information.background;
  if (error) return theme.new.error.background;
  if (success) return theme.new.success.background;
  return theme.new.content;
};

const HelperMessage = styled(DefaultHelperMessage)`
  color: ${displayStrategiesHelper};
`;

const passwordTypes = ['password', 'current-password', 'new-password'];

export type TextInputProps = {
  id: string;
  label: string | React.ReactNode;
  name: string;
  type?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  value?: string;
  icon?: SVGImages;
  iconPosition?: 'left' | 'right';
  active?: boolean;
  autoComplete?: string;
  success?: boolean;
  error?: boolean;
  message?: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  inputmode?:
    | 'text'
    | 'search'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined;
  pattern?: string;
  avatar?: React.ReactNode;
  background?: TextInputBackground;
  prefix?: string;
};

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      name,
      type = 'text',
      onChange = () => {},
      onFocus = () => {},
      onBlur = () => {},
      defaultValue,
      value,
      label,
      icon,
      iconPosition = 'left',
      active = false,
      autoComplete,
      success,
      error,
      message = '',
      className,
      required,
      disabled,
      inputmode,
      pattern,
      avatar = null,
      background = 'primary',
      prefix,
    },
    ref,
  ) => {
    const [isActive, setActive] = useState(active);
    const [inputType, setInputType] = useState(type);
    const [forceValueState, setForceValueState] = useState(false);

    const isTypePassword = passwordTypes.includes(type);

    const toggleType = () => {
      setInputType(inputType === type ? 'text' : type);
    };

    const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      if (ref && !forceValueState) setForceValueState(true);
      onChange(event);
    };

    const labelValue = required ? (
      <>
        {label} | <T phrase="required" />
      </>
    ) : (
      label
    );

    return (
      <InputComponentWrapper className={className} disabled={Boolean(disabled)}>
        <InputWrap
          icon={Boolean(icon)}
          active={isActive}
          success={Boolean(success)}
          error={Boolean(error)}
          disabled={Boolean(disabled)}
          data-testid={`${id}-text-input`}
          background={background}
        >
          {icon && iconPosition === 'left' && (
            <StyledSVG icon={icon} fill={Palette.blueSlate} />
          )}
          {avatar}
          <InputBox
            icon={Boolean(icon || avatar)}
            active={isActive}
            value={Boolean(value || forceValueState)}
            isTypePassword={isTypePassword}
          >
            <Label
              html-for={id}
              active={isActive}
              value={Boolean(value || forceValueState)}
              data-testid={`${id}-text-input-label`}
            >
              {labelValue}
            </Label>
            <InputWrapper>
              {value && prefix ? prefix : null}
              <Input
                id={id}
                type={inputType}
                data-testid={`${id}-text-input-box`}
                name={name}
                onChange={handleOnChange}
                value={value}
                active={isActive || forceValueState}
                autoComplete={autoComplete}
                ref={ref}
                defaultValue={defaultValue}
                inputMode={inputmode}
                pattern={pattern}
                onFocus={(event) => {
                  setActive(true);
                  if (ref) setForceValueState(true);
                  onFocus(event);
                }}
                onBlur={(event) => {
                  setActive(false);
                  onBlur(event);
                }}
                required={required}
                disabled={disabled}
              />
            </InputWrapper>
          </InputBox>
          {isTypePassword && (
            <PasswordButton onClick={toggleType} tabIndex={-1} type="button">
              <Iconography
                name={
                  inputType === 'text'
                    ? AvailableIcons.EYE_CROSSED_OUT
                    : AvailableIcons.EYE
                }
                data-testid={
                  inputType === 'text'
                    ? `${id}-hide-password`
                    : `${id}-show-password`
                }
              />
            </PasswordButton>
          )}
          {icon && iconPosition === 'right' && (
            <SVGRightWrap>
              <SVG icon={icon} fill={Palette.blueSlate} />
            </SVGRightWrap>
          )}
        </InputWrap>
        <HelperMessage
          success={Boolean(success)}
          error={Boolean(error)}
          active={false}
          data-testid={`${id}-helper-message`}
        >
          {message}
        </HelperMessage>
      </InputComponentWrapper>
    );
  },
);

TextInput.displayName = 'TextInput';

export default TextInput;
