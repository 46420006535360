import React from 'react';
import { LoadingSpinner, Pill } from 'components/atoms';
import { useModal } from 'components/util/modal-context';
import styled from 'styled-components';
import { MIN_TABLET } from 'components/styles';
import { useUser } from 'components/util/user-context';
import { UserMatchmakingStatus } from 'graphpl/core';
import { useRouter } from 'next/router';
import { isValidToShowNewMatchPage } from 'pages/new-match/[matchId]';
import { CreateMatchOptionsModal } from './create-match-options-modal';
import { Searching } from './searching-modal';
import { shouldShowGoToMatchButton } from './utilities/go-to-match-state-management';

export const CreateAMatchPill = styled(Pill)`
  width: 100%;
  @media (min-width: ${MIN_TABLET}) {
    width: ${({ theme }) => theme.spacing.customSpacing('215px')};
    margin-bottom: ${({ theme }) => theme.spacing.customSpacing('4px')};
    margin-top: ${({ theme }) => theme.spacing.BASE_SPACING};
  }

  @media (min-width: ${({ theme }) => theme.spacing.customSpacing('520px')}) {
    min-width: ${({ theme }) => theme.spacing.customSpacing('208px')};
  }
`;

export const CreateAMatchSearchingButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

type NewCreateMatchButtonProps = {
  storybookSearching?: boolean;
  storybookActiveMatch?: boolean;
};

export const NewCreateMatchButton = ({
  storybookSearching,
  storybookActiveMatch,
}: NewCreateMatchButtonProps) => {
  const { user } = useUser();
  const router = useRouter();
  const { displayModal } = useModal();
  const openCreateMatchFlow = () => {
    displayModal({
      type: 'layout',
      body: <CreateMatchOptionsModal />,
      modalDisplayed: true,
      displayBackButton: false,
    });
  };

  if (
    storybookSearching ||
    user?.status?.matchmakingStatus === UserMatchmakingStatus.SEARCHING
  ) {
    return (
      <CreateAMatchPill
        size="large"
        purpose="open"
        onClick={() =>
          displayModal({
            type: 'skinny',
            body: <Searching />,
            modalDisplayed: true,
            displayBackButton: false,
          })
        }
      >
        <CreateAMatchSearchingButton>
          <LoadingSpinner color="blue" />
          Searching
          <LoadingSpinner color="transparent" />
        </CreateAMatchSearchingButton>
      </CreateAMatchPill>
    );
  }

  if (storybookActiveMatch || shouldShowGoToMatchButton(user)) {
    const userMatch = user?.activeContests?.[0];

    const url =
      userMatch?.status && isValidToShowNewMatchPage(userMatch.status)
        ? `/new-match/${userMatch?.id}`
        : `/matches/${userMatch?.id}`;

    return (
      <CreateAMatchPill
        size="large"
        purpose="info"
        animation="strong"
        onClick={() => router.push(url)}
      >
        Go to match
      </CreateAMatchPill>
    );
  }
  return (
    <CreateAMatchPill
      type="button"
      purpose="success"
      animation="strong"
      size="large"
      onClick={openCreateMatchFlow}
    >
      Find a match
    </CreateAMatchPill>
  );
};
