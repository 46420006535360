import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { T } from 'react-polyglot-hooks';
import {
  ButtonContainer,
  ContentContainer,
  DesktopContentSpacer,
  NavigationButton,
  DesktopPositionIndicatorContainer,
  MobilePositionIndicatorContainer,
  NoteContainer,
  PositionIndicator,
  SlideshowContainer,
  StyledLink,
  UndercarriageContainer,
} from './slideshow-item.styles';
import { ButtonVariant } from '../button';

type NavigationalButtonProps = PropsWithChildren<{
  action: string | MouseEventHandler<HTMLButtonElement>;
  testIdModifier: string;
  purpose: ButtonVariant;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}>;

const NavigationalButton = ({
  children,
  action,
  testIdModifier,
  purpose,
  disabled = false,
  onClick,
}: NavigationalButtonProps) => {
  const isFunction = typeof action === 'function';

  return (
    <>
      {isFunction ? (
        <NavigationButton
          data-testid={`slideshow-button-${testIdModifier}`}
          onClick={action}
          size="large"
          purpose={purpose}
          disabled={disabled}
        >
          {children}
        </NavigationButton>
      ) : (
        <StyledLink
          href={action}
          data-testid={`slideshow-button-${testIdModifier}-link`}
        >
          <NavigationButton
            data-testid={`slideshow-button-${testIdModifier}`}
            size="large"
            purpose={purpose}
            onClick={onClick}
          >
            {children}
          </NavigationButton>
        </StyledLink>
      )}
    </>
  );
};

type ViewTypes = 'desktop' | 'mobile';

export type SlideshowItemProps = PropsWithChildren<{
  prevItem?: string | MouseEventHandler<HTMLButtonElement>;
  prevItemContent?: string;
  prevItemStyling?: ButtonVariant;
  prevItemDisabled?: boolean;
  nextItem?: string | MouseEventHandler<HTMLButtonElement>;
  nextItemContent?: string;
  nextItemStyling?: ButtonVariant;
  nextItemDisabled?: boolean;
  nextItemOnClick?: MouseEventHandler<HTMLButtonElement>;
  amountOfItems?: number;
  currentPosition?: number;
  itemNotes?: React.ReactNode;
  undercarriage?: React.ReactNode;
  itemContent?: React.ReactNode;
  backgroundColor?: string;
}>;

const SlideshowItem = ({
  prevItem,
  prevItemContent = 'back',
  prevItemStyling = 'info',
  prevItemDisabled,
  nextItem,
  nextItemContent = 'next',
  nextItemStyling = 'info',
  nextItemDisabled,
  nextItemOnClick,
  amountOfItems,
  currentPosition = 1, // > 1 Indexed
  itemNotes,
  undercarriage,
  itemContent,
  backgroundColor,
}: SlideshowItemProps) => {
  const createPositionIndicators = (
    numOfItems: number,
    currentId: number,
    view: ViewTypes,
  ) => {
    const positions = [];
    for (let itemId = 0; itemId < numOfItems; itemId += 1) {
      positions.push(
        itemId === currentId - 1 ? (
          <PositionIndicator
            data-testid={`slideshow-${view}-positionIndicator-${
              itemId + 1
            }-selected`}
            itemSelected
            key={`slideshow-${view}-${itemId + 1}-position-indicator`}
          />
        ) : (
          <PositionIndicator
            data-testid={`slideshow-${view}-positionIndicator-${itemId + 1}`}
            key={`slideshow-${view}-${itemId + 1}-position-indicator`}
          />
        ),
      );
    }
    return positions;
  };

  return (
    <SlideshowContainer>
      {itemContent && (
        <ContentContainer
          className="slideshow-content-wrapper"
          backgroundColor={backgroundColor}
        >
          {amountOfItems && <DesktopContentSpacer />}
          {itemContent}
          {amountOfItems && (
            <DesktopPositionIndicatorContainer data-testid="slideshow-positionIndicator-container-desktop">
              {createPositionIndicators(
                amountOfItems,
                currentPosition,
                'desktop',
              )}
            </DesktopPositionIndicatorContainer>
          )}
        </ContentContainer>
      )}
      <NoteContainer className="slideshow-note-wrapper">
        {itemNotes && <>{itemNotes}</>}
        {(prevItem || nextItem) && (
          <ButtonContainer
            data-testid="slideshow-button-container"
            undercarriage={Boolean(undercarriage)}
          >
            {prevItem && (
              <NavigationalButton
                action={prevItem}
                testIdModifier="back"
                purpose={prevItemStyling}
                disabled={prevItemDisabled}
              >
                <T phrase={prevItemContent} />
              </NavigationalButton>
            )}
            {nextItem && (
              <NavigationalButton
                action={nextItem}
                testIdModifier="next"
                purpose={nextItemStyling}
                disabled={nextItemDisabled}
                onClick={nextItemOnClick}
              >
                <T phrase={nextItemContent} />
              </NavigationalButton>
            )}
          </ButtonContainer>
        )}
        {undercarriage && (
          <UndercarriageContainer>{undercarriage}</UndercarriageContainer>
        )}
        {amountOfItems && (
          <MobilePositionIndicatorContainer data-testid="slideshow-positionIndicator-container-mobile">
            {createPositionIndicators(amountOfItems, currentPosition, 'mobile')}
          </MobilePositionIndicatorContainer>
        )}
      </NoteContainer>
    </SlideshowContainer>
  );
};

export default SlideshowItem;
