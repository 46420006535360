import styled, {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
  css,
} from 'styled-components';

export type AvatarType = 'groupChat' | 'default';
export type AvatarStatus = 'ready' | 'cancel';
export type AvatarSize =
  | 'smallest'
  | 'smaller'
  | 'small'
  | 'medium'
  | 'large'
  | 'largest'
  | 'profile'
  | 'kotl';

const sizeVariants: Record<AvatarSize, string> = {
  smallest: `
    height: 16px;
    width: 16px;
  `,
  smaller: `
    height: 24px;
    width: 24px;
  `,
  small: `
    height: 32px;
    width: 32px;
  `,
  medium: `
    height: 44px;
    width: 44px;
  `,
  large: `
    height: 64px;
    width: 64px;
  `,
  largest: `
    height: 80px;
    width: 80px;
  `,
  profile: `
    height: 84px;
    width: 84px;
  `,
  kotl: `
    height: 156px;
    width: 156px;
  `,
};

export const AvatarImage = styled.img<{ winner: boolean; size: AvatarSize }>`
  border: ${({ winner }) => !winner && '1px solid'};
  overflow: hidden;
  border-color: ${({ theme }) => theme.palette.border.alternative};
  border-radius: 50%;
  ${({ size }) => sizeVariants[size]};
`;

const backgroundVariants = ({
  theme,
  type,
}: {
  theme: DefaultTheme;
  type?: AvatarType;
}) => {
  if (type === 'default') return theme.palette.avatar.background.primary;
  if (type === 'groupChat') return theme.palette.background.alternative;
  return theme.palette.background.alternative;
};

export const PlayerIconContainer = styled.div<{
  winner?: boolean;
  size: AvatarSize;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  border: ${({ winner }) => !winner && '1px solid'};
  border-color: ${({ theme }) => theme.palette.border.alternative};
  border-radius: 50%;

  background-color: ${backgroundVariants};
  ${({ size }) => sizeVariants[size]};
`;

export const CheckMarkStyled = styled.img`
  height: 24px;
  width: 24px;
`;

const statusVariants: Record<
  AvatarStatus,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  ready: css`
    background-color: ${({ theme }) => theme.palette.avatar.background.ready};
  `,
  cancel: css`
    background-color: ${({ theme }) => theme.palette.avatar.background.cancel};
  `,
};

export const Overlay = styled.div<{ size: AvatarSize; status: AvatarStatus }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-color: white;
  ${({ size }) => sizeVariants[size]};
  ${({ status }) => statusVariants[status]};
`;

const goldWinnerSizeVariants: Record<
  AvatarSize,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  smallest: css`
    height: 36px;
    width: 36px;
  `,
  profile: css`
    height: 36px;
    width: 36px;
  `,
  kotl: css`
    height: 36px;
    width: 36px;
  `,
  smaller: css`
    height: 36px;
    width: 36px;
  `,
  small: css`
    height: 40px;
    width: 40px;
  `,
  medium: css`
    height: 52px;
    width: 52px;
  `,
  large: css`
    height: 72px;
    width: 72px;
  `,
  largest: css`
    border-width: 3px;
    border-style: solid;
    border-radius: 50%;
    border-color: ${({ theme }) => theme.palette.avatar.background.yellow};
    height: 92px;
    width: 92px;
  `,
};

export const GoldWinnerBackground = styled.div<{ size: AvatarSize }>`
  position: absolute;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  border-color: ${({ theme }) => theme.palette.avatar.background.yellow};
  ${({ size }) => goldWinnerSizeVariants[size]};
`;

export const OuterAvatarWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InnerAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
