/* eslint-disable react/display-name */
import React, { PropsWithChildren } from 'react';
import { useDisableScrollbar } from 'helpers';
import {
  ModalBox,
  ModalCloseButton,
  ModalCloseButtonSVG,
  ModalInnerBox,
  ModalType,
  ModalWrapper,
  ModalWrapperBack,
} from './modal.styles';

const CloseButton = ({
  onClose,
  modalType,
}: {
  onClose: () => void;
  modalType: ModalType;
}) => (
  <ModalCloseButton
    data-testid="modal-close"
    modalType={modalType}
    title="close"
    onClick={onClose}
  >
    <ModalCloseButtonSVG
      modalType={modalType}
      width={24}
      height={24}
      icon="crossCircle"
      tab-index="-1"
      role="img"
    />
  </ModalCloseButton>
);

const clickOutsideModal = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  close: () => void,
) => {
  if (e.target !== document.getElementById('modal')) return false;

  return close.call(e);
};

export type ModalProps = PropsWithChildren<{
  isOpen?: boolean;
  closeModal?: () => void;
  closeModalOnClickOutside?: () => void;
  modalType: ModalType;
  displayBackButton?: boolean;
  blur: boolean;
}>;

const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      children,
      isOpen = false,
      closeModal = () => {},
      closeModalOnClickOutside = () => {},
      modalType,
      displayBackButton = true,
      blur,
    },
    ref,
  ) => {
    useDisableScrollbar(() => isOpen, [isOpen]);

    return (
      <ModalWrapperBack isOpen={isOpen}>
        <ModalWrapper
          isOpen={isOpen}
          onClick={(e) => clickOutsideModal(e, closeModalOnClickOutside)}
          blur={blur}
        >
          <ModalBox
            modalType={modalType}
            id="modal"
            data-testid="outside-modal-window"
          >
            {isOpen && (
              <ModalInnerBox
                aria-modal="true"
                data-reach-dialog-content
                role="dialog"
                tab-index="-1"
                modalType={modalType}
                ref={ref}
              >
                {displayBackButton && (
                  <CloseButton onClose={closeModal} modalType={modalType} />
                )}
                {children}
              </ModalInnerBox>
            )}
          </ModalBox>
        </ModalWrapper>
      </ModalWrapperBack>
    );
  },
);

export default Modal;
