import styled from 'styled-components';
import { fontS, fontSContrast } from 'components/styles';

export const SectionDescription = styled.span`
  ${fontS};
  color: ${({ theme }) => theme.new.subContent};
`;

export const SectionTitle = styled.span`
  margin-left: 0px;
  ${fontSContrast};
  color: ${({ theme }) => theme.new.content};
`;

export const RuleTitleWrapper = styled.span<{
  titleBackground: 'light' | 'dark';
}>`
  padding-right: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  padding-left: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  border-radius: ${({ theme }) => theme.spacing.customSpacing('2px')};
  margin-bottom: ${({ theme }) => theme.spacing.customSpacing('2px')};
  background-color: ${({ theme }) => theme.new.surface};
`;

export const RuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;
