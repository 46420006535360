import { TOUCHABLE_STYLE } from 'components/styles';
import styled from 'styled-components';

export const DropdownPickerBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;
  padding: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  color: ${({ theme }) => theme.new.content};
  fill: ${({ theme }) => theme.new.content};
  ${TOUCHABLE_STYLE}
`;

export const DropdownPickerArrow = styled.div<{
  hideArrow?: boolean;
  invertArrow?: boolean;
}>`
  display: ${({ hideArrow }) => (hideArrow ? 'none' : 'flex')};
  align-items: center;
  transform: ${({ invertArrow }) => `rotate(${invertArrow ? '180deg' : ''})`};
`;
