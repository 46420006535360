import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';
import Router from 'next/router';
import Header from 'components/organisms/global-header';
import Footer from 'components/organisms/global-footer';
import GlobalBottomBar, {
  LobbyBottomBarType,
} from 'components/organisms/global-bottom-bar';
import styled from 'styled-components';

const PageWrapper = styled.div<{ transparentBackground: boolean }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, transparentBackground }) =>
    transparentBackground ? 'transparent' : theme.new.background};
`;
const PageHeader = styled.header`
  flex-shrink: 0;
`;
const PageContent = styled.div`
  flex: 1 0 auto;
`;
const PageFooter = styled.footer`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  flex-shrink: 0;
`;

type DisplayOptions =
  | 'FULL'
  | 'HIDE_ON_MOBILE_LANDSCAPE'
  | 'NONE'
  | 'NO-MENU'
  | 'DEPOSIT-ONLY';

type UseNavigationContextType = {
  setHeader: (arg: DisplayOptions) => void;
  setFooter: (arg: DisplayOptions) => void;
  setBottomBar: (arg: DisplayOptions) => void;
  setLobbyBottomBar: (arg: LobbyBottomBarType) => void;
  setBottomBarNav: (arg: boolean) => void;
};

type NavigationProviderProps = PropsWithChildren<{
  isApp: boolean;
  authenticated: boolean;
  disableNavigation?: boolean;
  headerOverwrite?: DisplayOptions;
  footerOverwrite?: DisplayOptions;
  bottomBarOverwrite?: DisplayOptions;
  transparentBackground?: boolean;
}>;

export const NavigationContext = createContext<UseNavigationContextType>({
  setHeader: () => {},
  setFooter: () => {},
  setBottomBar: () => {},
  setLobbyBottomBar: () => {},
  setBottomBarNav: () => {},
});

export const useNavigation = () => useContext(NavigationContext);

const InternalPage = ({ children }: PropsWithChildren) => (
  <PageContent id="internal-page">{children}</PageContent>
);

const MemoisedInternalPage = React.memo(InternalPage);

export const NavigationProvider = ({
  isApp,
  authenticated,
  children,
  headerOverwrite,
  footerOverwrite,
  bottomBarOverwrite,
  disableNavigation,
  transparentBackground = false,
}: NavigationProviderProps) => {
  const [headerState, setHeaderState] = useState<DisplayOptions>(
    headerOverwrite || 'FULL',
  );
  const [footerState, setFooterState] = useState<DisplayOptions>(
    footerOverwrite || 'FULL',
  );
  const [bottomBarState, setBottomBarState] = useState<DisplayOptions>(
    bottomBarOverwrite || 'FULL',
  );
  const [
    lobbyBottomBarState,
    setLobbyBottomBarState,
  ] = useState<LobbyBottomBarType | null>(null);
  const [bottomBarNavState, setBottomBarNavState] = useState<boolean>(true);

  const shouldShowHeader = !isApp && headerState !== 'NONE';
  const shouldShowFooter = !isApp && footerState === 'FULL';
  const shouldShowBottomBar = !isApp || bottomBarState === 'FULL';
  const shouldShowBottomBarNav = !isApp && bottomBarNavState === true;

  const handleRouteChange = (): void => {
    setHeaderState('FULL');
    setFooterState('FULL');
    setBottomBarState('FULL');
    setLobbyBottomBarState(null);
    setBottomBarNavState(true);
  };

  useEffect(() => {
    Router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  if (disableNavigation)
    return (
      <NavigationContext.Provider
        value={{
          setHeader: () => {},
          setFooter: () => {},
          setBottomBar: () => {},
          setLobbyBottomBar: () => {},
          setBottomBarNav: () => {},
        }}
      >
        {children}
      </NavigationContext.Provider>
    );

  const setHeader = (state: DisplayOptions): void => {
    if (headerState !== state) setHeaderState(state);
  };
  const setFooter = (state: DisplayOptions): void => {
    if (footerState !== state) setFooterState(state);
  };
  const setBottomBar = (state: DisplayOptions): void => {
    if (bottomBarState !== state) setBottomBarState(state);
  };
  const setLobbyBottomBar = (state: LobbyBottomBarType): void => {
    if (JSON.stringify(lobbyBottomBarState) !== JSON.stringify(state))
      setLobbyBottomBarState(state);
  };
  const setBottomBarNav = (state: boolean): void => {
    if (bottomBarNavState !== state) setBottomBarNavState(state);
  };

  return (
    <NavigationContext.Provider
      value={{
        setHeader,
        setFooter,
        setBottomBar,
        setLobbyBottomBar,
        setBottomBarNav,
      }}
    >
      <PageWrapper transparentBackground={transparentBackground}>
        <PageHeader>
          {shouldShowHeader && (
            <Header
              authenticated={authenticated}
              hideHeaderOnMobileLandscape={
                headerState === 'HIDE_ON_MOBILE_LANDSCAPE'
              }
              disableMenu={headerState === 'NO-MENU'}
              delayOverwrite={undefined}
              displayDepositButton={headerState === 'DEPOSIT-ONLY'}
            />
          )}
        </PageHeader>
        <MemoisedInternalPage>{children}</MemoisedInternalPage>
        {shouldShowFooter && <Footer authenticated={authenticated} />}
        <PageFooter>
          {shouldShowBottomBar && authenticated && (
            <GlobalBottomBar
              lobbyBottomBar={lobbyBottomBarState}
              shouldShowBottomBarNav={shouldShowBottomBarNav}
            />
          )}
        </PageFooter>
      </PageWrapper>
    </NavigationContext.Provider>
  );
};
