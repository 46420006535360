const games = {
  madden: '#C74A42',
  cod: '#384A6D',
  callofduty: '#384A6D',
  fn: '#202A7E',
  fortnite: '#202A7E',
  nba: '#61BDFF',
  nba2k: '#61BDFF',
  fifa: '#B643E9',
  mlb: '#00748A',
};

export default games;
