import React, { PropsWithChildren, createContext, useContext } from 'react';

export const AppContext = createContext<AppContextProps>({ isApp: false });

type AppContextProps = {
  isApp: boolean;
};

export const AppProvider = ({
  children,
  isApp,
}: PropsWithChildren<AppContextProps>) => {
  return (
    <AppContext.Provider value={{ isApp }}>{children}</AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
