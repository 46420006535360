import React from 'react';
import { T } from 'react-polyglot-hooks';
import LoadingSpinner, {
  CenterSpinner,
} from 'components/atoms/loading-spinner';
import { useUser } from 'components/util/user-context';
import InstantMatchAmount from 'components/molecules/match/instantMatchAmount';
import MatchDetailsSection from 'components/organisms/match-details-modal/match-details-section';
import { ResponsivePill } from 'components/atoms/button/button';
import {
  MatchmakingType,
  UserActivityStatus,
  UserMatchmakingStatus,
  useCurrentUserMatchmakingQuery,
  useStopSearchMatchmakingMutation,
} from 'graphpl/core';
import { useModal } from 'components/util/modal-context';
import { AvailableIcons, Iconography } from 'components/styles';
import { useTheme } from 'styled-components';
import { useRouter } from 'next/router';
import { messageParent } from 'helpers/web-view';
import { useToast } from 'components/util/toast-context';
import {
  Wrapper,
  Content,
  InternalContent,
  Footer,
  HeaderIconWrapper,
  HeaderWrapper,
  SpinnerWrapper,
  ErrorHeading,
  ErrorContent,
} from './searching.styles';
import { shouldShowGoToMatchButton } from '../utilities/go-to-match-state-management';

type SearchingProps = {
  storybookQueryLoading?: boolean;
  storybookCancelLoading?: boolean;
  storybookActiveMatch?: boolean;
  topPadding?: number;
  bottomPadding?: number;
};

type GameFormat =
  | ({
      __typename?: 'GameFormat' | undefined;
      name?: string | null | undefined;
    } | null)[]
  | null
  | undefined;

export const searchingFormatTitle = (
  gameFormats: GameFormat,
): string | undefined => {
  if (!gameFormats) return;
  if (gameFormats.length === 0) return;
  if (gameFormats.length === 1) return gameFormats?.[0]?.name || undefined;
  return 'Multiple Formats';
};

export const Searching = ({
  storybookQueryLoading = false,
  storybookCancelLoading = false,
  storybookActiveMatch = false,
  topPadding = 0,
  bottomPadding = 0,
}: SearchingProps) => {
  const router = useRouter();
  const theme = useTheme();
  const { user: userFromContext, setUserRaw } = useUser();
  const { dismissModal } = useModal();
  const { displayToast } = useToast();
  const {
    data: matchmakingData,
    loading: queryLoading,
    error,
  } = useCurrentUserMatchmakingQuery({
    fetchPolicy: 'network-only',
    skip: Boolean(userFromContext?.activeMatchmaking),
  });

  const user = matchmakingData?.currentUser || userFromContext;

  const [
    doCancel,
    { loading: cancelLoading },
  ] = useStopSearchMatchmakingMutation({
    onCompleted: () => {
      setUserRaw((prev) => ({
        ...prev,
        status: {
          ...prev.status,
          matchmakingStatus: UserMatchmakingStatus.INACTIVE,
          currentActivity: UserActivityStatus.BROWSING,
        },
        activeMatchmaking: null,
      }));
      messageParent({
        action: 'REFETCH_USER',
        source: 'SEARCHING_MODAL',
      });
      messageParent({
        action: 'DISMISS_MODAL',
        source: 'SEARCHING_MODAL',
      });
      dismissModal();
    },
    onError: (err) => {
      displayToast({
        heading: 'An error occured',
        subtext: err.message,
        type: 'error',
        toastDisplayed: true,
      });
    },
  });

  if (storybookQueryLoading || (!user?.activeMatchmaking && queryLoading))
    return (
      <Wrapper topPadding={topPadding} bottomPadding={bottomPadding}>
        <SpinnerWrapper>
          <CenterSpinner
            color={theme.new.theme === 'light' ? 'blue' : 'white'}
          />
        </SpinnerWrapper>
      </Wrapper>
    );
  if (error) {
    return (
      <Wrapper topPadding={topPadding} bottomPadding={bottomPadding}>
        <Content>
          <HeaderWrapper>
            <InternalContent>
              <ErrorHeading>Searching</ErrorHeading>
              <ErrorContent>
                There was an error fetching your matchmaking information.
              </ErrorContent>
            </InternalContent>
            <HeaderIconWrapper
              onClick={() => {
                dismissModal();
                messageParent({
                  action: 'DISMISS_MODAL',
                  source: 'SEARCHING_MODAL',
                });
              }}
            >
              <Iconography name={AvailableIcons.CROSS} />
            </HeaderIconWrapper>
          </HeaderWrapper>
        </Content>
        <Footer disabled={storybookCancelLoading || cancelLoading}>
          <ResponsivePill
            size="large"
            purpose="info"
            onClick={() => {
              dismissModal();
              messageParent({
                action: 'DISMISS_MODAL',
                source: 'SEARCHING_MODAL',
              });
            }}
            disabled={storybookCancelLoading || cancelLoading}
          >
            Close
          </ResponsivePill>
        </Footer>
      </Wrapper>
    );
  }
  if (!user?.activeMatchmaking) {
    return (
      <Wrapper topPadding={topPadding} bottomPadding={bottomPadding}>
        <Content>
          <HeaderWrapper>
            <InternalContent>
              <ErrorHeading>Searching</ErrorHeading>
              <ErrorContent>There is no active search on going.</ErrorContent>
            </InternalContent>
            <HeaderIconWrapper
              onClick={() => {
                dismissModal();
                messageParent({
                  action: 'DISMISS_MODAL',
                  source: 'SEARCHING_MODAL',
                });
              }}
            >
              <Iconography name={AvailableIcons.CROSS} />
            </HeaderIconWrapper>
          </HeaderWrapper>
        </Content>
        <Footer disabled={storybookCancelLoading || cancelLoading}>
          <ResponsivePill
            size="large"
            purpose="info"
            onClick={() => {
              dismissModal();
              messageParent({
                action: 'DISMISS_MODAL',
                source: 'SEARCHING_MODAL',
              });
            }}
            disabled={storybookCancelLoading || cancelLoading}
          >
            Close
          </ResponsivePill>
        </Footer>
      </Wrapper>
    );
  }

  const {
    activeMatchmaking: {
      amounts,
      amountType,
      gameFormat,
      gameFormats,
      rules,
      type,
    },
  } = user;

  const formatTitle =
    searchingFormatTitle(gameFormats) || gameFormat?.name || '';

  const goToMatchButton =
    storybookActiveMatch || shouldShowGoToMatchButton(userFromContext);
  const userMatch = userFromContext?.activeContests?.[0]?.id;

  return (
    <Wrapper topPadding={topPadding} bottomPadding={bottomPadding}>
      <Content>
        <HeaderWrapper>
          <InternalContent>
            <InstantMatchAmount
              type={type || MatchmakingType.MATCHMAKING}
              amounts={amounts}
              amountType={amountType}
            />
            <MatchDetailsSection
              rules={rules}
              formatTitle={formatTitle || gameFormat?.name || ''}
              disabledFullRules
            />
          </InternalContent>
          <HeaderIconWrapper
            onClick={() => {
              dismissModal();
              messageParent({
                action: 'DISMISS_MODAL',
                source: 'SEARCHING_MODAL',
              });
            }}
          >
            <Iconography name={AvailableIcons.CROSS} />
          </HeaderIconWrapper>
        </HeaderWrapper>
      </Content>
      <Footer disabled={storybookCancelLoading || cancelLoading}>
        {goToMatchButton ? (
          <ResponsivePill
            size="large"
            purpose="info"
            onClick={() => {
              // TODO: This should be a navigation action
              messageParent({
                action: 'NAVIGATE',
                source: 'SEARCHING_MODAL',
                data: {
                  url: `/matches/${userMatch}`,
                  targetId: `${userMatch}`,
                },
              });
              router.push(`/matches/${userMatch}`);
            }}
            disabled={storybookCancelLoading || cancelLoading}
          >
            Go to match
          </ResponsivePill>
        ) : (
          <ResponsivePill
            size="large"
            purpose="error"
            onClick={() => doCancel()}
            disabled={storybookCancelLoading || cancelLoading}
          >
            {storybookCancelLoading || cancelLoading ? (
              <LoadingSpinner color="white" />
            ) : (
              <T phrase="cancelSearch" />
            )}
          </ResponsivePill>
        )}
      </Footer>
    </Wrapper>
  );
};
