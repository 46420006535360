import jwtDecode from 'jwt-decode';
import cookies from 'js-cookie';
import navigate from './navigate';
import { ON_SERVER } from './context';

type JWT = {
  sId: string;
  userId: string;
};

const setAuthToken = (token: string) => {
  if (ON_SERVER)
    throw new Error(`Can only setAuthToken "${token}" on the client.`);

  const jwt = jwtDecode(token) as JWT;

  cookies.set('Session', jwt.sId, { expires: 30 });
  cookies.set('UserToken', token, { expires: 30 });
};

const fetchUserIdFromAuthToken = (token: string) => {
  if (ON_SERVER)
    throw new Error(
      `Can only fetchUserIdFromAuthToken "${token}" on the client.`,
    );

  const jwt = jwtDecode(token) as JWT;

  return jwt.userId;
};

const cookiesToDestroyOnLogout = ['UserToken', 'UserLobby', 'Session'];

const clearAuthToken = () => {
  if (ON_SERVER) throw new Error('Can only clear auth on client');

  cookies.remove('UserToken');
  cookies.remove('UserLobby');
  cookies.remove('Session');
  navigate('/login');
};

const getSessionId = () => {
  return cookies.get('Session');
};

export {
  setAuthToken,
  clearAuthToken,
  getSessionId,
  fetchUserIdFromAuthToken,
  cookiesToDestroyOnLogout,
};
