import { FC, SVGProps } from 'react';
import Activision from './assets/platforms/activision.svg';
import BattleNet from './assets/platforms/battlenet.svg';
import Epic from './assets/platforms/epic.svg';
import Origin from './assets/platforms/origin.svg';
import Playstation from './assets/platforms/playstation.svg';
import Riot from './assets/platforms/riot.svg';
import Steam from './assets/platforms/steam.svg';
import WbGames from './assets/platforms/wb-games.svg';
import Xbox from './assets/platforms/xbox.svg';

// eslint-disable-next-line no-shadow
export enum PlatformIcons {
  ACTIVISION = 'Activision',
  BATTLE_NET = 'BattleNet',
  EPIC = 'Epic',
  ORIGIN = 'Origin',
  PLAYSTATION = 'Playstation',
  RIOT = 'Riot',
  STEAM = 'Steam',
  WB_GAMES = 'WbGames',
  XBOX = 'Xbox',
}

export const platformIconsComponentMap: Record<
  PlatformIcons,
  FC<SVGProps<SVGElement>>
> = {
  Activision,
  BattleNet,
  Epic,
  Origin,
  Playstation,
  Riot,
  Steam,
  WbGames,
  Xbox,
};
