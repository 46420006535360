import styled, {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
  css,
} from 'styled-components';

type UserBadgeOptions = 'blackDiamond' | 'verified';

const userBadgeOptions: Record<
  UserBadgeOptions,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  blackDiamond: css`
    background-image: url(/assets/images/badges/black-diamond.svg);
  `,
  verified: css`
    background-image: url(/assets/images/badges/verified.svg);
  `,
};

const userBadgeVariant = ({ badge }: { badge: UserBadgeOptions }) => {
  return userBadgeOptions[badge];
};

const UserBadge = styled.div<{ badge: UserBadgeOptions }>`
  width: 20px;
  height: 20px;
  background: 0 0 no-repeat;
  background-size: contain;
  margin-left: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
  padding-right: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
  display: inline-block;

  ${userBadgeVariant}
`;

export default UserBadge;
