export const EXTERNAL_LINK = 'click_external_link';
export const SHOP_LINK = 'click_shop_link';
export const PHP_LINK = 'click_php_link';
export const SPA_LINK = 'click_spa_link';
export const CLICK_BUTTON = 'click_button';

export const SEEN = 'seen';

export const TEST_EVENT = 'test_event';

export const VIEWED_PROFILE = 'viewed profile';
