import React from 'react';

const yCombinator = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="22.5" cy="22.5" r="22" fill="white" stroke="#D4E2EC" />
    <path
      d="M20.9837 25.4436L13.2598 10.9743H16.7897L21.3332 20.1312C21.4031 20.2943 21.4847 20.4632 21.5779 20.638C21.6711 20.8127 21.7526 20.9933 21.8225 21.1797C21.8691 21.2496 21.9041 21.3137 21.9274 21.3719C21.9507 21.4302 21.974 21.4826 21.9973 21.5292C22.1138 21.7622 22.2186 21.9894 22.3118 22.2107C22.405 22.4321 22.4866 22.636 22.5565 22.8224C22.7429 22.4263 22.9467 22.001 23.1681 21.5467C23.3894 21.0923 23.6166 20.6205 23.8496 20.1312L28.463 10.9743H31.7483L23.9545 25.6184V34.95H20.9837V25.4436Z"
      fill="#FB651E"
    />
  </svg>
);

const comcast = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="22.5" cy="22.5" r="22" fill="white" stroke="#D4E2EC" />
    <path
      d="M22.2992 12.8002C22.808 11.9566 23.6522 11.0739 24.5612 10.7006C26.1258 9.97775 28.223 10.2886 29.4995 11.4324C30.7348 12.5688 31.3614 14.1263 31.0658 15.8675C31.3687 15.8108 31.6746 15.6138 31.9953 15.5018C33.9128 14.9522 35.9408 15.5481 37.1703 17.2175C38.0938 18.4093 38.3351 20.2608 37.7808 21.7004C37.5057 22.4245 37.0365 23.0757 36.4265 23.6312L36.488 23.694C38.2719 23.782 40.0251 25.1887 40.5662 26.9135C41.153 28.6532 40.6369 30.5123 39.3839 31.7847C38.3602 32.8121 37.044 33.1676 35.5808 33.1376H9.0166C6.83545 33.1854 5.15013 32.2042 4.24277 30.2348C3.53089 28.5442 3.88242 26.382 5.2118 25.0933C6.05004 24.2032 7.10019 23.8387 8.23257 23.6552C6.95023 22.4471 6.33245 20.7941 6.6928 19.0214C6.95023 17.585 8.15614 16.1305 9.54139 15.6287C10.7619 15.1359 12.4151 15.2224 13.5329 15.8913L13.5726 15.842C13.2358 13.8873 14.0888 12.1237 15.6857 11.0425C17.1095 10.0646 19.3859 10.0898 20.78 11.1294C21.4066 11.519 21.9227 12.188 22.2992 12.8002Z"
      fill="white"
    />
    <path
      d="M29.8199 13.5305C30.3907 14.6998 30.0554 16.2319 29.4597 17.2817L24.8508 27.811L24.8109 27.8603L23.1449 17.1788C23.0112 16.153 24.0905 15.7154 24.7258 15.2226C24.6935 15.0719 24.5301 15.0793 24.4274 15.0091L22.8701 14.9926L22.8228 14.9448C22.8316 13.6188 23.5346 12.3943 24.7258 11.7507C25.9702 11.0206 27.7229 11.2191 28.8023 12.1554C29.2406 12.5524 29.6098 13.0137 29.8199 13.5305Z"
      fill="#645FAA"
    />
    <path
      d="M21.3286 13.1646C21.7053 13.8724 21.923 14.676 21.7905 15.5658L19.9111 27.796C19.9213 27.8109 19.9038 27.8274 19.8889 27.8347L19.4022 26.8507L14.6917 16.1692C14.2534 14.7938 14.6594 13.3558 15.6461 12.37C16.6402 11.3457 18.2756 11.0679 19.5757 11.6235C20.264 11.9252 20.9214 12.4808 21.3286 13.1646Z"
      fill="#C9234A"
    />
    <path
      d="M36.6379 18.3135C37.2717 19.4109 37.1291 21.0716 36.4244 22.09C35.9879 22.8053 35.2671 23.1623 34.6568 23.6311L24.8964 30.4344H24.874L24.983 30.109L30.1568 18.2508C30.9318 16.8531 32.4113 16.1452 33.9761 16.3587C35.0482 16.5575 36.1202 17.2652 36.6379 18.3135Z"
      fill="#0089CF"
    />
    <path
      d="M14.4652 18.2508L19.8492 30.4332H19.7626L8.90792 22.8367C7.80327 21.8915 7.35049 20.4758 7.72547 19.0453C8.00635 17.9731 8.88281 16.9712 9.92551 16.5737C11.6328 15.9391 13.5562 16.6141 14.4652 18.2508Z"
      fill="#F36F21"
    />
    <path
      d="M39.4703 26.9133C40.0114 28.0737 39.7982 29.6625 39.0247 30.6494C38.2572 31.5949 37.2305 32.1115 36.026 32.0786L24.4521 32.0862L34.1478 25.2365C35.0952 24.6168 36.5349 24.5046 37.5688 25.0063C38.3672 25.3245 39.1188 26.1189 39.4703 26.9133Z"
      fill="#0DB14B"
    />
    <path
      d="M5.19722 26.9133C4.65766 28.0737 4.86929 29.6625 5.64435 30.6494C6.41196 31.5949 7.43548 32.1115 8.64153 32.0786L20.2154 32.0862L10.5196 25.2365C9.57248 24.6168 8.13287 24.5046 7.10038 25.0063C6.30159 25.3245 5.55026 26.1189 5.19722 26.9133Z"
      fill="#FDB913"
    />
  </svg>
);

const samsung = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="22.5" cy="22.5" r="22" fill="black" stroke="#D4E2EC" />
    <path
      d="M34.7026 24.5905L34.631 20.374H35.935V25.6889H34.0601L32.7582 21.3274H32.7298L32.8025 25.6889H31.5059V20.374H33.4629L34.6742 24.5905H34.7026Z"
      fill="white"
    />
    <path
      d="M10.6868 20.867L9.94241 25.6889H8.47437L9.48168 20.374H11.8994L12.9035 25.6889H11.4409L10.7172 20.867H10.6868Z"
      fill="white"
    />
    <path
      d="M16.9064 24.4372L17.5954 20.374H19.8697L19.9901 25.6889H18.5965L18.5597 20.9106H18.5308L17.5987 25.6889H16.184L15.2509 20.9106H15.2208L15.1862 25.6889H13.7893L13.9131 20.374H16.1885L16.8763 24.4372H16.9064Z"
      fill="white"
    />
    <path
      d="M6.21497 24.3002C6.26696 24.4269 6.25105 24.5903 6.22664 24.6887C6.18207 24.8626 6.06217 25.0406 5.70988 25.0406C5.37669 25.0406 5.17508 24.8521 5.17508 24.5651V24.0583H3.75106L3.75 24.4636C3.75 25.6313 4.68166 25.9842 5.68017 25.9842C6.64048 25.9842 7.43101 25.6606 7.55622 24.7872C7.62095 24.3347 7.57213 24.0384 7.55091 23.9263C7.32702 22.8298 5.31196 22.502 5.16234 21.8894C5.13688 21.7846 5.1443 21.6726 5.15704 21.6129C5.19418 21.4464 5.30984 21.262 5.64197 21.262C5.95181 21.262 6.13539 21.4516 6.13539 21.7375C6.13539 21.8339 6.13539 22.0611 6.13539 22.0611H7.45859V21.6935C7.45859 20.552 6.42082 20.374 5.66956 20.374C4.72516 20.374 3.95373 20.6819 3.81261 21.5343C3.77441 21.77 3.7691 21.9794 3.82428 22.2423C4.05666 23.3116 5.9412 23.6216 6.21497 24.3002Z"
      fill="white"
    />
    <path
      d="M23.6367 24.3021C23.6892 24.429 23.6721 24.5898 23.6485 24.6893C23.6046 24.8649 23.4846 25.0416 23.131 25.0416C22.7999 25.0416 22.5973 24.8512 22.5973 24.5676L22.5963 24.0598H21.1733L21.1711 24.4639C21.1711 25.6319 22.1034 25.9842 23.101 25.9842C24.06 25.9842 24.8508 25.6615 24.9761 24.7877C25.0415 24.3338 24.9954 24.0387 24.9719 23.9276C24.7468 22.8305 22.7334 22.5025 22.5834 21.8889C22.5577 21.7831 22.5652 21.6721 22.5781 21.6149C22.6166 21.4446 22.7313 21.2637 23.0635 21.2637C23.3742 21.2637 23.5553 21.4499 23.5553 21.7376C23.5553 21.8339 23.5553 22.0614 23.5553 22.0614H24.8818V21.6932C24.8818 20.5528 23.8425 20.374 23.0902 20.374C22.1473 20.374 21.3747 20.6808 21.2354 21.5366C21.1969 21.7715 21.1926 21.9789 21.2483 22.2433C21.4787 23.3129 23.3635 23.6239 23.6367 24.3021Z"
      fill="white"
    />
    <path
      d="M28.1117 25.0076C28.4905 25.0076 28.6084 24.7463 28.6346 24.6124C28.6456 24.5536 28.6488 24.4752 28.6477 24.4044V20.374H30.0288V24.2803C30.0321 24.3805 30.0223 24.5863 30.0168 24.6374C29.9197 25.6543 29.1139 25.9842 28.1106 25.9842C27.1061 25.9842 26.2993 25.6543 26.2032 24.6374C26.1989 24.5863 26.189 24.3794 26.1912 24.2803V20.374H27.5712V24.4034C27.5712 24.4741 27.5734 24.5536 27.5843 24.6113C27.6149 24.7452 27.7296 25.0076 28.1117 25.0076Z"
      fill="white"
    />
    <path
      d="M39.3242 25.0201C39.715 25.0201 39.8521 24.7747 39.8769 24.6323C39.8866 24.5712 39.8898 24.4962 39.8888 24.4287V23.651H39.335V22.8689H41.25V24.3077C41.2489 24.4084 41.2467 24.4823 41.2305 24.6612C41.1409 25.636 40.2882 25.9842 39.3317 25.9842C38.3732 25.9842 37.5226 25.636 37.4308 24.6612C37.4157 24.4823 37.4135 24.4084 37.4114 24.3077L37.4125 22.0516C37.4125 21.9562 37.4243 21.788 37.4351 21.6981C37.5549 20.6964 38.3732 20.374 39.3317 20.374C40.2892 20.374 41.128 20.6943 41.2273 21.6981C41.2446 21.8684 41.2392 22.0516 41.2392 22.0516V22.2305H39.878V21.9305C39.879 21.9316 39.8758 21.803 39.8607 21.727C39.838 21.6081 39.7344 21.336 39.322 21.336C38.928 21.336 38.8136 21.5931 38.7845 21.727C38.7683 21.7977 38.7618 21.893 38.7618 21.9798V24.4309C38.7607 24.4983 38.765 24.5733 38.7758 24.6344C38.7974 24.7747 38.9334 25.0201 39.3242 25.0201Z"
      fill="white"
    />
  </svg>
);

export default { comcast, samsung, yCombinator };
