// eslint-disable-next-line no-shadow
enum Palette {
  // old palette
  purple = 'rgb(94, 47, 216)',
  red = 'rgb(216, 47, 75)',
  yellowGold = 'rgb(181, 160, 117)',

  // new palette

  // primary
  blueNavy = 'rgb(12, 39, 58)',
  blue = 'rgba(47, 155, 216, 1)',
  green = 'rgb(29, 200, 128)',
  yellow = 'rgb(232, 174, 52)',
  orange = 'rgb(216, 92, 47)',
  poppingRed = 'rgb(229, 74, 100)', // to become 'red' in the future
  newPurple = 'rgb(117, 72, 243)', // to become 'purple' in the future

  // +1 variant
  blueSlate = 'rgb(67, 125, 157)',
  blueSky = '#44BCFF',
  greenMint = '#51F0A2',
  yellowButter = '#FFD47B',
  orangeCoral = '#F48B64',
  redFlamingo = '#FD7188',
  purpleLavender = '#9E7EEF',

  // -1 variant
  blueInk = 'rgb(3, 23, 37)',
  blueDenim = 'rgb(16, 78, 113)',
  greenPine = 'rgb(4, 120, 112)',
  yellowHoney = 'rgb(201, 128, 45)',
  orangeRust = 'rgb(123, 45, 15)',
  redCherry = 'rgb(128, 4, 40)',
  purpleGrape = 'rgb(69, 29, 171)',
  purpleIndigo = 'rgb(145, 70, 255)',

  // utilities
  blueGrey = 'rgb(220, 220, 220)',
  blueWhite = 'rgb(243, 243, 243)',
  white = 'rgb(255, 255, 255)',
  black = 'rgb(0, 0, 0)',
  transparent = 'transparent',

  // highlights
  blueHighlight = '#44BCFF',
  greenHighlight = '#A3D9D5',
  goldHighlight = '#E7C69E',
  purpleHighlight = '#CAB6FF',

  // brand colors
  twitch = '#9147FF',
}

export type ValidColor = Palette | 'currentcolor' | 'transparent';

export default Palette;
