import React from 'react';
import { Separator, StyledHR, SeparatorLabel } from './rule.styles';

const Rule = ({ label }: { label: string }) => (
  <Separator data-testid="Rule">
    <StyledHR />
    {label && <SeparatorLabel>{label}</SeparatorLabel>}
    <StyledHR />
  </Separator>
);

export default Rule;
