const { PL_ENV } = process.env;

type Environments = 'dev' | 'stage' | 'canary' | 'prod';

const deepLinkPrefixes: Record<Environments, string> = {
  dev: 'playersloungedev://',
  stage: 'playersloungestage://',
  canary: 'playerslounge://',
  prod: 'playerslounge://',
};

const env = PL_ENV || 'dev';

const deepLinkPrefix = deepLinkPrefixes[env as Environments];

const routes = [
  {
    regex: /\/checkout(?:[/?].*|)$/,
    analyticsName: 'checkout',
    isInternal: true,
    deepLink: `${deepLinkPrefix}deposit`,
  },
  {
    regex: /\/deposit(?:[/?].*|)$/,
    analyticsName: 'deposit',
    isInternal: true,
    deepLink: `${deepLinkPrefix}deposit`,
  },
  { regex: /\/faq(\?.*)?$/, analyticsName: 'faq', isInternal: true },
  { regex: /\/login(\?.*)?$/, analyticsName: 'login', isInternal: true },
  {
    regex: /\/lounge(\?.*)?$/,
    analyticsName: 'lounge',
    isInternal: true,
    deepLink: `${deepLinkPrefix}lounge`,
  },
  {
    regex: /\/onboarding(?:[/?].*|)$/,
    isInternal: true,
    analyticsName: 'onboarding',
  },
  { regex: /\/privacy(\?.*)?$/, isInternal: true, analyticsName: 'privacy' },
  { regex: /\/signup(\?.*)?$/, isInternal: true, analyticsName: 'signup' },
  { regex: /\/terms(\?.*)?$/, isInternal: true, analyticsName: 'terms' },
  {
    regex: /\/tournaments(?:[/?].*|)$/,
    isInternal: true,
    analyticsName: 'tournaments',
  },
  {
    regex: /\/user\/linked-accounts(\?.*)?$/,
    isInternal: true,
    analyticsName: 'user/linked-accounts',
    deepLink: `${deepLinkPrefix}user/linked-accounts`,
  },
  {
    regex: /\/subscription\/manage(\?.*)?$/,
    isInternal: true,
    analyticsName: 'subscription/manage',
    deepLink: `${deepLinkPrefix}user/subscriptions`,
  },
  {
    regex: /\/twitch\/(\w*\d*)(\?.*)?/,
    isInternal: true,
    analyticsName: 'twitch',
  },
  {
    regex: /\/twitch(\?.*)?$/,
    isInternal: true,
    analyticsName: 'all-streams',
  },
  {
    regex: /\/profile(\?.*)?$/,
    isInternal: false,
    analyticsName: 'own-profile',
    deepLink: `${deepLinkPrefix}profile`,
  },
  {
    regex: /\/messages(\?.*)?$/,
    isInternal: true,
    analyticsName: 'dms',
  },
  {
    regex: /\/profile\/(\w*\d*)(\?.*)?/,
    isInternal: false,
    analyticsName: 'profile',
    deepLink: `${deepLinkPrefix}profile/:regexGroup0`,
  },
  {
    regex: /\/matches\/(\w+|\d+).*/,
    isInternal: false,
    analyticsName: 'matches',
    deepLink: `${deepLinkPrefix}match/:regexGroup0`,
  },
  {
    regex: /\/user\/account(\?.*)?$/,
    isInternal: false,
    analyticsName: 'user/account',
    deepLink: `${deepLinkPrefix}user/settings`,
  },
  {
    regex: /\/user\/personal-details(\?.*)?$/,
    isInternal: false,
    analyticsName: 'user/personal-details',
    deepLink: `${deepLinkPrefix}user/personal-details`,
  },
  {
    regex: /\/user\/transaction-history(\?.*)?$/,
    isInternal: false,
    analyticsName: 'user/transaction-history',
    deepLink: `${deepLinkPrefix}user/transactions`,
  },
  {
    regex: /\/user\/gamertags(\?.*)?$/,
    isInternal: false,
    analyticsName: 'user/gamertags',
    deepLink: `${deepLinkPrefix}user/gamertags`,
  },
  {
    regex: /\/user\/game-settings(\?.*)?$/,
    isInternal: false,
    analyticsName: 'user/game-settings',
    deepLink: `${deepLinkPrefix}user/game-settings`,
  },
  {
    regex: /\/user\/email-notifications(\?.*)?$/,
    isInternal: false,
    analyticsName: 'user/email-notifications',
    deepLink: `${deepLinkPrefix}user/notifications/email`,
  },
  {
    regex: /\/user\/push-notifications(\?.*)?$/,
    isInternal: false,
    analyticsName: 'user/push-notifications',
    deepLink: `${deepLinkPrefix}user/notifications/push`,
  },
  {
    regex: /\/user\/change-password(\?.*)?$/,
    isInternal: false,
    analyticsName: 'user/change-password',
    deepLink: `${deepLinkPrefix}user/password`,
  },
  {
    regex: /\/subscribe(\?.*)?$/,
    isInternal: true,
    analyticsName: 'subscribe',
  },
  {
    regex: /\/kotl(\?.*)?$/,
    isInternal: true,
    analyticsName: 'kotl',
  },
  {
    regex: /\/subscription\/welcome(\?.*)?$/,
    isInternal: true,
    analyticsName: 'subscription/welcome',
  },
];

// SubscriptionManagement: 'user/subscriptions',

export default routes;
