import React from 'react';

const crown = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99998 4L10.3333 8.27655L12.6666 5.14278V12H3.33331V5.14278L5.66665 8.27655L7.99998 4Z"
      fill="#F6BB43"
    />
  </svg>
);

const live = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM6 12C6 8.688 8.682 6 11.994 6C15.312 6 18 8.688 18 12C18 15.312 15.312 18 11.994 18C8.682 18 6 15.312 6 12Z"
    />
  </svg>
);

const trophy = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 2C17.1 2 16 3 16 4H8C8 3 6.9 2 6 2H2V11C2 12 3 13 4 13H6.2C6.6 15 7.9 16.7 11 17V19.08C8 19.54 8 22 8 22H16C16 22 16 19.54 13 19.08V17C16.1 16.7 17.4 15 17.8 13H20C21 13 22 12 22 11V2H18ZM6 11H4V4H6V11ZM18 11H20V4H18V11Z"
    />
  </svg>
);

const trophyFilled = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="693"
    height="710"
    viewBox="0 0 693 710"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path d="M565.5 0H127.6V437.9H565.5V0Z" fill="#CEDEE8" />
      <path d="M439.7 626.3H253.3V650.1H439.7V626.3Z" fill="#CEDEE8" />
      <path d="M565.5 650.1H127.6V709.8H565.5V650.1Z" fill="#CEDEE8" />
      <path d="M387.4 544.5H305.6V626.3H387.4V544.5Z" fill="#CEDEE8" />
      <path
        d="M565.4 437.9L458.7 544.5H234.2L127.6 437.9H565.4Z"
        fill="#CEDEE8"
      />
      <path
        d="M129 352V401.3L0 272.3V164.6H127.6V206.4H51.3V272.3H51.6L129 352Z"
        fill="#CEDEE8"
      />
      <path
        d="M564 352V401.3L693 272.3V164.6H565.4V206.4H641.7V272.3H641.4L564 352Z"
        fill="#CEDEE8"
      />
      <path d="M565.5 650.1H127.6V686H565.5V650.1Z" fill="#CEDEE8" />
      <path d="M641.6 200.6H51.3V206.2H641.6V200.6Z" fill="#CEDEE8" />
      <path d="M565.5 665.2H127.6V670.8H565.5V665.2Z" fill="#CEDEE8" />
      <path d="M565.5 12H127.6V17.6H565.5V12Z" fill="#CEDEE8" />
      <path d="M187.9 110.2H157.2V393.3H187.9V110.2Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="693" height="709.8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const previouslyPlayed = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3 7.03 7.03 3 12 3C16.97 3 21 7.03 21 12C21 16.97 16.97 21 12 21C9.51 21 7.27 19.99 5.64 18.36L7.06 16.94C8.32 18.21 10.07 19 12 19C15.87 19 19 15.87 19 12C19 8.13 15.87 5 12 5C8.13 5 5 8.13 5 12H8L3.96 16.03L3.89 15.89L0 12H3ZM11 13V8H12.5V12.15L16.02 14.24L15.25 15.52L11 13Z"
    />
  </svg>
);

const fire = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7512 10.4666C16.9838 10.6982 17.2199 10.9333 17.4244 11.2L17.3744 11.21C17.7044 11.59 17.9744 12 18.1744 12.46L18.3044 12.72C19.2544 15 18.6044 17.66 16.8044 19.32C15.1944 20.8 12.9444 21.19 10.8444 20.92C8.87436 20.67 7.04436 19.43 6.00436 17.7C5.68436 17.17 5.42436 16.57 5.28436 15.97C5.11436 15.47 5.05436 14.97 5.01436 14.47C4.87436 12.3 5.76436 10 7.40436 8.64C6.65436 10.28 6.83436 12.33 7.93436 13.76C7.98436 13.83 8.02436 13.88 8.08436 13.93C8.26436 14.09 8.50436 14.15 8.72436 14.05C8.92436 13.97 9.07436 13.77 9.07436 13.55C9.07436 13.42 9.03436 13.32 8.99436 13.22C7.79436 10.07 8.79436 6.4 11.3344 4.32C12.0344 3.75 12.8744 3.23 13.7844 3C12.8744 4.85 13.1744 7.26 14.6344 8.72C14.9759 9.0615 15.3526 9.34423 15.7296 9.62712C16.0481 9.86612 16.3667 10.1052 16.6644 10.38C16.6932 10.4088 16.7222 10.4377 16.7512 10.4666ZM13.2444 18.1C13.5944 18 14.0444 17.74 14.3244 17.5H14.3144C14.9144 16.95 15.2644 16.05 15.2344 15.23C15.2344 15.08 15.2144 14.94 15.1744 14.8C15.0117 13.9151 14.4511 13.4197 13.8699 12.9061C13.5579 12.6304 13.2399 12.3494 12.9744 12C12.7444 11.7 12.5444 11.32 12.3744 10.94C12.0944 11.63 12.0744 12.26 12.1944 13C12.2317 13.221 12.2838 13.4329 12.3352 13.6417C12.4628 14.1604 12.5856 14.6596 12.4644 15.23C12.2644 16.12 11.5644 17 10.3744 17.28C11.0444 17.94 12.1444 18.5 13.2444 18.1Z"
    />
  </svg>
);

const veryHappy = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.99 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 11.99 22C17.51 22 22 17.53 22 12C22 6.47 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM14.06 11L13 9.94L15.12 7.82L17.24 9.94L16.18 11L15.12 9.94L14.06 11ZM8.88 9.94L9.94 11L11 9.94L8.88 7.82L6.76 9.94L7.82 11L8.88 9.94ZM17.11 14C16.31 16.04 14.33 17.5 12 17.5C9.67 17.5 7.69 16.04 6.89 14H17.11Z"
    />
  </svg>
);

const globe = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM4 12C4 11.39 4.08 10.79 4.21 10.22L8.99 15V16C8.99 17.1 9.89 18 10.99 18V19.93C7.06 19.43 4 16.07 4 12ZM15.99 16C16.89 16 17.63 16.59 17.89 17.4C19.19 15.98 20 14.08 20 12C20 8.65 17.92 5.77 14.99 4.59V5C14.99 6.1 14.09 7 12.99 7H10.99V9C10.99 9.55 10.54 10 9.99 10H7.99V12H13.99C14.54 12 14.99 12.45 14.99 13V16H15.99Z"
    />
  </svg>
);

const locked = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 9H18C19.1 9 20 9.9 20 11V21C20 22.1 19.1 23 18 23H6C4.9 23 4 22.1 4 21V11C4 9.9 4.9 9 6 9H7V7C7 4.24 9.24 2 12 2C14.76 2 17 4.24 17 7V9ZM12 4C10.34 4 9 5.34 9 7V9H15V7C15 5.34 13.66 4 12 4ZM6 21V11H18V21H6ZM14 16C14 17.1 13.1 18 12 18C10.9 18 10 17.1 10 16C10 14.9 10.9 14 12 14C13.1 14 14 14.9 14 16Z"
    />
  </svg>
);

const trusted = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="1.5em" height="1.5em" viewBox="-4 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0ZM16 10C16 14.52 13.02 18.69 9 19.93C4.98 18.69 2 14.52 2 10V5.3L9 2.19L16 5.3V10ZM3 12L4.41 10.59L7 13.17L13.59 6.58L15 8L7 16L3 12Z"
    />
  </svg>
);

const tickCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1.25em" height="1.25em" viewBox="2 2 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM10 14.17L16.59 7.58L18 9L10 17L6 13L7.41 11.59L10 14.17Z"
    />
  </svg>
);

const diamond = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11.75L0.5 5L2.75116 1.5H9.25L11.5 5L6 11.75ZM5 2.5H7L7.5 4.5H4.5L5 2.5ZM3.5 4.5L4 2.5H3L2 4.5H3.5ZM8 2.5L8.5 4.5H10L9 2.5H8ZM7.5 5.5L6 10.25L4.5 5.5H7.5ZM4.25 8L3.5 5.5H2L4.25 8ZM8.5 5.5L7.74774 8L10 5.5H8.5Z"
      fill="#0C273A"
    />
  </svg>
);

export default {
  crown,
  diamond,
  live,
  trophy,
  trophyFilled,
  previouslyPlayed,
  fire,
  veryHappy,
  globe,
  locked,
  trusted,
  tickCircle,
};
