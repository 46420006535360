import styled from 'styled-components';
import { Iconography, TOUCHABLE_STYLE, fontL, fontS } from 'components/styles';
import Surface from 'components/atoms/surface';

export const StyledSurface = styled(Surface)`
  margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const HeaderWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  cursor: pointer;

  ${TOUCHABLE_STYLE}
`;

export const SectionHeading = styled.h2`
  ${fontL}
  color: ${({ theme }) => theme.new.content};
`;

export const FullRules = styled.div`
  ${fontS}
  color: ${({ theme }) => theme.new.content};
  padding-top: ${({ theme }) => theme.spacing.QUARTER_BASE_SPACING};
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};

  p {
    margin-top: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  }
  ul > li {
    list-style-type: disc;
    margin-left: ${({ theme }) => theme.spacing.customSpacing('18px')};
  }
`;

export const ContentWrapper = styled.div<{ isOpened: boolean }>`
  overflow: hidden;
  max-height: ${({ isOpened }) => (isOpened ? '5000px' : '0px')};
  transition: max-height 600ms ease-in-out;
`;

export const IconWrapper = styled(Iconography)<{ isOpened: boolean }>`
  transition: transform 300ms ease-in-out;
  transform: ${({ isOpened }) =>
    isOpened ? 'rotate(180deg)' : 'rotate(0deg)'};
`;
