import { DISABLED_STATE, fontS, fontXL } from 'components/styles';
import { ValidColor } from 'components/styles/colors';
import styled from 'styled-components';

export const EntryFeeInputContainer = styled.div<{ disabled: boolean }>`
  flex-grow: 1;
  ${DISABLED_STATE}
`;

export const EntryFeeLabel = styled.label<{
  background: ValidColor;
  error: boolean;
}>`
  ${fontS}
  color: ${({ theme, error }) =>
    error ? theme.new.error.background : theme.new.content};
  background-color: ${({ background }) => background};
  padding-right: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  transition: color 100ms;
`;

export const EntryFeeWrapper = styled.div<{
  background: ValidColor;
  error: boolean;
  highContrast: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, error, highContrast }) => {
    if (error) return theme.new.error.background;
    if (highContrast) return theme.new.content;
    return theme.new.divisions;
  }};
  padding: ${({ theme }) => theme.spacing.BASE_SPACING};

  margin-top: -${({ theme }) => theme.spacing.customSpacing('10px')};
  background-color: ${({ background }) => background};
  ${fontXL}
  color: ${({ theme, error }) =>
    error ? theme.new.error.background : theme.new.content};
  transition: color 100ms;
`;

export const StyledInput = styled.input<{
  background: ValidColor;
  error: boolean;
}>`
  position: relative;
  width: 100%;
  ${fontXL}
  background-color: ${({ background }) => background};
  color: ${({ theme, error }) =>
    error ? theme.new.error.background : theme.new.content};
  border: none;
  outline: none;
  text-align: left;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    width: 0px;
  }
  -moz-appearance: textfield;
  :caret-style: auto;

  transition: color 100ms;
`;
