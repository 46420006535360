import { useEffect } from 'react';

/**
 * Disables the background scrolling based on a true (hidden) / false (auto) return
 */
const useDisableScrollbar = (
  callback: (val: number) => boolean,
  dependencies: unknown[] = [],
) => {
  useEffect(() => {
    const handleResize = () => {
      const disableCondition = callback(window?.innerWidth);

      if (disableCondition) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return null;
};

export default useDisableScrollbar;
