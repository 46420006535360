import { ON_SERVER } from 'helpers';
import * as events from './events';
import * as sources from './sources';

const { PL_VERSION } = process.env;

type Analytics = {
  event: string;
  source: string;
  destination?: string;
  opensNewTab?: string;
};

export type LinkAnalyticsObject = {
  event: string;
  properties: {
    source: string;
    origin: string;
    platform: 'web';
    'pl-version': string;
    destination?: string;
    opensNewTab?: string;
  };
} | null;

const getAnalyticsObject = ({
  event,
  source,
  ...rest
}: Analytics): LinkAnalyticsObject => {
  if (ON_SERVER) return null;

  if (!Object.values(events).find((val) => val === event))
    throw new Error(
      `Analytics event "${event}" is not a defined analytics event.`,
    );

  if (!Object.values(sources).find((val) => val === source))
    throw new Error(
      `Analytics source "${source}" is not a defined analytics source.`,
    );

  return {
    event: `web-${event}-${source}`,
    properties: {
      source: `web-${source}`,
      origin: window.location.href,
      ...rest,
      'pl-version': PL_VERSION || 'unknown',
      platform: 'web',
    },
  };
};

export default getAnalyticsObject;
