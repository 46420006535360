import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { fontM, fontS, GlobalColors, smallShadow } from 'components/styles';
import Link from 'components/atoms/link';
import SVG from 'svgs';

type ToastType = 'info' | 'success' | 'error' | 'warning';
type ToastPosition = 'top' | 'bottom';

const BannerWrapper = styled.div<{ type: GlobalColors }>`
  color: ${({ theme }) => theme.new.information.text};
  background-color: ${({ theme, type }) => theme.new[type].background};
  padding: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

const BannerHeader = styled.div`
  ${fontM}
  margin-bottom: ${({ theme }) => theme.spacing.customSpacing('4px')};
`;

export const BannerContainer = ({
  children,
  type,
}: PropsWithChildren<{ type: ToastType }>) => {
  return (
    <BannerWrapper type={type === 'info' ? 'information' : type}>
      {children}
    </BannerWrapper>
  );
};

export const Banner = ({
  type = 'info',
  header,
  children,
}: PropsWithChildren<{
  type?: ToastType;
  header?: string | React.ReactNode;
}>) => {
  return (
    <BannerWrapper
      data-testid="banner"
      type={type === 'info' ? 'information' : type}
    >
      <BannerHeader>{header}</BannerHeader>
      {children}
    </BannerWrapper>
  );
};

export const CloseButtonSVG = styled(SVG)`
  fill: ${({ theme }) => theme.new.information.text};
  grid-row: 1;
  grid-column: 2;
  margin-left: ${({ theme }) => theme.spacing.customSpacing('8px')};
`;

const Subtext = styled.span`
  ${fontS};
`;

const DismissableArea = styled.button`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  background-color: transparent;
`;

const ToastButton = styled.div<{ type: GlobalColors; solid?: boolean }>`
  padding: ${({ theme }) =>
    `${theme.spacing.customSpacing('4px')} ${theme.spacing.customSpacing(
      '15px',
    )}`};
  border-radius: ${({ theme }) => theme.spacing.customSpacing('20px')};
  color: ${({ theme, solid, type }) =>
    solid ? theme.new[type].background : theme.new[type].text};
  margin-right: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  ${fontS};
  background-color: ${({ theme, solid }) =>
    solid ? theme.new.information.text : 'transparent'};
  border: ${({ theme, solid }) =>
    !solid && `1px solid ${theme.new.information.text}`};
  cursor: pointer;
  position: relative;
  z-index: 2;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

const ToastWrapper = styled.div<{ position: ToastPosition }>`
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: ${({ theme }) => theme.depth.TOAST};

  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  position: fixed;
  left: 0;
  right: 0;
  bottom: ${({ theme, position = 'bottom' }) =>
    position === 'bottom' ? theme.spacing.BASE_SPACING : 'unset'};
  top: ${({ theme, position = 'bottom' }) =>
    position === 'top' ? theme.spacing.customSpacing('64px') : 'unset'};
`;

const ToastCard = styled.div<{ type: GlobalColors; position: ToastPosition }>`
  width: 100%;
  max-width: ${({ theme }) => theme.spacing.customSpacing('500px')};

  color: ${({ theme }) => theme.new.information.text};
  background-color: ${({ theme, type }) => theme.new[type].background};
  padding: ${({ theme }) => theme.spacing.BASE_SPACING};
  margin-left: ${({ theme }) => theme.spacing.BASE_SPACING};
  margin-right: ${({ theme }) => theme.spacing.BASE_SPACING};

  border-radius: ${({ theme }) => theme.spacing.HALF_BASE_SPACING};
  animation: fromBottom 0.25s ease-in;
  ${smallShadow};

  @keyframes fromBottom {
    0% {
      transform: ${({ theme, position }) =>
        `translateY(${theme.spacing.customSpacing(
          position === 'top' ? '-200px' : '200px',
        )})`};
    }
    100% {
      transform: translate(0%);
    }
  }
`;

export type BaseToastData = {
  type?: ToastType;
  heading?: string | React.ReactNode;
  subtext?: string | React.ReactNode;
  displayOptions?: boolean;
  firstBtnOnClick?: () => void;
  firstBtnHref?: string;
  firstBtnText?: string;
  secondBtnOnClick?: () => void;
  secondBtnText?: string;
  secondBtnHref?: string;
  children?: React.ReactNode;
  clickToDismiss?: boolean;
  closeButton?: boolean;
  position?: ToastPosition;
};

type ToastProps = {
  dismissToast: () => void;
  toastData: BaseToastData;
};

export const Toast = ({
  dismissToast,
  toastData: {
    type = 'info',
    heading,
    subtext,
    displayOptions = false,
    firstBtnOnClick,
    firstBtnHref,
    firstBtnText,
    secondBtnOnClick,
    secondBtnText,
    secondBtnHref,
    children,
    clickToDismiss,
    closeButton,
    position = 'bottom',
  } = {},
}: ToastProps) => {
  const properType = type === 'info' ? 'information' : type;
  return (
    <ToastWrapper data-testid="global-toast" position={position}>
      <ToastCard type={properType} position={position}>
        {clickToDismiss && (
          <DismissableArea
            data-testid="global-toast-dismissable-area"
            onClick={dismissToast}
          />
        )}

        {heading && <BannerHeader>{heading}</BannerHeader>}
        {subtext && <Subtext>{subtext}</Subtext>}
        {children}
        {displayOptions && (
          <ButtonWrapper>
            {!firstBtnHref && firstBtnText && (
              <ToastButton
                role="button"
                data-testid="global-toast-first-btn"
                onClick={firstBtnOnClick}
                type={properType}
                solid
              >
                {firstBtnText}
              </ToastButton>
            )}
            {firstBtnHref && firstBtnText && (
              <Link href={firstBtnHref}>
                <ToastButton
                  role="button"
                  data-testid="global-toast-first-btn"
                  onClick={firstBtnOnClick}
                  type={properType}
                  solid
                >
                  {firstBtnText}
                </ToastButton>
              </Link>
            )}
            {!secondBtnHref && secondBtnText && (
              <ToastButton
                role="button"
                data-testid="global-toast-second-btn"
                onClick={secondBtnOnClick}
                type={properType}
              >
                {secondBtnText}
              </ToastButton>
            )}
            {secondBtnHref && secondBtnText && (
              <Link href={secondBtnHref}>
                <ToastButton
                  role="button"
                  data-testid="global-toast-second-btn"
                  onClick={secondBtnOnClick}
                  type={properType}
                >
                  {secondBtnText}
                </ToastButton>
              </Link>
            )}
          </ButtonWrapper>
        )}
        {closeButton && (
          <CloseButtonSVG
            data-testid="global-toast-close-btn"
            onClick={dismissToast}
            icon="close"
            tab-index="-1"
            role="img"
          />
        )}
      </ToastCard>
    </ToastWrapper>
  );
};
