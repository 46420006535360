import React, { MouseEventHandler } from 'react';
import Link from 'components/atoms/link';
import styled from 'styled-components';
import { TOUCHABLE_STYLE, fontS, fontSContrast } from 'components/styles';
import { messageParent } from 'helpers/web-view';

const InitialP = styled.p`
  ${fontS}
  color: ${({ theme }) => theme.new.error.text};
  :hover {
    text-decoration: underline;
  }
`;

const StyledP = styled.p`
  ${fontSContrast}
  color: ${({ theme }) => theme.new.error.text};
  ${TOUCHABLE_STYLE}
`;

const StyledLink = styled(Link)`
  ${TOUCHABLE_STYLE}
`;

const errorMessagesExpected = [
  'Before you can continue, please tell us which network (PSN Network / XBox gamertag) you are using with this game on the account settings page',
  'Before you can continue, please add the PSN Network or XBox gamertag you are using with this game on the account settings page',
];

const isMLBPlatformError = (message: string) =>
  Boolean(errorMessagesExpected.find((expected) => expected === message));

const MlbPlatformModal = ({
  onClick,
}: {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}) => {
  return (
    <StyledLink
      href="/user/gamertags"
      openInNewTab
      onClick={(e) => {
        messageParent({
          action: 'NAVIGATE',
          source: 'mlb-platform-modal',
          data: {
            url: '/user/gamertags',
          },
        });
        onClick?.(e);
      }}
    >
      <InitialP>
        You need to let us know what network you play MLB on (Xbox or
        PlayStation)
      </InitialP>
      <StyledP>Click here to set your network</StyledP>
    </StyledLink>
  );
};

export default MlbPlatformModal;
export { isMLBPlatformError };
