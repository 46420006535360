import React from 'react';
import SVG from 'svgs';
import Link from 'components/atoms/link';
import { SocialBar } from 'components/molecules/social';
import { AvailableIcons, Iconography } from 'components/styles';
import FooterData from './footer-data';
import {
  FooterContainer,
  FooterContentContainer,
  UpperFooterContainer,
  SSL,
  SSLText,
  LowerFooterContainer,
  MobileOnlyFooterContainer,
  PaymentsMethodContainer,
  LowerFooterTitleContainer,
  LowerFooterPaymentsHolder,
  FooterLink,
  LowerFooterTitle,
  LowerFooterDescription,
  FooterSVG,
  PaymentSVG,
  LowerFooterContentContainer,
} from './footer.styles';

export const MobileOnlyFooter = () => (
  <MobileOnlyFooterContainer data-testid="mobile-footer">
    <Link href="https://apps.apple.com/gb/app/players-lounge/id1474870497">
      <FooterSVG icon="appStore" />
    </Link>
  </MobileOnlyFooterContainer>
);

export const UpperFooter = () => (
  <UpperFooterContainer>
    <SSL>
      <Iconography name={AvailableIcons.LOCK} />
      <SSLText>SSL Secured</SSLText>
    </SSL>
    <SocialBar profiles={FooterData.social} />
  </UpperFooterContainer>
);

export const FooterContent = () => (
  <FooterContentContainer data-testid="footer-content">
    {FooterData.footer.links.map((value) => (
      <div key={value.text}>
        <FooterLink
          data-testid="footer-link"
          href={value.href ? value.href : '#'}
        >
          {value.text}
        </FooterLink>
      </div>
    ))}
  </FooterContentContainer>
);

export const LowerFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <LowerFooterContainer>
      <LowerFooterContentContainer>
        <LowerFooterTitleContainer>
          <LowerFooterTitle>
            &#169; {currentYear} Players&apos; Lounge. All rights reserved.
          </LowerFooterTitle>
          <LowerFooterPaymentsHolder>
            <PaymentSVG icon="paymentMethods" />
          </LowerFooterPaymentsHolder>
        </LowerFooterTitleContainer>
        <LowerFooterDescription>
          {FooterData.footer.disclaimer}
        </LowerFooterDescription>
      </LowerFooterContentContainer>
      <PaymentsMethodContainer>
        <SVG icon="paypal" />
        <SVG icon="visa" />
        <SVG icon="mastercard" />
        <SVG icon="americanExpress" />
      </PaymentsMethodContainer>
    </LowerFooterContainer>
  );
};

const Footer = ({ authenticated }: { authenticated: boolean }) => (
  <FooterContainer authenticated={authenticated}>
    <MobileOnlyFooter />
    <UpperFooter />
    <FooterContent />
    <LowerFooter />
  </FooterContainer>
);

export default Footer;
