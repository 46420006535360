import React, { useState, useEffect, useRef } from 'react';
import { DropdownItem, Tag } from 'components/atoms';
import { T } from 'react-polyglot-hooks';
import Avatar from 'components/atoms/avatar';
import PlPlusPill from 'components/atoms/pl-plus-pill';
import {
  closeOnClickOutside,
  clearAuthToken,
  clearPusherSocket,
} from 'helpers';
import { useUser } from 'components/util/user-context';
import getAnalyticsObject, {
  GLOBAL_HEADER_REFERRER_LINK,
  PHP_LINK,
} from 'analytics';
import SVG, { SVGImages } from 'svgs';
import Palette from 'components/styles/colors';
import OpenToChallenges from 'components/molecules/open-to-challenges';
import { UserFragment } from 'graphpl/core';
import {
  TagWrapper,
  AddFundsPill,
  StyledUsername,
  StyledPicker,
  StyledList,
  LobbyText,
} from './index.styles';

const plPlusEnabled = process.env.PL_PLUS_ENABLED === 'true';

const referralAnalytics = getAnalyticsObject({
  event: PHP_LINK,
  source: GLOBAL_HEADER_REFERRER_LINK,
});

type ListPickerProps = {
  hamburgerMenu?: boolean;
  avatar?: string;
  user: UserFragment | null;
  isMenuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
  className?: string;
};

const ListPicker = React.forwardRef<HTMLDivElement, ListPickerProps>(
  ({ hamburgerMenu, avatar, user, isMenuOpen, setMenuOpen, ...props }, ref) => {
    const arrow = {
      disableArrow: hamburgerMenu,
      drawArrowOnLeft: !hamburgerMenu,
    };

    const username = user?.username || 'Username';
    const hasPlPlus = user?.usernameFlairs?.hasPlPlus || false;

    return (
      <StyledPicker
        invertArrow={isMenuOpen}
        isMenuOpen={isMenuOpen}
        onClick={() => setMenuOpen(!isMenuOpen)}
        forwardedRef={ref}
        {...arrow}
        {...props}
      >
        {hamburgerMenu ? (
          <SVG icon="menu" fill={Palette.white} />
        ) : (
          <>
            <StyledUsername username={username} animated={hasPlPlus} />
            <Avatar avatar={avatar} size="smaller" />
          </>
        )}
      </StyledPicker>
    );
  },
);

ListPicker.displayName = 'ListPicker';

type UserHeaderDropdownMenuProps = {
  intercomOnClick?: () => void;
  hamburgerMenu?: boolean;
  className?: string;
};

const UserHeaderDropdownMenu = ({
  intercomOnClick = () => {
    // @ts-ignore
    window.intercomSettings.hide_default_launcher = false;
    // @ts-ignore
    window.Intercom('boot', window.intercomSettings);
    // @ts-ignore
    window.Intercom('show');
  },
  hamburgerMenu,
  className,
}: UserHeaderDropdownMenuProps) => {
  const { user } = useUser();

  const lobbyString = `${user?.selectedLobby?.gameSeries?.name} | ${user?.selectedLobby?.consoleSetting?.displayName}`;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const listRef = useRef(null);
  const pickerRef = useRef(null);

  type DropdownContentProps = {
    phrase: string;
    icon: SVGImages;
  };

  const DropdownContent = ({ phrase, icon }: DropdownContentProps) => (
    <>
      <SVG icon={icon} fill={Palette.blueNavy} />
      <span>
        <T phrase={phrase} />
      </span>
    </>
  );

  useEffect(
    () =>
      closeOnClickOutside({
        setState: setMenuOpen,
        buttonRef: pickerRef,
        contentRef: listRef,
      }),
    [listRef, pickerRef, isMenuOpen],
  );

  type ListItemsProps = React.HTMLAttributes<HTMLDivElement>;

  const ListItems = React.forwardRef<HTMLDivElement, ListItemsProps>(
    (props, ref) => {
      return (
        isMenuOpen && (
          <StyledList
            prefixId="user-header"
            isOpen={isMenuOpen}
            forwardedRef={ref}
            {...props}
          >
            <DropdownItem
              href="/deposit?ds=global_nav_dropdown"
              disableHoverState
            >
              <AddFundsPill purpose="success" size="medium">
                <T phrase="addFunds" />
              </AddFundsPill>
            </DropdownItem>
            {plPlusEnabled && !user?.hasPlPlus && (
              <DropdownItem
                href="/subscribe?source=global_nav_dropdown"
                disableHoverState
                removeTopBorder
              >
                <PlPlusPill purpose="success" size="medium">
                  <T phrase="subscribeToPLPlus" />
                </PlPlusPill>
              </DropdownItem>
            )}
            <DropdownItem disableHoverState removeTopBorder>
              <OpenToChallenges />
            </DropdownItem>
            <DropdownItem href="/lounge" isNewSection>
              <DropdownContent phrase="home" icon="home" />
              <LobbyText>{lobbyString}</LobbyText>
            </DropdownItem>
            <DropdownItem href="/profile" isNewSection>
              <DropdownContent phrase="profile" icon="player" />
            </DropdownItem>
            <DropdownItem href="/games">
              <DropdownContent phrase="myMatches" icon="live" />
            </DropdownItem>
            <DropdownItem href="/withdraw" isNewSection>
              <DropdownContent phrase="withdraw" icon="wallet" />
            </DropdownItem>
            <DropdownItem href="/user/transactions">
              <DropdownContent
                phrase="transactionHistory"
                icon="previouslyPlayed"
              />
            </DropdownItem>
            <DropdownItem href="/user/account" isNewSection>
              <DropdownContent phrase="accountSettings" icon="settings" />
            </DropdownItem>
            <DropdownItem href="/subscription/manage" isNewSection>
              <DropdownContent phrase="plPlus.title" icon="plPlusIcon" />
            </DropdownItem>
            <DropdownItem href="/user/linked-accounts" isNewSection>
              <DropdownContent phrase="linkedAccounts" icon="linkedAccounts" />
            </DropdownItem>
            <DropdownItem
              href="/user/referrals"
              isNewSection
              analytics={referralAnalytics}
            >
              <DropdownContent phrase="referFriends" icon="groupAdd" />
              <TagWrapper>
                <Tag tag="earnCash">
                  <T phrase="earnCash" />
                </Tag>
              </TagWrapper>
            </DropdownItem>
            <DropdownItem href="/user/rewards" isNewSection>
              <DropdownContent phrase="rewardsStore" icon="wallet" />
              <TagWrapper>
                <Tag tag="earnCash">
                  <T phrase="redeemRewards" />
                </Tag>
              </TagWrapper>
            </DropdownItem>
            <DropdownItem href="/faq" isNewSection>
              <DropdownContent phrase="faq" icon="rules" />
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                intercomOnClick();
                setMenuOpen(false);
              }}
              isNewSection
            >
              <DropdownContent phrase="support" icon="veryHappy" />
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                clearPusherSocket();
                // @ts-ignore
                window.intercomSettings.hide_default_launcher = true;
                // @ts-ignore
                window.Intercom('update', window.intercomSettings);
                clearAuthToken();
              }}
              isNewSection
            >
              <DropdownContent phrase="logout" icon="cancelMatch" />
            </DropdownItem>
          </StyledList>
        )
      );
    },
  );

  ListItems.displayName = 'ListItems';

  return (
    <>
      <ListPicker
        avatar={
          user?.avatar || 'https://images.playerslounge.co/img/outline.png'
        }
        className={className}
        ref={pickerRef}
        hamburgerMenu={hamburgerMenu}
        user={user}
        isMenuOpen={isMenuOpen}
        setMenuOpen={setMenuOpen}
      />
      <ListItems ref={listRef} />
    </>
  );
};

export default UserHeaderDropdownMenu;
