import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  LobbiesFragment,
  LobbyLookupDocument,
  LobbyLookupQueryResult,
} from 'graphpl/core';

export const getServerLobbyData = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  { consoleSetting, gameSeries }: LobbiesFragment,
): Promise<LobbyLookupQueryResult | undefined> => {
  let serverLobbyData: LobbyLookupQueryResult | undefined;

  try {
    const { data } = await apolloClient.query<LobbyLookupQueryResult>({
      query: LobbyLookupDocument,
      variables: {
        lookup: {
          consoleSettingId: consoleSetting?.id || '',
          gameSeriesId: gameSeries?.id || '',
          isMultiplay: false,
        },
        filter: { matchmakingEnabled: true },
      },
    });

    serverLobbyData = data;
  } catch (e) {
    return;
  }

  return serverLobbyData;
};
