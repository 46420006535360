import * as events from './events';

const { PL_VERSION } = process.env;

type Analytics = {
  event: string;
  source: string;
  disablePrefix: boolean;
};

const trackAnalyticsEvent = ({
  event,
  source,
  disablePrefix,
  ...rest
}: Analytics): void => {
  if (!event)
    throw new Error(
      `No analytics event name was provided to trackAnalyticsEvent`,
    );
  if (!source)
    throw new Error(`No analytics source was provided to trackAnalyticsEvent`);

  if (!Object.values(events).find((val) => val === event))
    throw new Error(
      `Analytics event "${event}" is not a defined analytics event.`,
    );

  const properties = {
    source: disablePrefix ? source : `web-${source}`,
    origin: window.location.href,
    ...rest,
    'pl-version': PL_VERSION,
    platform: 'web',
  };

  // @ts-ignore
  global?.analytics?.track?.(event, properties);
};

export default trackAnalyticsEvent;
