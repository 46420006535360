import { css } from 'styled-components';

export const TOUCHABLE_STYLE = css`
  opacity: 1;
  transition: opacity 0.3s;
  @media (any-hover: hover) {
    :hover:focus:not([disabled]) {
      opacity: 0.4;
    }
    :hover:not([disabled]) {
      opacity: 0.8;
    }
  }
`;
