import React, { PropsWithChildren, createContext, useContext } from 'react';
import { ConsolesFragment, GamesFragment, LobbiesFragment } from 'graphpl/core';
import { FeaturedStreamFragment, FeatureFlagFragment } from 'graphpl/cms';
import cmsCacheConfig from './cache/cms-config.json';
import coreCacheConfig from './cache/core-config.json';
import { mapFeatureFlags } from './utilities/map-feature-flags';
import { featureFlagFunc } from './utilities/feature-flag-func';
import { featuredStreamFunc } from './utilities/featured-stream';

type GlobalContextType = {
  lobbies: LobbiesFragment[];
  games: GamesFragment[];
  consoles: ConsolesFragment[];
  featureFlags: Record<string, FeatureFlagFragment>;
  featureFlag: (flag: string) => FeatureFlagFragment;
  featuredStreams: FeaturedStreamFragment[];
  featuredStream: (gameSeries: string) => FeaturedStreamFragment | undefined;
};

const initialAllFeatureFlags = mapFeatureFlags(
  cmsCacheConfig.featureFlags as FeatureFlagFragment[],
);

const initialFeatureFlag = featureFlagFunc(initialAllFeatureFlags);

const initialFeaturedStream = featuredStreamFunc(
  cmsCacheConfig.featuredStreams as FeaturedStreamFragment[],
);

export const GlobalContext = createContext<GlobalContextType>({
  lobbies: coreCacheConfig.lobbies as LobbiesFragment[],
  games: coreCacheConfig.games as GamesFragment[],
  consoles: coreCacheConfig.consoles as ConsolesFragment[],
  featureFlags: initialAllFeatureFlags,
  featureFlag: initialFeatureFlag,
  featuredStreams: cmsCacheConfig.featuredStreams as FeaturedStreamFragment[],
  featuredStream: initialFeaturedStream,
});

export const GlobalProvider = ({
  mockLobbies,
  mockGames,
  mockConsoles,
  mockFeatureFlags,
  children,
}: PropsWithChildren<{
  mockLobbies?: LobbiesFragment[];
  mockGames?: GamesFragment[];
  mockConsoles?: ConsolesFragment[];
  mockFeatureFlags?: FeatureFlagFragment[];
}>) => {
  const allFeatureFlags = mapFeatureFlags(
    mockFeatureFlags || (cmsCacheConfig.featureFlags as FeatureFlagFragment[]),
  );

  const featureFlag = featureFlagFunc(allFeatureFlags);

  const featuredStream = featuredStreamFunc(
    cmsCacheConfig.featuredStreams as FeaturedStreamFragment[],
  );

  return (
    <GlobalContext.Provider
      value={{
        lobbies: mockLobbies || (coreCacheConfig.lobbies as LobbiesFragment[]),
        games: mockGames || (coreCacheConfig.games as GamesFragment[]),
        consoles:
          mockConsoles || (coreCacheConfig.consoles as ConsolesFragment[]),
        featureFlags: allFeatureFlags,
        featureFlag,
        featuredStreams: cmsCacheConfig.featuredStreams as FeaturedStreamFragment[],
        featuredStream,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => useContext(GlobalContext);
