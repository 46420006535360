import { useEffect, useRef } from 'react';

const useInterval = (callback: () => void, delay = 1000) => {
  const memoisedCallback = useRef<() => void>(() => {});

  useEffect(() => {
    memoisedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    // @ts-ignore
    const handler = (...args) => memoisedCallback.current(...args);
    const id = setInterval(handler, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
