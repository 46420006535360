import { GamesFragment, UserFragment } from 'graphpl/core';

type GetDefaultGameForSeriesProps = {
  games: GamesFragment[];
  user: UserFragment | null;
};

const defaultGameObject: GamesFragment = {
  __typename: 'Game',
  id: 'madden25',
  name: 'Madden 25',
  gameSeriesId: 'madden',
  consoles: ['ps5', 'xboxX'],
  enabled: true,
  directChallengeEnabled: true,
  matchmakingEnabled: true,
  tournamentsEnabled: true,
  gameFormats: [
    {
      __typename: 'GameFormat',
      description: 'NFL Teams | 4 min quarters | All Madden difficulty',
      descriptors: ['NFL Teams', '4 min quarters', 'All Madden difficulty'],
      enabled: true,
      key: 'lounge',
      name: 'Regular teams',
      teamSizes: [1],
    },
    {
      __typename: 'GameFormat',
      description: 'Ultimate Team | 4 min quarters | All Madden difficulty',
      descriptors: ['Ultimate Team', '4 min quarters', 'All Madden difficulty'],
      enabled: true,
      key: 'mut',
      name: 'MUT',
      teamSizes: [1],
    },
    {
      __typename: 'GameFormat',
      description: 'Ultimate Team | Weekend League | H2H Seasons',
      descriptors: ['Ultimate Team', 'Weekend League', 'H2H Seasons'],
      enabled: true,
      key: 'plc_mut',
      name: 'Solo Bet: MUT',
      teamSizes: [1],
    },
  ],
  rules: [],
};

export const getDefaultGame = ({
  games,
  user,
}: GetDefaultGameForSeriesProps): GamesFragment => {
  const UserLobby = user?.selectedLobby;

  if (!UserLobby) return defaultGameObject;

  const selectedLobby = UserLobby;

  const defaultGameSeriesId =
    selectedLobby?.gameSeries?.id || defaultGameObject.id;

  const currentGame = games.find(
    ({ gameSeriesId }) => gameSeriesId === defaultGameSeriesId,
  );

  return currentGame || defaultGameObject;
};

type GetGameProps = {
  games: GamesFragment[];
};

export const getGame = (
  gameString: string,
  games: GamesFragment[],
): GamesFragment => {
  return games.find(({ id }) => id === gameString) || defaultGameObject;
};

export const getDCEnabledGames = ({ games }: GetGameProps) => {
  return games
    .filter(
      ({ enabled, directChallengeEnabled }) =>
        directChallengeEnabled && enabled,
    )
    .map(({ id, name }) => ({
      value: id as string,
      name: name as string,
    }));
};

export const getMMEnabledGames = ({ games }: GetGameProps) => {
  return games
    .filter(({ enabled, matchmakingEnabled }) => matchmakingEnabled && enabled)
    .map(({ id, name }) => ({
      value: id as string,
      name: name as string,
    }));
};
