/* istanbul ignore file */
import React, { PropsWithChildren, useState } from 'react';
import { useDisableScrollbar } from 'helpers';
import Palette from 'components/styles/colors';
import { MAX_PHONE_PX } from 'components/styles';
import {
  DropdownListWrapper,
  DropdownBackdrop,
  ItemSideElement,
  DropdownSubMenuHeader,
  DropdownSubListWrapper,
  BackSVG,
} from './dropdown-list.styles';
import DropdownItem from '../item';

let prevItemDisableHover: boolean;

const filterDropdownSubMenuFromChildren = (childItem: React.ReactNode) => {
  // @ts-ignore
  return React.Children.toArray(childItem?.props?.children)?.filter(
    (subItem) => {
      // @ts-ignore
      if (!subItem?.type) return true;
      // @ts-ignore
      return subItem?.type.displayName !== 'DropdownSubMenu';
    },
  );
};

type SubMenuGeneratorProps = {
  item: React.ReactNode;
  index: number;
  openId?: number | null;
  key: string;
  onClick?: (index: number) => void;
  drawnItem: React.ReactNode;
};

const subMenuGenerator = ({
  item,
  index,
  openId,
  key,
  onClick,
  drawnItem,
}: SubMenuGeneratorProps) =>
  // @ts-ignore
  React.Children.map(item?.props?.children, (subItem) => {
    if (subItem?.type?.displayName !== 'DropdownSubMenu') return null;

    const returnData = React.Children.toArray(subItem.props.children);

    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <DropdownSubMenu
        isOpen={index === openId || subItem.props.isOpen}
        prefixId={key}
      >
        {/* @ts-ignore */}
        <DropdownSubMenuHeader onClick={onClick} isNewSection isSubMenuHeader>
          <BackSVG icon="chevronLeft" fill={Palette.blueSlate} /> {drawnItem}
        </DropdownSubMenuHeader>
        {returnData}
      </DropdownSubMenu>
    );
  });

type DrawDropdownChildren = {
  items: React.ReactNode;
  prefixId: string;
  onFocus?: (index: number) => void;
  onClick?: (index: number) => void;
  openId?: number | null;
  hoveringId: number | null;
};

const drawDropdownChildren = ({
  items,
  prefixId,
  onFocus,
  onClick,
  openId,
  hoveringId,
}: DrawDropdownChildren) =>
  React.Children.map(items, (item, index) => {
    const key = `${prefixId}-${index}`;

    const drawnItem = React.Children.map(
      item,
      filterDropdownSubMenuFromChildren,
    );

    const subMenuItem = subMenuGenerator({
      item,
      index,
      openId,
      key,
      drawnItem,
    });

    let itemNoBorder = index - 1 === hoveringId;
    if (prevItemDisableHover) {
      itemNoBorder = index - 1 === hoveringId && !prevItemDisableHover;
    }
    // @ts-ignore
    prevItemDisableHover = item?.props?.disableHoverState;

    // @ts-ignore
    const onClickHandler = item?.props?.containsSubMenu
      ? () => onClick?.(index)
      : // @ts-ignore
        item?.props?.onClick;

    // @ts-ignore
    if (item?.props?.isSubMenuHeader) {
      return (
        <DropdownSubMenuHeader
          isNewSection
          key={key}
          // @ts-ignore
          id={key}
          prefixId={`${prefixId}-${index}`}
          onMouseEnter={() => onFocus?.(index)}
          noBorder={itemNoBorder}
        >
          {drawnItem}
        </DropdownSubMenuHeader>
      );
    }

    if (!item) {
      return null;
    }

    return (
      <DropdownItem
        // @ts-ignore
        {...item?.props}
        key={key}
        id={key}
        prefixId={`${prefixId}-${index}`}
        onMouseEnter={() => onFocus?.(index)}
        noBorder={itemNoBorder}
        onClick={onClickHandler}
        // @ts-ignore
        subMenu={item?.props?.containsSubMenu ? subMenuItem : null}
      >
        {drawnItem}
      </DropdownItem>
    );
  });

type DropdownListProps = PropsWithChildren<{
  className?: string;
  isOpen?: boolean;
  id?: string;
  prefixId?: string;
  forwardedRef?: React.ForwardedRef<HTMLDivElement>;
}>;

export const DropdownList = ({
  children,
  className,
  isOpen = false,
  id,
  prefixId = 'dropdown',
  forwardedRef,
}: DropdownListProps) => {
  const [hoveringId, setHoverId] = useState<number | null>(null);
  const [openId, setOpenId] = useState<number | null>(null);

  const noScrollbarOnMobile = (windowWidth: number) =>
    windowWidth <= MAX_PHONE_PX && isOpen;
  useDisableScrollbar(noScrollbarOnMobile, [isOpen]);

  const onFocus = (index: number | null) => {
    setHoverId(index);
  };
  const onClick = (index: number | null) => {
    setOpenId(index === openId ? null : index);
  };

  const drawnChildren = drawDropdownChildren({
    items: children,
    prefixId,
    onFocus,
    onClick,
    openId,
    hoveringId,
  });

  return (
    <>
      {isOpen && <DropdownBackdrop data-testid="dropdown-list-backdrop" />}
      <DropdownListWrapper
        className={className}
        isVisible={isOpen}
        id={id}
        ref={forwardedRef}
        onMouseLeave={() => onFocus(null)}
        data-testid={
          prefixId ? `${prefixId}-dropdownlist-wrapper` : 'dropdownlist-wrapper'
        }
      >
        {drawnChildren}
      </DropdownListWrapper>
    </>
  );
};

export const DropdownSubMenu = ({
  children,
  isOpen,
  prefixId,
}: PropsWithChildren<{ isOpen?: boolean; prefixId?: string }>) => {
  const [hoveringId, setHoverId] = useState<number | null>(null);
  const onFocus = (index: number | null) => {
    setHoverId(index);
  };

  const drawnChildren = drawDropdownChildren({
    items: children,
    prefixId: `${prefixId}-submenu`,
    onFocus,
    hoveringId,
  });

  return (
    <ItemSideElement
      isVisible={Boolean(isOpen)}
      data-testid={
        prefixId ? `${prefixId}-dropdown-submenu` : 'dropdown-submenu'
      }
    >
      <DropdownSubListWrapper
        isVisible={Boolean(isOpen)}
        onMouseLeave={() => onFocus(null)}
      >
        {drawnChildren}
      </DropdownSubListWrapper>
    </ItemSideElement>
  );
};

DropdownSubMenu.displayName = 'DropdownSubMenu';
