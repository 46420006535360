import React, { PropsWithChildren } from 'react';
import Palette from 'components/styles/colors';
import SVG from 'svgs';
import {
  DropdownPickerBox,
  DropdownPickerArrow,
} from './dropdown-picker.styles';

type DropdownPickerProps = PropsWithChildren<{
  drawArrowOnLeft?: boolean;
  onClick?: () => void;
  disableArrow?: boolean;
  forwardedRef?: React.ForwardedRef<HTMLDivElement>;
  className?: string;
  invertArrow?: boolean;
  hideArrow?: boolean;
}>;

const DropdownPicker = ({
  drawArrowOnLeft = false,
  children,
  onClick = () => {},
  disableArrow,
  forwardedRef,
  className,
  invertArrow,
  hideArrow = false,
}: DropdownPickerProps) => (
  <DropdownPickerBox
    data-testid="dropdown-picker"
    onClick={onClick}
    className={className}
    ref={forwardedRef}
  >
    {!disableArrow && drawArrowOnLeft && (
      <DropdownPickerArrow
        data-testid="dropdownArrow-left"
        invertArrow={Boolean(invertArrow)}
      >
        <SVG icon="arrowDown" className={className} fill={Palette.blueSlate} />
      </DropdownPickerArrow>
    )}
    {children}
    {!disableArrow && !drawArrowOnLeft && (
      <DropdownPickerArrow
        invertArrow={Boolean(invertArrow)}
        data-testid="dropdownArrow-right"
        hideArrow={hideArrow}
      >
        <SVG icon="arrowDown" fill={Palette.blueSlate} />
      </DropdownPickerArrow>
    )}
  </DropdownPickerBox>
);

export default DropdownPicker;
