import {
  DISABLED_STATE,
  TOUCHABLE_STYLE,
  fontM,
  fontMContrast,
  plPlusAnimation,
} from 'components/styles';
import styled from 'styled-components';

export const RowWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const OpponentName = styled.p`
  ${fontM}
  color: ${({ theme }) => theme.new.content};
`;

export const PlPlusOpponentName = styled.p`
  ${fontMContrast}
  color: ${({ theme }) => theme.new.content};
  ${plPlusAnimation}
`;

export const LeftItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const IconWrapper = styled.div<{ disabled: boolean }>`
  max-height: 48px;
  box-sizing: border-box;
  cursor: pointer;
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-top: ${({ theme }) => theme.spacing.customSpacing('12px')};
  padding-bottom: ${({ theme }) => theme.spacing.customSpacing('12px')};
  height: 48px;
  ${TOUCHABLE_STYLE}
  ${DISABLED_STATE}
`;
