import { ON_SERVER } from 'helpers/context';
import { GetServerSidePropsContext } from 'next';
import { getCookie } from './cookie';

const getUserToken = (ctx?: GetServerSidePropsContext): string | undefined => {
  if (ON_SERVER) {
    return ctx?.req?.cookies?.UserToken;
  }

  return getCookie('UserToken') || undefined;
};

export default getUserToken;
