import { FeatureFlagFragment } from 'graphpl/cms';

export const mapFeatureFlags = (
  flags: FeatureFlagFragment[],
): Record<string, FeatureFlagFragment> => {
  if (!flags) return {};
  if (flags.length === 0) return {};

  return flags.reduce((acc, flag) => {
    return {
      ...acc,
      [flag.featureFlag]: flag,
    };
  }, {});
};
