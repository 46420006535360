import { fontL } from 'components/styles';
import styled from 'styled-components';
import Surface from 'components/atoms/surface';

export const StyledSurface = styled(Surface)`
  margin-bottom: ${({ theme }) => theme.spacing.BASE_SPACING};
`;

export const SectionWrapper = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: auto;
  transition: height 0.3s;
  overflow: hidden;
  max-height: ${({ isOpen }) => (isOpen ? '200px' : '0px')};
  transition: max-height 300ms ease-in-out;
  margin
`;

export const SectionHeading = styled.h2`
  ${fontL}
  color: ${({ theme }) => theme.new.content};
  padding: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const PreviewContentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing.customSpacing('12px')};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-right: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
  padding-bottom: ${({ theme }) => theme.spacing.BASE_SPACING_AND_HALF};
`;
